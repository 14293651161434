import React from "react";
import {TableCell} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

interface BooleanTableCellProps {
    value: boolean | null;
}

const useStyles = makeStyles({
    tableCell: {
        textAlign: "left",
        // fontSize: "0.6vw",
        fontSize: "12px",
    }
});

const BooleanTableCell: React.FC<BooleanTableCellProps> = ({ value }) => {
    const classes = useStyles();

    return <TableCell className={classes.tableCell}>{value != null ? (value ? "Yes" : "No") : "N/A"}</TableCell>;
};

export default BooleanTableCell;
