import React from "react";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "components/common/TextField/TextField";

interface IntegrationTextFieldRowProps {
    label: string;
    name: string;
    value: string;
    enabled?: boolean | null;
    error?: string | null;
    onChange: (name: string, value: string) => void;
}

const IntegrationTextFieldRow: React.FC<IntegrationTextFieldRowProps> = ({
                                                                       label,
                                                                       name,
                                                                       value,
                                                                       enabled,
                                                                       error,
                                                                       onChange
                                                                   }) => {


    return (
        <Grid
            item
            container
            spacing={2}
            xs={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center">
            <Grid item xs={2} style={{textAlign: "left"}}>
                <InputLabel htmlFor={name}>{label}</InputLabel>
            </Grid>
            <Grid item xs={5}>
                <TextField
                    disabled={!enabled}
                    name={name}
                    fullWidth
                    textAlign="left"
                    value={value}
                    onChange={(event: any) => onChange(event.target.name, event.target.value)}
                    // error={enabled && Boolean(error)}
                    // helperText={enabled && error ? error : ""}
                />
                {enabled && error && <div
                    style={{color: 'red', marginTop: '4px', textAlign: 'left'}}>{error}</div>}
            </Grid>
        </Grid>
    );
};

export default IntegrationTextFieldRow;
