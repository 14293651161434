import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectIsPEIStompClientConnected, selectPEIStompClient} from 'reducers/webSocketSlice';
import PEIDashletsRestClient from 'api/rest/PEIDashletsRestClient';
import {loadPEIChartBagTemplates} from "../actions";
import FormControl from "@material-ui/core/FormControl";
// import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import * as _ from "lodash";
import * as webSocketClient from "../webSocketClient";
import InputLabel from "components/common/InputLabel/InputLabel";
import {DASHLET_CONFIG_PROPERTIES, TEMPLATES_DASHLET_CONFIG_OPTIONS_PROPERTY} from "../config";
import {selectTemplateNames, setDashletDataConfigProperty, setDashletOptionsProperty} from "reducers/dashboard/createDashletSlice";
import WebSocketSubscription from "websocket/WebSocketSubscription";
import { selectIsLoadingTemplateNames, toggleIsLoadingTemplateNames } from 'reducers/dashboard/dashboardSlice';
import { ChevronDown12Regular } from '@fluentui/react-icons';
import withStyles from "@material-ui/core/styles/withStyles";

const styles = theme => ({
    customTextField: {
    },
});

const PEIChartBagDashletCreate = (props) => {
    const {classes, dashletErrors, dashletData} = props;
    const {backendKey, templateName} = dashletData.config;
    const [backends, setBackends] = useState([]);
    const [isLoadingBackends, setIsLoadingBackends] = useState(false);
    // const [templateNames, setTemplateNames] = useState([]);
    // const [isLoadingTemplateNames, setIsLoadingTemplateNames] = useState(false);
    const stompClient = useSelector(selectPEIStompClient);
    const isStompClientConnected = useSelector(selectIsPEIStompClientConnected);
    const dispatch = useDispatch();

    const isLoadingTemplateNames = useSelector(selectIsLoadingTemplateNames);
    const templateNames = useSelector(selectTemplateNames)

    const onChangeDashletConfigPropertyHandler = (event) => {
        event.preventDefault();
        dispatch(setDashletDataConfigProperty(event.target.name, event.target.value));
    };

    useEffect(() => {
        setIsLoadingBackends(true);
        PEIDashletsRestClient.listUserBackends()
            .then(backends => setBackends(backends))
            .catch(error => console.error(error))
            .finally(() => setIsLoadingBackends(false));
    }, []);

    useEffect(() => {
        if (backendKey) {
            // setIsLoadingTemplateNames(true);
            dispatch(toggleIsLoadingTemplateNames(false))
            dispatch(loadPEIChartBagTemplates(backendKey));
        }
    }, [backendKey]);

    return <React.Fragment>
           <InputLabel id="dashlet-pei-chart-bag-backendKey-input-label">
                PEI Backend
            </InputLabel>
        <FormControl key="dashlet-pei-chart-bag-backendKey"
                     fullWidth
                     error={dashletErrors.backendKey}
                     margin="dense"
                     variant="outlined"
        >
            <Select
                // labelId="dashlet-pei-chart-bag-backendKey-label"
                id="backendKey"
                name="backendKey"
                value={backendKey ? backendKey : ''}
                onChange={onChangeDashletConfigPropertyHandler}
                disabled={isLoadingBackends}
                // label="PEI Backend"
                IconComponent={(props) => (
                    <ChevronDown12Regular {...props} style={{ height: '20px', width: '20px', top: '8px' }} />
                )}
            >
                <MenuItem key="dashlet-pei-chart-bag-backendKey-menu-item" value=""></MenuItem>
                {_.map(backends, (backend) => {
                    return (
                        <MenuItem
                            key={`dashlet-pei-chart-bag-backendKey-menu-item-key-${backend.backendKey}`}
                            value={backend.backendKey}>
                            {backend.name}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>

        <InputLabel id="dashlet-pei-chart-bag-templateName-input-label-1582673886219">
                PEI Chart Bag Template
            </InputLabel>
        <FormControl key="dashlet-pei-chart-bag-templateName-1582673886219"
                     fullWidth
                     error={dashletErrors.templateName}
                     margin="dense"
                     variant="outlined"
        >
            <Select
                // labelId="dashlet-pei-chart-bag-templateName-label-1582673886219"
                id={DASHLET_CONFIG_PROPERTIES.TEMPLATE_NAME}
                name={DASHLET_CONFIG_PROPERTIES.TEMPLATE_NAME}
                value={templateName ? templateName : ''}
                onChange={onChangeDashletConfigPropertyHandler}
                disabled={isLoadingTemplateNames || !backendKey}
                // label="PEI Chart Bag Template"
                IconComponent={(props) => (
                    <ChevronDown12Regular {...props} style={{ height: '20px', width: '20px', top: '8px' }} />
                )}
            >
                <MenuItem key="dashlet-pei-chart-bag-templateName-menu-item-1582673956819" value=""></MenuItem>
                {_.map(templateNames, (templateName) => {
                    return (
                        <MenuItem
                            key={`dashlet-pei-chart-bag-templateName-menu-item-key-${templateName}`}
                            value={templateName}>
                            {templateName}
                        </MenuItem>
                    )
                })}
            </Select>
        </FormControl>

        {isStompClientConnected && <WebSocketSubscription
            key={`dashlet-pie-chart-bag-templates-subscription-1582673956819`}
            stompClient={stompClient}
            subscriptionDestination={webSocketClient.LOAD_PEI_CHART_BAG_TEMPLATES_SUBSCRIPTION}
            handleMessage={response => {
                const {templates} = response;
                dispatch(setDashletOptionsProperty(TEMPLATES_DASHLET_CONFIG_OPTIONS_PROPERTY, templates));
                dispatch(toggleIsLoadingTemplateNames(false));
                // setTemplateNames(templates.map(t => t.name));
                // setIsLoadingTemplateNames(false);
            }}
        />}
    </React.Fragment>

};

export default withStyles(styles)(PEIChartBagDashletCreate);