import React from "react";
import Dialog from "@material-ui/core/Dialog";
import {
  DialogActions,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField
} from "@material-ui/core";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogTitle from "../DialogTitle/DialogTitle";
import InputLabel from "components/common/InputLabel/InputLabel";
import { Dismiss20Regular } from "@fluentui/react-icons";

type ListDialogMode = "save" | "load";

interface ListDialogProps {
  open: boolean;
  mode: ListDialogMode;
  title: string;
  options: string[];
  value: string | null;
  inputLabel?: string;
  onChange: (value: string) => void;
  submitLabel?: string;
  onSubmit: () => void;
  cancelLabel?: string;
  onCancel: () => void;
  onRemove?: (key: number | string) => void;
  onExport?: (key: number | string) => void;
}

const ListDialog: React.FC<ListDialogProps> = ({
  open,
  mode,
  title,
  options,
  value,
  inputLabel,
  onChange,
  submitLabel,
  onSubmit,
  cancelLabel,
  onCancel,
  onRemove,
  onExport,
}) => {
  const isSubmitDisabled = value === null;

  const handleListItemClick = (val: string) => onChange(val);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    onChange(event.target.value);

  const handleSubmitButtonClick = () => onSubmit();

  const handleCancelButtonClick = () => onCancel();

  const handleRemoveButtonClick = (key: number | string) => {
    if (onRemove) {
      onRemove(key);
    }
  };

  const handleExportButtonClick = (key: number | string) => {
    if (onExport)
      onExport(key);
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="templates-dialog-title"
      PaperProps={{
        style: { minHeight: "50vh", maxHeight: "50px", overflow: "auto" },
      }}
    >
      <DialogTitle id="templates-dialog-title">
        {title}
        <IconButton
          edge="end"
          onClick={handleCancelButtonClick}
          aria-label="close"
          style={{ position: "absolute", right: 12, top: 4 }}
        >
          <Dismiss20Regular />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers style={{ overflow: "hidden", width: 500 }}>
        {mode === "save" && (
          <>
          <InputLabel id={inputLabel}>{inputLabel}</InputLabel>
            <TextField
            // label={inputLabel}
            variant="outlined"
            fullWidth
            margin="dense"
            size="small"
            value={value}
            onChange={handleInputChange}
          />
          </>
        )}
        <List
            style={{
              overflow: "auto",
              maxHeight: mode === "save" ? 250 : 300,
              marginTop: 2,
            }}
        >
          {options.map((val: string, idx: number) => {
            return (
                <ListItem
                    key={`list-templates-dialog-list-item-${idx}`}
                    selected={value === val}
                    dense={true}
                    button
                    onClick={() => handleListItemClick(val)}
                >
                  <ListItemText primary={val} />
                  <ListItemSecondaryAction>
                    {onExport ? (
                        <IconButton
                            edge="end"
                            aria-label="export"
                            onClick={() => handleExportButtonClick(val)}
                        >
                          <ImportExportIcon />
                        </IconButton>
                    ) : null}
                    {onRemove ? (
                        <IconButton
                            edge="end"
                            aria-label="remove"
                            onClick={() => handleRemoveButtonClick(val)}
                        >
                          <DeleteForeverIcon />
                        </IconButton>
                    ) : null}
                  </ListItemSecondaryAction>
                </ListItem>
            );
          })}
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancelButtonClick}>
          {cancelLabel || "Cancel"}
        </Button>
        <Button
          variant="contained"
          disabled={isSubmitDisabled}
          onClick={handleSubmitButtonClick}
        >
          {submitLabel || "Submit"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ListDialog;
