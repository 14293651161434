import React from "react";
import IconButton from "@material-ui/core/IconButton";
import SaveIcon from "@material-ui/icons/Save";
import {Theme, Tooltip, WithStyles} from "@material-ui/core";
import {withStyles} from "@material-ui/core/styles";
import {useDispatch} from "react-redux";
import {openSaveTemplateDialog} from "../../templateActions";

const styles = (theme: Theme) => ({
    iconButton: {
        "&:hover": {
            backgroundColor: '#fafafa',
        },
        paddingTop: '6px',
    },
    iconButtonDisable: {
        display: 'none',
    },
    icon: {
        color: theme.palette.text.secondary,
        '&:hover': {
            color: theme.palette.text.primary,
        },
        fontSize: '0.875rem',
    },
});

interface SaveTemplateButtonProps extends WithStyles<typeof styles> {
    dashletId: number;
}

const SaveTemplateButton: React.FC<SaveTemplateButtonProps> = ({
                                                                   classes,
                                                                   dashletId,
                                                               }) => {
    const dispatch = useDispatch();

    const handleClick = () => dispatch(openSaveTemplateDialog(dashletId));

    return (
        <Tooltip
            key={`dashlet-${dashletId}-save-template-button`}
            title="Save As Tempalate"
        >
            <IconButton
                aria-label="Save As Template"
                size="small"
                className={classes.iconButton}
                onClick={handleClick}
            >
                <SaveIcon
                    className={classes.icon}
                    fontSize="small"
                />
            </IconButton>
        </Tooltip>
    );
};

export default withStyles(styles)(SaveTemplateButton);
