import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectDashletTimeRange} from "../../../reducers/dashboard/dashboardSlice";
import {updateDashletTimeRange} from "../../../actions/dashboard/dashletActions";
import {MaterialUiPickersDate} from "@material-ui/pickers/typings/date";
import {Theme, WithStyles} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import AppBar from "@material-ui/core/AppBar";
import TimeRangeTabs from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTabs";
import TimeRangeTab from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTab";
import Divider from "@material-ui/core/Divider";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import TextField from "components/common/TextField/TextField";
import Select from "components/common/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TimeRangeTabPanel from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTabPanel";
import moment from "moment/moment";
import {KeyboardDateTimePicker} from "@material-ui/pickers";
import RadioButton from "components/common/RadioButton/RadioButton";
import ListBaselinesStompResponse from "../../types/ListBaselinesStompResponse";
import {LIST_BASELINES_SUBSCRIPTION} from "../../hlcDashletWebSocketClient";
import Baseline from "../../../types/Baseline";
import {listBaselines} from "../../actions";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import { Calendar12Regular, CalendarDate24Regular, Clock12Regular, Earth16Regular, Smartwatch24Regular, WeatherSunny16Regular } from "@fluentui/react-icons";
import Button from "@material-ui/core/Button";

const styles = (theme: Theme) => ({
    root: {},
    appBar: {
        backgroundColor: '#fff',
        boxShadow: 'none',
    },
    formLabel: {
        padding: '0.4rem 0rem',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    formControlLabel: {
        margin: theme.spacing(1),
        minWidth: '40px'
    },
    formControlInput: {
        margin: theme.spacing(1),
        minWidth: '60px',
    },
    formControlSelect: {
        margin: theme.spacing(1),
        minWidth: '120px',
    },
    formControlFullWidthSelect: {
        margin: theme.spacing(1),
        width: '192px',
        minWidth: '160px',
    },
    formControlDatetime: {
        margin: theme.spacing(1),
        width: '192px',
        minWidth: '160px',
    },
    footer: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    grow: {
        flexGrow: 1,
    },
    switchRoot: {
        marginLeft: 0,
    },
    switchLabel: {
        marginLeft: 8,
    },
});

const TIME_RANGE_TYPES = {
    TIME_AMOUNT: 1,
    TIME_RANGE: 2,
    HISTORICAL_TIME_AMOUNT: "HISTORICAL_TIME_AMOUNT_FROM",
    HISTORICAL_TIME_FROM: "HISTORICAL_TIME_FROM",
    HISTORICAL_BASELINE: "HISTORICAL_BASELINE"
}

const DATE_TIME_FORMAT = "DD/MM/YY HH:mm";

const getEmptyTimeRangeSettings = () => {
    const now = moment();

    return {
        timeRangeType: TIME_RANGE_TYPES.TIME_AMOUNT,
        //last time
        lastTime: 60,
        lastTimeMeasure: 'Minute',
        //time amount
        timeAmount: 1,
        timeAmountMeasure: 'Hour',
        timeAmountFrom: 1,
        timeAmountFromMeasure: 'Day',
        //time range
        timeRangeStartTime: now,
        timeRangeEndTime: now,

        //historical
        historicalTimeRangeType: TIME_RANGE_TYPES.HISTORICAL_TIME_AMOUNT,
        historicalTimeAmountFrom: 2,
        historicalTimeAmountFromMeasure: 'Day',
        historicalTimeFrom: now.subtract(1, 'days'),
        historicalBaseline: null
    }
};

interface FinOpsDashletTimeRangeProps extends WithStyles<typeof styles> {
    dashletId: number;
    backendKey: string;
    close: () => void;
}

const FinOpsDashletTimeRange: React.FC<FinOpsDashletTimeRangeProps> = ({
                                                                           classes,
                                                                           dashletId,
                                                                           backendKey,
                                                                           close
                                                                       }) => {
    const dispatch = useDispatch();
    const initialTimeRangeSettings = useSelector(state => selectDashletTimeRange(state, dashletId));
    const [timeRangeSettings, setTimeRangeSettings]
        = React.useState(initialTimeRangeSettings && Object.keys(initialTimeRangeSettings).length > 0 ? initialTimeRangeSettings : getEmptyTimeRangeSettings());
    const [baselines, setBaselines] = React.useState<Baseline[]>([]);
    const {
        timeRangeType,
        timeAmount,
        timeAmountMeasure,
        timeAmountFrom,
        timeAmountFromMeasure,
        timeRangeStartTime,
        timeRangeEndTime,
        historicalTimeRangeType,
        historicalTimeAmountFrom,
        historicalTimeAmountFromMeasure,
        historicalTimeFrom,
        historicalBaseline
    } = timeRangeSettings;

    const changeProperty = (name: string, value: any) => setTimeRangeSettings({
        ...timeRangeSettings,
        [name]: value
    });

    const handleCancel = () => close();

    const handleSubmit = () => {
        dispatch(updateDashletTimeRange(dashletId, timeRangeSettings));
        close();
    };

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        changeProperty(event.target.name, event.target.value);
    };

    const handleChangeTimeRangeType = (event: React.SyntheticEvent, value: number) => {
        changeProperty("timeRangeType", value);
    };

    const handleChangeTimeRangeStartTime = (value: MaterialUiPickersDate | null) => {
        changeProperty("timeRangeStartTime", value?.valueOf());
    };

    const handleChangeTimeRangeEndTime = (value: MaterialUiPickersDate | null) => {
        changeProperty("timeRangeEndTime", value?.valueOf());
    };

    const changeHistoricalTimeRangeType = (timeRangeType: string) => {
        changeProperty("historicalTimeRangeType", timeRangeType);
    };

    const handleChangeHistoricalTimeFrom = (value: MaterialUiPickersDate | null) => {
        changeProperty("historicalTimeFrom", value?.valueOf());
    };

    const handleListBaselines = (response: ListBaselinesStompResponse) => {
        if (!response.hasErrors) {
            setBaselines(response.baselines);
        } else {
            setBaselines([]);
            console.error(response.errorMessage); // todo: handle error
        }
    }

    return (
        <React.Fragment>
            <Paper className={classes.root}>
                <AppBar position="relative" className={classes.appBar} component="div">
                    {/* @ts-ignore */}
                    <TimeRangeTabs value={timeRangeSettings.timeRangeType} onChange={handleChangeTimeRangeType}>
                        <TimeRangeTab
                            label="Time Amount"
                            value={TIME_RANGE_TYPES.TIME_AMOUNT}
                        />
                        <TimeRangeTab
                            label="Time Range"
                            value={TIME_RANGE_TYPES.TIME_RANGE}
                        />
                    </TimeRangeTabs>
                </AppBar>

                {/* @ts-ignore */}
                <TimeRangeTabPanel hidden={TIME_RANGE_TYPES.TIME_AMOUNT !== timeRangeType}>
                    <FormGroup row>
                        <FormControl className={classes.formControlLabel}>
                            <Typography component="div" className={classes.formLabel}>
                                Use
                            </Typography>
                        </FormControl>
                        <FormControl className={classes.formControlInput}>
                            <TextField
                                name="timeAmount"
                                onChange={handleChangeInput}
                                value={timeAmount}
                                inputProps={{
                                    step: 1,
                                    min: 1,
                                    max: 120,
                                    type: 'number',
                                }}
                            />
                        </FormControl>
                        <FormControl className={classes.formControlSelect}>
                            <Select value={timeAmountMeasure} name="timeAmountMeasure" onChange={handleChangeInput} renderValue={(selected: any) => { return selected; }}>
                                <MenuItem value="Minute"><Clock12Regular style={{ marginRight: '8px', color: '#B2BEB5', height: '20px', width: '20px' }} />Minutes</MenuItem>
                                <MenuItem value="Hour"><Smartwatch24Regular style={{ marginRight: '8px', height: '20px', width: '20px' }} />Hours</MenuItem>
                                <MenuItem value="Day"><WeatherSunny16Regular style={{ marginRight: '8px', height: '20px', width: '20px' }} />Days</MenuItem>
                                <MenuItem value="Week"><Calendar12Regular style={{ marginRight: '8px', color: '#fd5c63', height: '20px', width: '20px' }} />Weeks</MenuItem>
                                <MenuItem value="Month"><CalendarDate24Regular style={{ marginRight: '8px', height: '20px', width: '20px' }} />Months</MenuItem>
                                <MenuItem value="Year"><Earth16Regular style={{ marginRight: '8px', color: '#0066b2', height: '20px', width: '20px' }} />Years</MenuItem>
                            </Select>
                        </FormControl>
                    </FormGroup>
                    <FormGroup row>
                        <FormControl className={classes.formControlLabel}>
                            <Typography component="div" className={classes.formLabel}>
                                From
                            </Typography>
                        </FormControl>
                        <FormControl className={classes.formControlInput}>
                            <TextField
                                name="timeAmountFrom"
                                onChange={handleChangeInput}
                                value={timeAmountFrom}
                                inputProps={{
                                    step: 1,
                                    min: 1,
                                    max: 120,
                                    type: 'number',
                                }}
                            />
                        </FormControl>
                        <FormControl className={classes.formControlSelect}>
                            <Select value={timeAmountFromMeasure} name="timeAmountFromMeasure"
                                    onChange={handleChangeInput} renderValue={(selected: any) => { return selected; }}>
                                <MenuItem value="Minute"><Clock12Regular style={{ marginRight: '8px', color: '#B2BEB5', height: '20px', width: '20px' }} />Minutes</MenuItem>
                                <MenuItem value="Hour"><Smartwatch24Regular style={{ marginRight: '8px', height: '20px', width: '20px' }} />Hours</MenuItem>
                                <MenuItem value="Day"><WeatherSunny16Regular style={{ marginRight: '8px', height: '20px', width: '20px' }} />Days</MenuItem>
                                <MenuItem value="Week"><Calendar12Regular style={{ marginRight: '8px', color: '#fd5c63', height: '20px', width: '20px' }} />Weeks</MenuItem>
                                <MenuItem value="Month"><CalendarDate24Regular style={{ marginRight: '8px', height: '20px', width: '20px' }} />Months</MenuItem>
                                <MenuItem value="Year"><Earth16Regular style={{ marginRight: '8px', color: '#0066b2', height: '20px', width: '20px' }} />Years</MenuItem>
                            </Select>
                        </FormControl>
                    </FormGroup>
                </TimeRangeTabPanel>

                {/* @ts-ignore */}
                <TimeRangeTabPanel hidden={TIME_RANGE_TYPES.TIME_RANGE !== timeRangeType}>
                    <FormGroup row>
                        <FormControl className={classes.formControlLabel}>
                            <Typography component="div" className={classes.formLabel}>
                                From
                            </Typography>
                        </FormControl>
                        <FormControl className={classes.formControlDatetime}>
                            <KeyboardDateTimePicker
                                variant="inline"
                                ampm={false}
                                value={timeRangeStartTime}
                                onChange={handleChangeTimeRangeStartTime}
                                onError={console.log}
                                disableFuture
                                // disableToolbar
                                minutesStep={5}
                                format={DATE_TIME_FORMAT}
                            />
                        </FormControl>
                    </FormGroup>
                    <FormGroup row>
                        <FormControl className={classes.formControlLabel}>
                            <Typography component="div" className={classes.formLabel}>
                                To
                            </Typography>
                        </FormControl>
                        <FormControl className={classes.formControlDatetime}>
                            <KeyboardDateTimePicker
                                variant="inline"
                                ampm={false}
                                value={timeRangeEndTime}
                                onChange={handleChangeTimeRangeEndTime}
                                onError={console.log}
                                disableFuture
                                // disableToolbar
                                minutesStep={5}
                                format={DATE_TIME_FORMAT}
                            />
                        </FormControl>
                    </FormGroup>
                </TimeRangeTabPanel>

                {/* @ts-ignore */}
                <TimeRangeTabPanel>
                    <FormGroup row>
                        <Typography component="div" className={classes.formLabel}>
                            Compare with historical
                        </Typography>
                    </FormGroup>
                    <FormGroup row>
                        <FormGroup className={classes.formControlLabel}>
                            <RadioButton
                                checked={historicalTimeRangeType === TIME_RANGE_TYPES.HISTORICAL_TIME_AMOUNT}
                                onChange={() => changeHistoricalTimeRangeType(TIME_RANGE_TYPES.HISTORICAL_TIME_AMOUNT)}
                            />
                        </FormGroup>
                        <FormControl className={classes.formControlInput}>
                            <TextField
                                name="historicalTimeAmountFrom"
                                onChange={handleChangeInput}
                                value={historicalTimeAmountFrom}
                                disabled={historicalTimeRangeType !== TIME_RANGE_TYPES.HISTORICAL_TIME_AMOUNT}
                                inputProps={{
                                    step: 1,
                                    min: 1,
                                    max: 120,
                                    type: 'number',
                                }}

                            />
                        </FormControl>
                        <FormControl className={classes.formControlSelect}>
                            <Select
                                value={historicalTimeAmountFromMeasure}
                                name="historicalTimeAmountFromMeasure"
                                onChange={handleChangeInput}
                                disabled={historicalTimeRangeType !== TIME_RANGE_TYPES.HISTORICAL_TIME_AMOUNT}
                                renderValue={(selected: any) => { return selected; }}
                            >
                                <MenuItem value="Minute"><Clock12Regular style={{ marginRight: '8px', color: '#B2BEB5', height: '20px', width: '20px' }} />Minutes</MenuItem>
                                <MenuItem value="Hour"><Smartwatch24Regular style={{ marginRight: '8px', height: '20px', width: '20px' }} />Hours</MenuItem>
                                <MenuItem value="Day"><WeatherSunny16Regular style={{ marginRight: '8px', height: '20px', width: '20px' }} />Days</MenuItem>
                                <MenuItem value="Week"><Calendar12Regular style={{ marginRight: '8px', color: '#fd5c63', height: '20px', width: '20px' }} />Weeks</MenuItem>
                                <MenuItem value="Month"><CalendarDate24Regular style={{ marginRight: '8px', height: '20px', width: '20px' }} />Months</MenuItem>
                                <MenuItem value="Year"><Earth16Regular style={{ marginRight: '8px', color: '#0066b2', height: '20px', width: '20px' }} />Years</MenuItem>
                            </Select>
                        </FormControl>
                    </FormGroup>
                    <FormGroup row>
                        <FormGroup className={classes.formControlLabel}>
                            <RadioButton
                                checked={historicalTimeRangeType === TIME_RANGE_TYPES.HISTORICAL_TIME_FROM}
                                onChange={() => changeHistoricalTimeRangeType(TIME_RANGE_TYPES.HISTORICAL_TIME_FROM)}
                            />
                        </FormGroup>
                        <FormControl className={classes.formControlDatetime}>
                            <KeyboardDateTimePicker
                                variant="inline"
                                ampm={false}
                                value={historicalTimeFrom}
                                onChange={handleChangeHistoricalTimeFrom}
                                onError={console.log}
                                disableFuture
                                // disableToolbar
                                minutesStep={5}
                                format={DATE_TIME_FORMAT}
                                disabled={historicalTimeRangeType !== TIME_RANGE_TYPES.HISTORICAL_TIME_FROM}
                            />
                        </FormControl>
                    </FormGroup>
                    <FormGroup row>
                        <FormGroup className={classes.formControlLabel}>
                            <RadioButton
                                checked={historicalTimeRangeType === TIME_RANGE_TYPES.HISTORICAL_BASELINE}
                                onChange={() => changeHistoricalTimeRangeType(TIME_RANGE_TYPES.HISTORICAL_BASELINE)}
                            />
                        </FormGroup>
                        {/*<FormControl className={classes.formControlLabel}>*/}
                        {/*    <Typography component="div" className={classes.formLabel}>*/}
                        {/*        Baseline*/}
                        {/*    </Typography>*/}
                        {/*</FormControl>*/}
                        <FormControl className={classes.formControlFullWidthSelect}>
                            <Select
                                value={historicalBaseline}
                                name="historicalBaseline"
                                onChange={handleChangeInput}
                                disabled={historicalTimeRangeType !== TIME_RANGE_TYPES.HISTORICAL_BASELINE}
                            >
                                {baselines.map((b: Baseline) => {
                                    return <MenuItem key={b.id} value={b.id}>{b.name}</MenuItem>;
                                })}
                            </Select>
                        </FormControl>
                    </FormGroup>
                </TimeRangeTabPanel>

                <Divider />

                <div className={classes.footer}>
                    <div className={classes.grow}/>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button variant="contained" onClick={handleSubmit}>Apply</Button>
                </div>
            </Paper>

            <HLCUserSessionWebSocketSubscription
                dashletId={dashletId}
                subscriptionDestination={LIST_BASELINES_SUBSCRIPTION}
                handleMessage={handleListBaselines}
                onSubscribeCallback={() => dispatch(listBaselines(backendKey))}
            />
        </React.Fragment>
    );
};

export default withStyles(styles)(FinOpsDashletTimeRange);
