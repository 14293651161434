import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tabs,
    Tab,
    Typography,
    Button,
    Divider,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    TextField,
    Grid,
    Checkbox,
    FormControlLabel,
    CircularProgress,
    Backdrop,
    withStyles,
    Input,
    styled
} from "@material-ui/core";
import InputLabels from "components/common/InputLabel/InputLabel";
import TargetDatabaseType from 'types/model/common/TargetDatabaseType';
import { REGULAR_DASHBOARD, ANOMALY_DASHBOARD, DEFAULT_DASHBOARD } from 'dashlets/AnomalyDetectionDashlet/constants';
import HLCDashletsRestClient from 'api/rest/HLCDashletsRestClient';
import _ from 'lodash';
import GUIDELINES_CONTENT from './GuidelinesContent'; 
import AddIcon from '@material-ui/icons/Add';
import { Add16Filled, ChevronDown12Regular } from '@fluentui/react-icons';

const styles = theme => ({
    root: {
        maxWidth: '700px',
    },
    dialogContent: {
        display: 'flex',
        flexDirection: 'column',
        maxHeight: '470px',
        padding:"0px 15px",
        overflowY: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: '#888 #f0f0f0',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f0f0f0',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px', 
        },
    },
    actionButtonLabel: {
        fontWeight: 600,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    toggleButton: {
        padding: '8px',
        gap: '8px'
    },
    typography:{
        fontSize:"20px",
        lineHeight:"28px",
        color:"#242424",
        fontWeight:600
    },
    dialogActions: {
        margin: '10px 18px 10px 0px',
    },
    inputLabel:{
        marginTop:"5px",
        color:"#BDBDBD",
        fontWeight: 400,
        fontSize:"12px",
        lineHeight:"16px",
    },
    customCheckbox: {
        borderRadius: '4px',
        '& .MuiCheckbox-root': {
            color: '#616161', 
          },
          '& .MuiCheckbox-root.Mui-checked': {
            color: '#0F6CBD',
          },
          '& .MuiCheckbox-root.Mui-disabled': {
            color: '#B0B0B0',
          },
          '& .MuiCheckbox-root.Mui-checked.Mui-disabled': {
            color: '#B0B0B0',
          },
        "& .MuiFormControlLabel-label": {
          fontSize: "14px",  
          lineHeight: "20px",  
        },
    },
    tab: {
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        color: '#424242',
        '&.Mui-selected .MuiTab-label': {
            color: '#242424',
            fontWeight: 600,
            borderBottom: '1px solid #0F6CBD',
        },  
      }
});

class QuickDashboardModal extends Component {
    state = {
        activeTab: 'guidelines',
        backends: [],
    };

    componentDidMount() {
        HLCDashletsRestClient.listUserBackends()
            .then(backends => {
                this.setState({ backends });
            })
            .catch(error => {
                console.error(error);
            });
    }

    handleTabChange = (event, newValue) => {
        this.setState({ activeTab: newValue });
    };

    toggle = (event) => {
        const name = event.target.name;
        this.props.handleChangeProperty(name, !this.props.targetConfiguration[name]);
    }

    render() {
        const { classes, open, handleClose, handleConfirm, isLocked, errors, objectData, isDashboardMenuOpen, addDashboardMenuAnchorEl, handleMenuClose, handleChangeInput, handleDashboardTypeChange, handleBackendSelect, backendKey, dashboardType, targetConfiguration } = this.props;
        const { activeTab, backends } = this.state;

        const {
            databaseType,
            databaseHost,
            databasePort,
            databaseName,
            databaseUser,
            databasePassword,
            databaseUrl,
            useDatabaseUrl,
            hidePassword
        } = targetConfiguration; 

        const StyledTab = styled(Tab)(({ theme }) => ({
            fontSize: "14px",
            fontWeight: 400, 
            color: "#7A7A7A", 
            marginRight: '3px',
            "&.Mui-selected": {
                color: "#242424", 
                fontWeight: "600 !important", 
                borderBottom: "3px solid #0F6CBD", 
                borderBottomRightRadius: "5%", 
                borderBottomLeftRadius: "5%", 
            },
            "&:hover": {
                color: "#242424 !important", 
                fontWeight: "600 !important", 
                borderBottom: "3px solid rgb(15,108,189) !important", 
                // borderBottomRightRadius: "5%", 
                // borderBottomLeftRadius: "5%", 
            },
        }));

        return (
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="sm">
                <DialogTitle>
                    <Typography variant="h6" className={classes.typography}>{"Quick Dashboard"}</Typography>
                    {/* {"Quick Dashboard"} */}
                    {/* <Tabs value={activeTab} onChange={this.handleTabChange}  aria-label="Tabs example">
                        <Tab className={classes.tab} label="Guidelines" value="guidelines" />
                        <Tab className={classes.tab} label="Add Dashboard" value="addDashboard" />
                    </Tabs> */}

                    <Tabs value={activeTab} onChange={this.handleTabChange} aria-label="tabs"  indicatorColor="primary" textColor="primary"  >
                        <StyledTab label="Guidelines" value="guidelines" />
                        <StyledTab label="Add Dashboard" value="addDashboard" />
                    </Tabs>
                    <Divider />
                </DialogTitle>
                <Backdrop open={isLocked}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                <DialogContent className={classes.dialogContent}>
                    {activeTab === 'guidelines' && (
                         <>
                         <Button onClick={(event) => activeTab === 'guidelines' ? this.handleTabChange(event, 'addDashboard') : null} color="primary" startIcon={<Add16Filled />}>
                             CREATE YOUR FIRST DASHBAORD
                         </Button>
                         <Typography variant="body1" sx={{ padding: 2 }} className={classes.dialogContent}>
                             {GUIDELINES_CONTENT}
                         </Typography>
                     </>
                    )}
                    {
                        activeTab === 'addDashboard' && (
                            <>
                                <Backdrop className={classes.backdrop} open={isLocked}>
                                    <CircularProgress color="inherit" />
                                </Backdrop>
                                <DialogContent className={classes.dialogContent}>

                                    <FormControl key="dash-name" fullWidth required error={errors.name} margin="dense">
                                        <InputLabel id="name-label" htmlFor="dash-name">Dashboard Name</InputLabel>
                                        <Input
                                            id="dash-name"
                                            name="name"
                                            type="text"
                                            value={objectData.name}
                                            onChange={handleChangeInput}
                                        />
                                    </FormControl>

                                    <InputLabels id="dashboard-type-label">Dashboard Type</InputLabels>
                                    <FormControl  margin="dense" variant="outlined" size="small" fullWidth>
                                        <Select
                                            // labelId="dashboard-type-label"
                                            id="dashboardType"
                                            name="dashboardType"
                                            value={this.props.dashboardType}
                                            onChange={this.props.handleDashboardTypeChange}
                                            // label="Dashboard Type"
                                            fullWidth
                                            IconComponent={(props) => (
                                                <ChevronDown12Regular {...props} style={{ height: '20px', width: '20px', top: '8px' }} />
                                            )}
                                        >
                                            <MenuItem value={REGULAR_DASHBOARD}>Regular Dashboard</MenuItem>
                                            <MenuItem value={ANOMALY_DASHBOARD}>Anomaly Dashboard</MenuItem>
                                            <MenuItem value={DEFAULT_DASHBOARD}>Default Dashboard</MenuItem>
                                        </Select>
                                    </FormControl>
                                    {this.props.dashboardType === ANOMALY_DASHBOARD && (
                                        <Grid item xs={12}>
                                            <InputLabels id="hlc-backend">Select Upbeat Backend</InputLabels>
                                            <FormControl margin="dense" variant="outlined" size="small" fullWidth>
                                                <Select
                                                    // labelId="dashlet-hlc-chart-backendKey-label-1582673886219"
                                                    id="backendKey"
                                                    name="backendKey"
                                                    value={backendKey ? backendKey : ''}
                                                    onChange={this.props.handleBackendSelect}
                                                    // label="Select Upbeat Backend"
                                                    fullWidth
                                                    IconComponent={(props) => (
                                                        <ChevronDown12Regular {...props} style={{ height: '20px', width: '20px', top: '8px' }} />
                                                    )}
                                                >
                                                    <MenuItem key="dashlet-hlc-chart-backendKey-menu-item-1582673956819" value=""></MenuItem>
                                                    {_.map(this.state.backends, (backend) => {
                                                        return (
                                                            <MenuItem
                                                                key={`dashlet-hlc-chart-backendKey-menu-item-key-${backend.backendKey}`}
                                                                value={backend.backendKey}>
                                                                {backend.name}
                                                            </MenuItem>
                                                        )
                                                    })}
                                                </Select>
                                            </FormControl>
                                        </Grid>
                                    )}
                                    <Grid
                                        direction="row"
                                        alignItems="center">
                                        {this.props.dashboardType === DEFAULT_DASHBOARD && (
                                            <div>
                                                <Grid item xs={12}>
                                                <InputLabels id="hlc-backend">Select Upbeat Backend</InputLabels>
                                                    <FormControl margin="dense" variant="outlined" size="small" fullWidth>
                                                        <Select
                                                            // labelId="dashlet-hlc-chart-backendKey-label-1582673886219"
                                                            id="backendKey"
                                                            name="backendKey"
                                                            value={backendKey ? backendKey : ''}
                                                            onChange={this.props.handleBackendSelect}
                                                            // label="Select Upbeat Backend"
                                                            fullWidth
                                                            IconComponent={(props) => (
                                                                <ChevronDown12Regular {...props} style={{ height: '20px', width: '20px', top: '8px' }} />
                                                            )}
                                                        >
                                                            <MenuItem key="dashlet-hlc-chart-backendKey-menu-item-1582673956819" value=""></MenuItem>
                                                            {_.map(this.state.backends, (backend) => {
                                                                return (
                                                                    <MenuItem
                                                                        key={`dashlet-hlc-chart-backendKey-menu-item-key-${backend.backendKey}`}
                                                                        value={backend.backendKey}>
                                                                        {backend.name}
                                                                    </MenuItem>
                                                                )
                                                            })}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                <InputLabels id="database-type-label">Database Type</InputLabels>
                                                    <FormControl margin="dense" variant="outlined" size="small" fullWidth>
                                                        <Select
                                                            id="databaseType"
                                                            name="databaseType"
                                                            // labelId="database-type-label"
                                                            value={databaseType}
                                                            onChange={this.handleChangeInput}
                                                            disabled={!backendKey}
                                                            // label="Database Type"
                                                            fullWidth
                                                            IconComponent={(props) => (
                                                                <ChevronDown12Regular {...props} style={{ height: '20px', width: '20px', top: '8px' }} />
                                                            )}
                                                        >
                                                            {Object.values(TargetDatabaseType).map((dbType) => (
                                                                // @ts-ignore
                                                                <MenuItem key={`db-type-${dbType}`} value={dbType}>
                                                                    {dbType}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Grid>

                                                    <Grid item>
                                                        <TextField
                                                            required
                                                            error={errors.databaseHost}
                                                            id="databaseHost"
                                                            name="databaseHost"
                                                            // label="Host"
                                                            placeholder="Host"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            size="small"
                                                            value={databaseHost}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </Grid>

                                                    <Grid item>
                                                        <TextField
                                                            required
                                                            error={errors.databasePort}
                                                            id="databasePort"
                                                            name="databasePort"
                                                            // label="Port"
                                                            placeholder="Port"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            size="small"
                                                            value={databasePort}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </Grid>

                                                    <Grid item>
                                                      <InputLabels id="username">Username</InputLabels>
                                                        <TextField
                                                            required
                                                            error={errors.databaseUser}
                                                            id="databaseUser"
                                                            name="databaseUser"
                                                            // label="Username"
                                                            variant="outlined"
                                                            placeholder="Username"
                                                            fullWidth
                                                            margin="dense"
                                                            size="small"
                                                            value={databaseUser}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </Grid>
                                                    <Grid item>
                                                    <InputLabels id='Password'>Password</InputLabels>
                                                        <TextField
                                                            required
                                                            error={errors.databasePassword}
                                                            id="databasePassword"
                                                            name="databasePassword"
                                                            // label="Password"
                                                            placeholder="Password"
                                                            type="password"
                                                            variant="outlined"
                                                            fullWidth
                                                            margin="dense"
                                                            size="small"
                                                            value={databasePassword}
                                                            onChange={handleChangeInput}
                                                        />
                                                    </Grid>

                                                <Grid item>
                                                <FormControlLabel
                                                 className={classes.customCheckbox}
                                                    control={
                                                    <Checkbox
                                                        id="hidePassword"
                                                        name="hidePassword"
                                                        size="small"
                                                        color='primary'
                                                        checked={hidePassword}
                                                        onChange={this.toggle}
                                                    />
                                                    }
                                                    label="Hide Password"
                                                />
                                                </Grid>

                                                <Grid item xs={12}>
                                                <InputLabels id='Database'>Database</InputLabels>
                                                    <TextField
                                                        required
                                                        error={errors.databaseName}
                                                        id="databaseName"
                                                        placeholder="Database"
                                                        name="databaseName"
                                                        // label="Database"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="dense"
                                                        size="small"
                                                        value={databaseName}
                                                        onChange={handleChangeInput}
                                                    />
                                                </Grid>

                                                <Grid item>
                                                    <FormControlLabel
                                                     className={classes.customCheckbox}
                                                        control={
                                                            <Checkbox
                                                                color='primary'
                                                                id="useDatabaseUrl"
                                                                name="useDatabaseUrl"
                                                                size="small"
                                                                checked={useDatabaseUrl}
                                                                onChange={this.toggle}
                                                            />
                                                        }
                                                        label="Use Database/JDBC URL"
                                                    />
                                                </Grid>

                                                <Grid item>
                                                <InputLabels id='Database/JDBC URL'>Database/JDBC URL</InputLabels>
                                                    <TextField
                                                        required={useDatabaseUrl}
                                                        error={errors.databaseUrl}
                                                        multiline
                                                        maxRows={5}
                                                        id="databaseUrl"
                                                        name="databaseUrl"
                                                        placeholder="Database/JDBC URL"
                                                        className="dropDown-removePadding"
                                                        // label="Database/JDBC URL"
                                                        variant="outlined"
                                                        fullWidth
                                                        margin="dense"
                                                        size="small"
                                                        disabled={!useDatabaseUrl || !backendKey}
                                                        value={databaseUrl}
                                                        onChange={handleChangeInput}
                                                    />
                                                </Grid>
                                            </div>)}
                                    </Grid>

                                </DialogContent>
                            </>
                        )
                    }
                </DialogContent>

                <DialogActions className={classes.dialogActions}>
                    <Button variant="contained" onClick={(event) => activeTab === 'guidelines' ? this.handleTabChange(event, 'addDashboard') : handleConfirm(event)}>
                        {activeTab === 'guidelines' ? 'Next' : 'Save'}
                    </Button>
                    <Button  variant="outlined" onClick={handleClose}>
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
};

QuickDashboardModal.propTypes = {
    //data
    open: PropTypes.bool.isRequired,
    classes: PropTypes.object.isRequired,
    objectData: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    isLocked: PropTypes.bool,
    open: PropTypes.bool,
    targetConfiguration: PropTypes.object,

    //func
    handleClose: PropTypes.func,
    handleConfirm: PropTypes.func,
    handleChangeInput: PropTypes.func,
    handleDashboardTypeChange: PropTypes.func,
    handleChangeProperty: PropTypes.func,
    handleBackendSelect: PropTypes.func,
};

export default withStyles(styles)(QuickDashboardModal);
