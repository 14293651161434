import {WEB_SOCKET_ACTION_PREFIX, WEB_SOCKET_SUBSCR_PREFIX} from "constants/webSocketConstants";
import {publish} from "./hlcCommonWebSocketClient";
import ExecuteDemoTargetsGenerationStompFrame
    from "types/payload/infrastructure/simulation/ExecuteDemoTargetsGenerationStompFrame";
import SimulationOperationStompFrame
    from "types/payload/infrastructure/simulation/SimulationOperationStompFrame";
import SimulationOperation from "types/payload/infrastructure/simulation/SimulationOperation";

export const generateDemoTargets = (appState: any, backendKey: string, payload: ExecuteDemoTargetsGenerationStompFrame) =>
    publish(
        appState,
        backendKey,
        `${WEB_SOCKET_ACTION_PREFIX}/ui.infrastructure.simulation.targets.generate.execute`,
        payload
    );

export const deleteDemoTargets = (appState: any, backendKey: string) =>
    publish(
        appState,
        backendKey,
        `${WEB_SOCKET_ACTION_PREFIX}/ui.infrastructure.simulation.targets.delete.execute`,
        {
            operation: SimulationOperation.DELETE_DEMO_TARGETS
        } as SimulationOperationStompFrame
    );

export const SIMULATION_OPERATION_SUBSCRIPTION
    = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.infrastructure.simulation.operation.results`;

