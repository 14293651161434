import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';
import {Button} from "@material-ui/core";
import GateNavBarContainer from "containers/common/NavBarContainer";
import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import FormHelperText from "@material-ui/core/FormHelperText";
import Input from "@material-ui/core/Input";

const styles = theme => ({
  root: {
    height: '100%',
  },
  section: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100% - 72px)',
    textAlign: 'center',
    backgroundColor: '#F5F5F5',
  },
  wrapper: {
    width: '30rem',
    display: 'inline-block',
  },
  leftIcon: {
    marginRight: theme.spacing(1),
    opacity: .2,
  },

  notchedOutline: {
    borderRadius: '2rem',
    borderColor: '#fff !important'
  },

  cssLabel: {
    color: '#fff'
  },
  cssFocused: {
    color: '#fff !important',
  },
  cssError: {
    color: 'red !important',
  },
  passwordInput: {
    color: '#fff',
    // fontSize: '1.5rem',
  },


  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    boxShadow: '0 5px 15px rgba(0,0,0,.5)',
    borderRadius: '8px'
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  hint: {
    marginTop: theme.spacing(1)
  },
  progress: {
    margin: theme.spacing(2),
  },

});

const ChangePasswordButton = withStyles(theme => ({
  root: {
    marginTop: theme.spacing(3),
    background: theme.palette.primary.main,
    borderRadius: '4px',
    '&:hover': {
      background: theme.palette.primary.main
    }
  },
  label: {
    color: theme.palette.secondary.contrastText,
  }
}))(Button);

class ChangePassword extends Component {
  render() {
    const {classes, onChangeInputHandler, onSubmit, errors, isLocked } = this.props;

    return (
      <div className={classes.root}>
        <GateNavBarContainer />

        <section className={classes.section}>
          <div className={classes.wrapper}>
            <Paper className={classes.paper}>
              <Typography component="h1" variant="h5">
                Change Password
              </Typography>

              <form className={classes.form}>
                <FormControl margin="normal" required fullWidth >
                  <InputLabel htmlFor="password">New Password</InputLabel>
                  <Input id="password" name="password" type="password" onChange={onChangeInputHandler} disabled={isLocked}/>
                  {errors.password !== '' ? (
                    <FormHelperText error={true}>Error: {errors.password}</FormHelperText>
                  ) : null}
                </FormControl>
                <FormControl margin="normal" required fullWidth >
                  <InputLabel htmlFor="password2">Retype New Password</InputLabel>
                  <Input name="password2" type="password" id="password2" onChange={onChangeInputHandler} disabled={isLocked}/>
                  {errors.password2 !== '' ? (
                    <FormHelperText error={true}>Error: {errors.password2}</FormHelperText>
                  ) : null}

                </FormControl>

                <ChangePasswordButton
                  type="submit"
                  fullWidth
                  variant="contained"
                  onClick={onSubmit}
                  disabled={isLocked}
                  style={{width: '134px', padding:"18px 12px"}}
                >
                  Change Password
                </ChangePasswordButton>

              </form>
            </Paper>

          </div>
        </section>
      </div>
    );
  }
}

ChangePassword.propTypes = {
  //data
  errors: PropTypes.object,
  isLocked: PropTypes.bool,

  //func
  onChangeInputHandler: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default withStyles(styles)(ChangePassword);