import withStyles from "@material-ui/core/styles/withStyles";
import React, {Component} from "react";
import MuiInput from '@material-ui/core/Input';

const textFieldStyles = theme => ({
  root: {
    paddingRight: '5px',
  },
  input: {
    // height: '1rem',
    textAlign: props => (props.textAlign === "left") ? "left" : "right",
    padding: '.4rem .5rem',
    border: '1px solid #D1D1D1',
    borderRadius: '4px',
    borderBottom: '1px solid #616161'
    // '&:focus': {
    //   outline: '2px dotted transparent',
    //   border: '1px solid #00a1c9',
    //   boxShadow: '0 0 0 1px #00a1c9',
    // },
  },
});

class TextField extends Component {

  render() {
    const {classes, textAlign, ...other} = this.props;

    return (
        <MuiInput
            classes={classes}
            disableUnderline
            {...other}
        />
    );
  }
}

export default withStyles(textFieldStyles)(TextField)
