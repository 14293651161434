import React from "react";
import FinOpsReportConsumption from "../../../../types/model/finops/regular/report/FinOpsReportConsumption";
import SectionContainer from "dashlets/components/atomic/SectionContainer";
import SectionHeader from "dashlets/components/atomic/SectionHeader";
import Grid from "@material-ui/core/Grid";
import HighchartsReact from "highcharts-react-official";
import Highcharts, {Chart} from "highcharts";
import TargetDetailsSection from "./TargetDetailsSection";

interface ConsumptionSectionProps {
    title?: string;
    data: FinOpsReportConsumption;
}

const ConsumptionSection: React.FC<ConsumptionSectionProps> = ({
                                                                   title,
                                                                   data,
                                                               }) => {
    const [hiddenTargetIndexes, setHiddenTargetIndexes] = React.useState<number[]>([]);
    const buildBarChartOptions = (series: any[], categories: string[], yAxisTitle: string, hiddenTargetIndexes: number[]) => {
        return {
            chart: {
                type: 'column',
                height: 100,
                style: {
                    // fontFamily: 'Roboto'
                    fontFamily: 'Segoe UI'
                },
            },

            title: {
                text: ''
            },

            legend: {
                enabled: false
            },

            credits: {
                enabled: false
            },

            xAxis: {
                categories: categories
            },

            yAxis: {
                allowDecimals: false,
                min: 0,
                gridLineWidth: 0,
                title: {
                    text: yAxisTitle,
                    style: {
                        // fontFamily: 'Roboto',
                        fontFamily: 'Segoe UI',
                        fontWeight: 400,
                        fontSize: "8px"
                    },
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        // fontFamily: 'Roboto',
                        fontFamily: 'Segoe UI',
                        fontWeight: 400,
                        fontSize: "8px"
                    },
                }
            },

            tooltip: {
                format: '<b>{key}</b><br/>{series.name}: {y}<br/>'
            },

            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 400
                    },
                    chartOptions: {
                        xAxis: {
                            labels: {
                                enabled: false
                            }
                        }
                    }
                }]
            },

            series: series.filter((_, index) => !hiddenTargetIndexes.includes(index)),
        };
    }

    const toggleTarget = (index: number) => {
        setHiddenTargetIndexes((prevIndexes) =>
            prevIndexes.includes(index)
                ? prevIndexes.filter((i) => i !== index)
                : [...prevIndexes, index]
        );
    };

    const handleFirstChartRenderCallback = (chart: Chart) => {
        const colorsMap: Record<string, any> = {};
        // Access the chart instance
        chart.series[0].points.forEach(point => {
            colorsMap[point.series.name] = point.color;
            // console.log(point.color + " -> " + point.series.name)
        });
    }

    React.useEffect(() => {
        setHiddenTargetIndexes([]);
    }, [data])

    return (
        <>
            <SectionContainer>
                {title && <SectionHeader>{title}</SectionHeader>}
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={6}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={buildBarChartOptions(data?.creditsUsedBarChartSeries, data?.barChartsCategories, "Credits Used", hiddenTargetIndexes)}
                            callback={handleFirstChartRenderCallback}
                        />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={buildBarChartOptions(data?.costBarChartSeries, data?.barChartsCategories, "Cost ($)", hiddenTargetIndexes)}
                        />
                    </Grid>
                    {/*<Grid item xs={12} sm={6}>*/}
                    {/*    <HighchartsReact*/}
                    {/*        highcharts={Highcharts}*/}
                    {/*        options={buildBarChartOptions(data?.avgDailyCostBarChartSeries, data?.barChartsCategories, "Avg. Daily Costs ($)" hiddenTargetIndexes)}*/}
                    {/*    />*/}
                    {/*</Grid>*/}
                    <Grid item xs={12} sm={6}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={buildBarChartOptions(data?.numberOfQueriesBarChartSeries, data?.barChartsCategories, "Num. of queries", hiddenTargetIndexes)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <HighchartsReact
                            highcharts={Highcharts}
                            options={buildBarChartOptions(data?.elapsedTimeBarMsChartSeries, data?.barChartsCategories, "Elapsed time (ms)", hiddenTargetIndexes)}
                        />
                    </Grid>
                </Grid>
            </SectionContainer>

            <TargetDetailsSection
                data={data?.targetDetails}
                hiddenTargetIndexes={hiddenTargetIndexes}
                targetColors={data?.barChartsColors}
                toggleTarget={toggleTarget}
                showRecommendations={() => console.log(" ===> show recommendations!")}
                showObservations={() => console.log(" ===> show recommendations!")}
            />
        </>
    );
}

export default ConsumptionSection;
