import React, {useState} from "react";
import AzureIntegration from "types/model/settings/integration/cloud/AzureIntegration";
import useIntegrationSectionState from "hooks/integration/useIntegrationSectionState";
import AzureAuthenticationType from "types/model/settings/integration/cloud/AzureAuthenticationType";
import IntegrationCheckboxRow from "components/integrations/IntegrationCheckboxRow";
import IntegrationDropdownRow from "components/integrations/IntegrationDropdownRow";
import IntegrationTextFieldRow from "components/integrations/IntegrationTextFieldRow";
import IntegrationSaveButtonRow from "components/integrations/IntegrationSaveButtonRow";
import IntegrationContainer from "components/integrations/IntegrationContainer";

interface AzureIntegrationSectionProps {
    integration: AzureIntegration;
    onSave: (integration: AzureIntegration) => void;
}

interface ErrorMessages {
    clientId: string | null;
    clientSecret: string | null;
    tenantId: string | null;
}

const emptyErrorMessages = (): ErrorMessages => {
    return {
        clientId: null,
        clientSecret: null,
        tenantId: null,
    };
};

const authenticationTypes = {
    [AzureAuthenticationType.CLIENT_SECRET]: "Client-Secret Authentication",
}

const AzureIntegrationSection: React.FC<AzureIntegrationSectionProps> = ({
                                                                             onSave,
                                                                             ...props
                                                                         }) => {
    const {integration, handleChangeProperty, handleEnabledToggle}
        = useIntegrationSectionState(props.integration);
    const {
        enabled,
        authenticationType,
        clientId,
        clientSecret,
        tenantId
    } = integration as AzureIntegration;
    const [errors, setErrors] = useState<ErrorMessages>(emptyErrorMessages());

    const handleSave = () => {
        let hasError = false;
        const newErrors: ErrorMessages = emptyErrorMessages();

        if (enabled) {
            if (!clientId) {
                newErrors.clientId = "Client ID can't be empty.";
                hasError = true;
            }
            if (!clientSecret) {
                newErrors.clientSecret = "Client Secret Access Key can't be empty."
                hasError = true;
            }
            if (!tenantId) {
                newErrors.tenantId = "Tenant ID Secret Access Key can't be empty."
                hasError = true;
            }

            setErrors(newErrors);
        }
        if (!hasError) {
            newErrors.clientId = "";
            newErrors.clientSecret = "";
            newErrors.tenantId = "";
            setErrors(newErrors);
            onSave(integration as AzureIntegration);
        }
    };

    return (
        <>
            <IntegrationContainer>
                <IntegrationCheckboxRow
                    label="Enabled"
                    name="enabled"
                    value={enabled}
                    defaultValue={false}
                    toggle={handleEnabledToggle}
                />
                <IntegrationDropdownRow
                    label="Authentication"
                    name="authenticationType"
                    value={authenticationType}
                    defaultValue={AzureAuthenticationType.CLIENT_SECRET}
                    options={authenticationTypes}
                    enabled={enabled}
                    onChange={handleChangeProperty}
                />
                <IntegrationTextFieldRow
                    label="Client ID"
                    name="clientId"
                    value={clientId}
                    enabled={enabled}
                    error={errors.clientId}
                    onChange={handleChangeProperty}
                />
                <IntegrationTextFieldRow
                    label="Client Secret"
                    name="clientSecret"
                    value={clientSecret}
                    enabled={enabled}
                    error={errors.clientSecret}
                    onChange={handleChangeProperty}
                />
                <IntegrationTextFieldRow
                    label="Tenant ID"
                    name="tenantId"
                    value={tenantId}
                    enabled={enabled}
                    error={errors.tenantId}
                    onChange={handleChangeProperty}
                />
                <IntegrationSaveButtonRow onSave={handleSave} />
            </IntegrationContainer>
        </>
    );
};

export default AzureIntegrationSection;
