import React, { useState } from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import MinimizeIcon from "@material-ui/icons/Minimize";
import MaximizeIcon from "@material-ui/icons/CropDin";
import dialogTitleStyle from "assets/jss/common/components/dialogTitleStyle";
import { Tooltip } from "@material-ui/core";
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Chart from "@material-ui/icons/Assessment";
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import { ChartMultiple24Regular, Dismiss24Regular, FullScreenMaximize24Regular, FullScreenMinimize24Regular, ZoomOut24Regular } from '@fluentui/react-icons';


function DialogTitle({ ...props }) {
  const { children, classes, onClose, onMinimize, onMaximize, onLoadSelectedChart, onResetZoom, fullScreen, ...other } = props;
  const [isMaximized, setIsMaximized] = useState(false);

  const handleMaximizeClick = () => {
    setIsMaximized(!isMaximized);
    if (onMaximize) {
      onMaximize();
    }
  };

  const handleMinimizeClick = () => {
    setIsMaximized(false);
    if (onMinimize) {
      onMinimize();
    }
  };

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6" className={classes.typography}>{children}</Typography>
      {onMinimize ? (
        <Tooltip title="Minimize">
          <IconButton aria-label="minimize" className={classes.minimizeButton} onClick={handleMinimizeClick}>
            <fullScreenMinimize20Regular />
          </IconButton>
        </Tooltip>
      ) : null}
      {onMaximize ? (
        <Tooltip title={isMaximized ? "Minimize" : "Maximize"}>
          <IconButton aria-label="maximize" className={classes.maximizeButton} onClick={handleMaximizeClick}>
            {isMaximized ? <FullScreenMinimize24Regular /> : <FullScreenMaximize24Regular />}
          </IconButton>
        </Tooltip>
      ) : null}
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <Dismiss24Regular />
        </IconButton>
      ) : null}
      {onLoadSelectedChart ? (
        <Tooltip title="Load Template With Selected Time Range">
          <IconButton aria-label="close" className={classes.openChart} onClick={onLoadSelectedChart}>
            <ChartMultiple24Regular style={{ fontSize: '30px' }} />
          </IconButton>
        </Tooltip>
      ) : null}
      {onResetZoom ? (
        <Tooltip title="Reset Zoom">
          <IconButton aria-label="close" className={classes.resetZoomButton} onClick={onResetZoom}>
            <ZoomOut24Regular style={{ fontSize: '30px' }} />
          </IconButton>
        </Tooltip>
      ) : null}
    </MuiDialogTitle>
  );
}

export default withStyles(dialogTitleStyle)(DialogTitle)