import React, {Component} from 'react';
import {MuiThemeProvider} from '@material-ui/core/styles';
import history from "./routes/history";
import { Router, Route, Switch } from "react-router-dom";
import { Provider } from 'react-redux';

import indexRoutes from "./routes/index.jsx";
import AuthenticationAware from "./containers/common/auth/AuthenticationAware";

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { theme } from './theme/blue';
import store from "./reducers/store";
import {HLC_WEB_SOCKET_URL, IS_HLC_WS_ENABLED, IS_PEI_WS_ENABLED, PEI_WEB_SOCKET_URL} from "./config";
import StompClient from "./websocket/StompClient";
import {STOMP_CLIENT} from "./reducers/webSocketSlice";
import UserInfoLoader from "./loaders/UserInfoLoader";
import WebSocketSubscriptionsContainer from 'websocket/WebSocketSubscriptionsContainer';
import { ToastContainer } from 'react-toastify';
// import { Elements } from "@stripe/react-stripe-js";
// import { loadStripe } from "@stripe/stripe-js";
// const stripePromise = loadStripe(
//   process.env.NODE_ENV === "development" 
//     ? process.env.REACT_APP_TEST_STRIPE_PUBLISHABLE_KEY 
//     : process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

class App extends Component {
  render() {
    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <MuiThemeProvider theme={theme}>
            <Provider store={store}>
              {/* <Elements stripe={stripePromise}> */}
                <AuthenticationAware>
                    <UserInfoLoader />
                  <Router history={history}>
                    <Switch>
                      {indexRoutes.map((prop, key) => {
                        return <Route path={prop.path} component={prop.component} key={key} />;
                      })}
                    </Switch>
                  </Router>

                    {/*{IS_PEI_WS_ENABLED ? [*/}
                    {/*    <StompClient*/}
                    {/*        key="hlc-ws-stomp-client-1582840873831"*/}
                    {/*        stompClientKey={STOMP_CLIENT.PEI}*/}
                    {/*        sockJsEndpoint={PEI_WEB_SOCKET_URL}*/}
                    {/*        debugMode*/}
                    {/*    />,*/}
                    {/*] : null}*/}
                    {/*{IS_HLC_WS_ENABLED ? [*/}
                    {/*    <StompClient*/}
                    {/*        key="pei-ws-stomp-client-1582840873831"*/}
                    {/*        stompClientKey={STOMP_CLIENT.HLC}*/}
                    {/*        sockJsEndpoint={HLC_WEB_SOCKET_URL}*/}
                    {/*        debugMode*/}
                    {/*    />,*/}
                    {/*] : null}*/}
                </AuthenticationAware>
                <WebSocketSubscriptionsContainer />
                <div>
                  {/* <ReduxToastr
                    timeOut={5000}
                    newestOnTop={true}
                    preventDuplicates={false}
                    position="bottom-right"
                    transitionIn="fadeIn"
                    transitionOut="fadeOut"
                    progressBar
                    closeOnToastrClick/> */}

                  <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar
                    newestOnTop
                    closeOnClick
                    pauseOnFocusLoss={false}
                    theme="light"
                  />
                </div>
              {/* </Elements> */}
            </Provider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
    );
  }
}

export default App;
