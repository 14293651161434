import withStyles from "@material-ui/core/styles/withStyles";
import React, {Component} from "react";
import MuiSelect from '@material-ui/core/Select';
import { ChevronDown12Regular } from "@fluentui/react-icons";

const selectStyles = theme => ({
  root: {

  },
  select: {
    padding: '.4rem .5rem',
    border: '1px solid #D1D1D1',
    borderRadius: '4px !important',
    borderBottom: '1px solid #616161'
  },
  selectMenu: {

  },
});

class Select extends Component {

  render() {
    const {classes, ...other} = this.props;

    return (
        <MuiSelect
            classes={classes}
            disableUnderline
            fullWidth
            {...other}
            IconComponent={(props) => (
              <ChevronDown12Regular {...props} style={{ height: '20px', width: '20px' , marginRight: '7px', top: '8px' }} />
            )}
        />
    );
  }
}

export default withStyles(selectStyles)(Select)