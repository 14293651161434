import Typography from "@material-ui/core/Typography";
import { DASHBOARD_URL, HLC_URL, PEI_URL } from "config";
import React from 'react';
import PropTypes from 'prop-types';
import { AppBar, Toolbar } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';
import { ArrowDropUp } from "@material-ui/icons";
import ArrowDropDown from '@material-ui/icons/ArrowDropDown';
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Divider from '@material-ui/core/Divider';
import {
  BackendsListLink, BackendsLogsLink,
  ChangePasswordLink,
  HomeLink,
  IntegrationsAPMLink, JobsListLink,
  SubscriptionLink
} from 'routes/links';
import Link from '@material-ui/core/Link';
import { toastr } from 'react-redux-toastr';
import { ChevronDown12Regular, ChevronUp12Regular } from "@fluentui/react-icons";
import { showErrorNotification } from "components/toastr/notificationActions";

const styles = theme => ({
  grow: {
    flexGrow: 1,
  },
  title: {
    marginRight: theme.spacing(2),
    // fontWeight: 300,
    fontWeight: 400,
    color: '#242424 ',
  },
  appBar: {
    width: '100%',
    maxWidth: 'none',
    backgroundColor: '#FFFFFF',
    boxShadow: 'none',
    height: '48px',
    minHeight: '48px',
    boxShadow: '0 1px 7px rgba(0, 0, 0, 0.25)',
    position: 'relative',
  },
  toolbar: {
    height: '48px',
    minHeight: '48px',
  },
  leftBar: {
    display: 'flex',
    alignItems: 'center',
  },
  centerBar: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(3), //24
    paddingRight: theme.spacing(3), //24
  },
  rightBar: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(3), //24
    borderLeft: 'solid 1px rgba(255, 255, 255, 0.2)',
  },
  icon: {
    color: '#242424 ',
  },
  actionButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#fff',
    boxShadow: '0 1px 7px rgba(0, 0, 0, 0.25)',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },
  actionIcon: {
    color: '#000',
  },
  profileMenuLink: {
    fontSize: '13px',
    color: theme.palette.text.secondary,
    '&:hover': {
      color: '#0F6CBD'
    }
  },
});

const NavBarButton = withStyles(theme => ({
  root: {

  },
  label: {
    color: '#242424 ',
    // fontWeight: 600,
    fontWeight: 400,
    textTransform: 'none',
  }
}))(Button);

const ProfileNavBarButton = withStyles(theme => ({
  root: {

  },
  label: {
    color: '#242424 ',
    textTransform: 'none',
  }
}))(Button);

const NavBarMenu = withStyles(theme => ({
  paper: {
    marginTop: 32 //old 64
  },
}))(Menu);

const NavBarMenuItem = withStyles(theme => ({
  root: {
    color: theme.palette.text.secondary,
    fontSize: '13px',
    transition: 'all 0.3s ease',
    '&:hover': {
      color: '#0F6CBD',
      backgroundColor: '#fff',
    }
  },
}))(MenuItem);

class NavBar extends React.Component {
  state = {
    profileAnchorEl: null,
    productAnchorEl: null,
    isProfileMenuOpen: false,
    isApplicationsMenuOpen: false, 
  };

  showError = () => {
    showErrorNotification("Backend not Connected", "Please connect selected application's Backend");
  }

  handleProfileMenuLogout = event => {
    this.handleProfileMenuClose();
    this.props.handleLogout();
  };

  handleProfileMenuChangePassword = event => {
    this.handleProfileMenuClose();
    this.props.handleLogout();
  };

  handleProfileMenuOpen = event => {
    this.setState({ profileAnchorEl: event.currentTarget });
  };

  handleProfileMenuClose = () => {
    this.setState({ profileAnchorEl: null });
  };

  handleProductMenuOpen = event => {
    this.setState({ productAnchorEl: event.currentTarget });
  };

  handleProductMenuClose = () => {
    this.setState({ productAnchorEl: null });
  };

  handleProductMenuItemClick = (event, value) => {
    this.props.changeStartApplication(value);
    this.setState({ productAnchorEl: null });
  };

  handleEmailClick = () => {
    this.props.handleEmailClick();
  }

  openApplication = (appURL) => {
    window.open(appURL, "_blank")
  };

  handleManageSubscriptionClick = () => {
    this.props.getCustomerPortalURL(this.props.currentUserInfo.subscription);
  }

  render() {
    const { profileAnchorEl, productAnchorEl, product } = this.state;
    const { classes, startPageApplication, currentUserInfo, isHLCConnected, isPEIconnected } = this.props;
    const isProfileMenuOpen = Boolean(profileAnchorEl);
    const isApplicationsMenuOpen = Boolean(productAnchorEl);

    const renderApplicationsMenu = (
        <NavBarMenu
            anchorEl={productAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isApplicationsMenuOpen}
            onClose={this.handleProductMenuClose}
        >
          <NavBarMenuItem onClick={this.handleProductMenuClose}>
            <Link
                onClick={() => {this.openApplication(DASHBOARD_URL)}}
                underline="none"
                className={classes.profileMenuLink}
            >
              Dashboard
            </Link>
          </NavBarMenuItem>
          <NavBarMenuItem onClick={this.handleProductMenuClose}>
            <Link
                onClick={() => {this.openApplication(HLC_URL)}}
                underline="none"
                className={classes.profileMenuLink}
            >
              High Load Capture
            </Link>
          </NavBarMenuItem>
          <NavBarMenuItem onClick={this.handleProductMenuClose}>
            <Link
                onClick={() => {this.openApplication(PEI_URL)}}
                underline="none"
                className={classes.profileMenuLink}
            >
              Performance Explorer (Oracle)
            </Link>
          </NavBarMenuItem>
        </NavBarMenu>
    );

    const renderProfileMenu = (
        <NavBarMenu
            anchorEl={profileAnchorEl}
            anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
            open={isProfileMenuOpen}
            onClose={this.handleProfileMenuClose}
        >
          <NavBarMenuItem onClick={this.handleProfileMenuClose}>
            <Link
                component={HomeLink}
                underline="none"
                className={classes.profileMenuLink}
            >
              Home
            </Link>
          </NavBarMenuItem>
          
          {!currentUserInfo?.subscription || currentUserInfo?.subscription?.status !== "active" ?
            <NavBarMenuItem onClick={this.handleProfileMenuClose}>
              <Link
                component={SubscriptionLink}
                underline="none"
                className={classes.profileMenuLink}
              >
              Subscribe
              </Link> 
            </NavBarMenuItem> : 
            <NavBarMenuItem onClick={this.handleManageSubscriptionClick}>
              Manage Subscription
            </NavBarMenuItem>}
          
          <NavBarMenuItem onClick={this.handleProfileMenuClose}>
            <Link
                component={ChangePasswordLink}
                underline="none"
                className={classes.profileMenuLink}
            >
              Change Password
            </Link>
          </NavBarMenuItem>
          <NavBarMenuItem onClick={this.handleProfileMenuClose}>
            <Link
                component={IntegrationsAPMLink}
                underline="none"
                className={classes.profileMenuLink}
            >
              Integrations
            </Link>
          </NavBarMenuItem>
          <NavBarMenuItem onClick={this.handleProfileMenuClose}>
            <Link
                component={BackendsListLink}
                underline="none"
                className={classes.profileMenuLink}
            >
              Infrastructure
            </Link>
          </NavBarMenuItem>
          <NavBarMenuItem onClick={this.handleProfileMenuClose}>
            <Link
                underline="none"
                className={classes.profileMenuLink}
                onClick={() => {
                  if (!isHLCConnected && !isPEIconnected) {
                    this.showError();
                  } else if (isHLCConnected || isPEIconnected) {
                    this.props.handleEmailClick();
                  }
                }}
            >
              PE - Email Configuration
            </Link>
          </NavBarMenuItem>
          <NavBarMenuItem onClick={this.handleProfileMenuLogout}>Logout</NavBarMenuItem>
          <Divider/>
          <NavBarMenuItem disabled={true}>Signed in as : {currentUserInfo?.username}</NavBarMenuItem>
        </NavBarMenu>
    );

    return (
        <AppBar position="static" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Icon className="logo" />
            <Typography variant="h6" className={classes.title}>
              Enteros UpBeat
            </Typography>
            <NavBarButton
                variant="text"
                aria-owns={isProfileMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleProductMenuOpen}
            >
              {/*{APP_CONF[startPageApplication].NAME}*/}
              Applications
              {isApplicationsMenuOpen ? (
              <ChevronUp12Regular style={{ height: '16px', width: '16px', marginLeft: '4px'}} />
            ) : (
              <ChevronDown12Regular style={{ height: '16px', width: '16px', marginLeft: '4px'}} />
            )}
            </NavBarButton>

            <div className={classes.grow} />

            <ProfileNavBarButton
                variant="text"
                aria-owns={isProfileMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
            >
              {currentUserInfo?.name}
              {isProfileMenuOpen ? (
              <ChevronUp12Regular style={{ height: '16px', width: '16px', marginLeft: '4px'}} />
            ) : (
              <ChevronDown12Regular style={{ height: '16px', width: '16px', marginLeft: '4px'}} />
            )}
            </ProfileNavBarButton>
          </Toolbar>
          {renderApplicationsMenu}
          {renderProfileMenu}
        </AppBar>
    )
  }
}

NavBar.propTypes = {
  //data
  currentUserInfo: PropTypes.object,
  startPageApplication: PropTypes.string,
  isHLCConnected: PropTypes.bool,
  isPEIconnected: PropTypes.bool,

  //func
  handleLogout: PropTypes.func.isRequired,
  changeStartApplication: PropTypes.func,
  handleEmailClick: PropTypes.func,
  getCustomerPortalURL: PropTypes.func
};

export default withStyles(styles)(NavBar);