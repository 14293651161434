import React, { useState } from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import { Input } from "@material-ui/core";
import { InputAdornment, IconButton, Tooltip, Zoom } from "@material-ui/core";
import { ArrowDownload20Filled, ArrowUpload20Filled } from '@fluentui/react-icons';

const FileInput = (props) => {

    const {
        multiple = false,
        value = "",
        onDone = () => {
        }
    } = props;

    const [isDragging, setIsDragging] = useState(false);
    const [fileName,setFileName] = useState([]);

    const [importFile, setImportFile] = useState({});
    const [importFileName, setImportFileName] = useState('');

    const inputRef = React.useRef(null);

    const handleFileRead = (files) => {
        const allFiles = [];
        Array.from(files).forEach((file) => {
          const reader = new FileReader();
    
          reader.readAsDataURL(file);
    
          reader.onload = () => {
            const fileInfo = {
              name: file.name,
              type: file.type,
              size: Math.round(file.size / 1000) + " kB",
              base64: reader.result,
              file: file,
            };
    
            allFiles.push(fileInfo);
    
            if (allFiles.length === files.length) {
              if (multiple) onDone(allFiles);
              else onDone(allFiles[0]);
            }
            setFileName(allFiles)
          };
        });
    };

    const handleChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setImportFileName(file.name);
            setImportFile(file);

            let reader = new FileReader();

            reader.readAsDataURL(file);

            reader.onload = () => {
                const fileInfo = {
                name: file.name,
                type: file.type,
                size: Math.round(file.size / 1000) + ' kB',
                base64: reader.result,
                file: file,
                };
                props.onDone(fileInfo);
            }
        }
    }

    const handleDragOver = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };
    
    const handleDragLeave = () => {
        setIsDragging(false);
    };
    
    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const files = e.dataTransfer.files;
        if (files.length) {
          handleFileRead(files);
        }
    };
    
    const handleFileSelect = (e) => {
        const files = e.target.files;
        if (files.length) {
          handleFileRead(files);
        }
    };

    return <React.Fragment>
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "150px",
                backgroundColor: "#EBF3FC",
                cursor: "pointer",
                border: isDragging ? "2px solid #EBF3FC": "",
                backgroundColor: isDragging ? "#fff" : "#EBF3FC",
                textAlign: "center",
                fontFamily:"Segoe UI"
            }}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => inputRef.current.click()}
        >
            <input
                type="file"
                ref={inputRef}
                onChange={handleFileSelect}
                style={{ display: 'none' }}
                accept="zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed"
            />
            <Tooltip title="Upload ZIP" placement="top" TransitionComponent={Zoom} arrow>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'absolute'}}>
                    <ArrowDownload20Filled style={{color: '#0F6CBD'}}/>
                    {/* <lable style={{marginLeft: '5px',color:"#115EA3"}}>Upload Zip File</lable> */}
                    <lable style={{marginLeft: '5px',color:"#115EA3"}}>
                        {fileName && fileName?.length > 0 ? (
                            fileName?.map((item, index) => (
                                <span key={index}>
                                    {item?.name}
                                </span>
                            ))
                        ) : (
                            "Upload Zip File"
                        )}</lable>
                </div>
            </Tooltip>
        </div>
    </React.Fragment>

}

export default FileInput;