import React from "react";
import {TableCell} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {formatNumber} from "utils/FormattingUtils";

interface NumberTableCellProps {
    value: number | null;
}

const useStyles = makeStyles({
    tableCell: {
        textAlign: "right",
        // fontSize: "0.6vw",
        fontSize: "12px",
    }
});

const NumberTableCell: React.FC<NumberTableCellProps> = ({ value }) => {
    const classes = useStyles();

    return <TableCell className={classes.tableCell}>{value != null ? formatNumber(value) : "N/A"}</TableCell>;
};

export default NumberTableCell;
