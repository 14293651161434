import React from "react";
import {filter as _filter, concat as _concat} from "lodash";
import AddIcon from "@material-ui/icons/Add";
import GetAppIcon from '@material-ui/icons/GetApp';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import Paper from "@material-ui/core/Paper";
import {
    Box, List, ListItem,
    ListItemText, Collapse,
    ListItemSecondaryAction,
    Grid, Chip
} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import Typography from "@material-ui/core/Typography";
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import PersonAddIcon from "@material-ui/icons/PersonAdd";
import ClearIcon from "@material-ui/icons/Clear";
import {useDispatch, useSelector} from "react-redux";
import {
    createBackend,
    deleteBackend, downloadBackend, downloadBackendProperties, getBackendSecretKey,
    grantAccess,
    listBackends,
    revokeAccess,
    updateBackend
} from "actions/infrastructure/backendActions";
import {
    selectBackendManagementEntities,
    selectBackendManagementIds
} from "reducers/infrastructure/backendManagementSlice";
import BackendUserDTO from "types/model/infrastructure/BackendUserDTO";
import BackendDTO, {getDefaultBackendDTO} from "types/model/infrastructure/BackendDTO";
import BackendDialog from "./BackendDialog";
import ConfirmationDialog from "components/common/ConfirmationDialog";
import GrantAccessDialog from "./GrantAccessDialog";
import CopyIconButton from "components/common/CopyIconButton";
import Tooltip from "@material-ui/core/Tooltip";
import { selectBackendCurrentUserInfo } from "reducers/userInfoSlice";
import { Add20Filled, ArrowDownload20Filled, ChevronDown12Regular, ChevronUp12Regular, Delete20Filled, DrawerArrowDownload20Filled, Edit20Filled, PersonAdd20Filled } from "@fluentui/react-icons";


const labelStyle = {
    fontSize: "0.875rem",
};

const valueStyle = {
    fontSize: "0.875rem",
}

const SECRET_KEY_HIDDEN = "****************************************************************"

const BackendsListPage: React.FC = ({}) => {
    const dispatch = useDispatch();
    const backendEntities = useSelector(selectBackendManagementEntities);
    const backendIds = useSelector(selectBackendManagementIds);
    const currentUserInfo = useSelector(selectBackendCurrentUserInfo);
    const [openBackendIdList, setOpenBackendIdList] = React.useState<number[]>([]);
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
    const [dialogBackend, setDialogBackend] = React.useState<BackendDTO>(getDefaultBackendDTO);
    const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = React.useState<boolean>(false);
    const [deleteBackendId, setDeleteBackendId] = React.useState<number | null>(null);
    const [revokeAccessBackendUser, setRevokeAccessBackendUser] = React.useState<BackendUserDTO | null>(null);
    const [revokeAccessConfirmationDialogOpen, setRevokeAccessConfirmationDialogOpen] = React.useState<boolean>(false);
    const [grantAccessBackend, setGrantAccessBackend] = React.useState<BackendDTO | null>(null);
    const [grantAccessDialogOpen, setGrantAccessDialogOpen] = React.useState<boolean>(false);
    const [secretKeyVisibleIdList, setSecretKeyVisibleIdList] = React.useState<number[]>([]);

    const handleListItemClick = (backendId: number) => {
        if (openBackendIdList.indexOf(backendId) !== -1) {
            setOpenBackendIdList(_filter(openBackendIdList, (el) => el !== backendId));
        } else {
            setOpenBackendIdList(_concat(openBackendIdList, [backendId]));
        }
    };

    const showBackendSecretKey = (backendId: number) => {
        if (!backendEntities[backendId].backendSecretKey) {
            dispatch(getBackendSecretKey(backendId));
        }
        setSecretKeyVisibleIdList(_concat(secretKeyVisibleIdList, [backendId]));
    }

    const hideBackendSecretKey = (backendId: number) => {
        setSecretKeyVisibleIdList(_filter(secretKeyVisibleIdList, (el) => el !== backendId));
    }

    const isBackendSecretKeyVisible = (backendId: number) =>
        secretKeyVisibleIdList.indexOf(backendId) !== -1 && backendEntities[backendId].backendSecretKey;

    const handleSecretKeyVisibilityClick = (backendId: number) =>
        isBackendSecretKeyVisible(backendId) ? hideBackendSecretKey(backendId) : showBackendSecretKey(backendId);

    const openBackendDialog = (backend: BackendDTO) => {
        setDialogBackend(backend);
        setDialogOpen(true);
    }

    const closeBackendDialog = () => {
        setDialogBackend(getDefaultBackendDTO);
        setDialogOpen(false);
    }

    const openDeleteConfirmationDialog = (backendId: number) => {
        setDeleteBackendId(backendId);
        setDeleteConfirmationDialogOpen(true);
    }

    const closeDeleteConfirmationDialog = () => {
        setDeleteBackendId(null);
        setDeleteConfirmationDialogOpen(false);
    }

    const confirmDeleteBackend = (backendId: number | null) => {
        if (backendId) {
            dispatch(deleteBackend(backendId));
        }
        closeDeleteConfirmationDialog();
    }

    const openRevokeAccessConfirmationDialog = (backendUser: BackendUserDTO) => {
        setRevokeAccessBackendUser(backendUser);
        setRevokeAccessConfirmationDialogOpen(true);
    }

    const closeRevokeAccessConfirmationDialog = () => {
        setRevokeAccessBackendUser(null);
        setRevokeAccessConfirmationDialogOpen(false);
    }

    const confirmRevokeAccess = (backendUser: BackendUserDTO) => {
        dispatch(revokeAccess(backendUser.backendId, backendUser.username))
        closeRevokeAccessConfirmationDialog();
    }

    const openGrandAccessDialog = (backend: BackendDTO) => {
        setGrantAccessBackend(backend);
        setGrantAccessDialogOpen(true);
    }

    const closeGrantAccessDialog = () => {
        setGrantAccessBackend(null);
        setGrantAccessDialogOpen(false)
    }

    const confirmGrantAccess = (backendId: number | null, username: string) => {
        if (backendId) {
            dispatch(grantAccess(backendId, username));
        }
        closeGrantAccessDialog();
    }

    const handleDeleteUserClick = (backendUser: BackendUserDTO) => openRevokeAccessConfirmationDialog(backendUser);

    const handleAddUserClick = (backendId: number) => openGrandAccessDialog(backendEntities[backendId]);

    const handleDownloadClick = (backendId: number) => dispatch(downloadBackend(backendId));

    const handleDownloadPropertiesClick = (backendId: number) => dispatch(downloadBackendProperties(backendId));

    const handleAddBackendClick = () => openBackendDialog(getDefaultBackendDTO());

    const handleEditBackendClick = (backendId: number) => {
        openBackendDialog(backendEntities[backendId]);
    }

    const handleDeleteBackendClick = (backendId: number) => {
        openDeleteConfirmationDialog(backendId);
    };

    const handleSaveBackend = (backend: BackendDTO) => {
        backend.id ? dispatch(updateBackend(backend)) : dispatch(createBackend(backend));
        closeBackendDialog();
    };

    const handleChangeBackendProperty = (propertyName: string, propertyValue: string) => {
        setDialogBackend({
            ...dialogBackend,
            [propertyName]: propertyValue
        })
    };

    React.useEffect(() => {
        dispatch(listBackends());
    }, []);

    // @ts-ignore
    return (
        <>
            <Box>
                <Grid container alignItems="center" justifyContent="space-between">
                    <Grid item xs>
                        <Typography variant="h6" id="tableTitle" component="div" style={{paddingLeft: "16px", textAlign: "left"}}>
                            Backends
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Tooltip title="+ Add DB Backen">
                            <IconButton color="primary" onClick={handleAddBackendClick}>
                                <Add20Filled />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
                <Paper>
                    <List dense disablePadding>
                        {backendIds.map((backendId: number) => {
                            const backend = backendEntities[backendId];

                            return (
                                <React.Fragment key={backendId}>
                                    <ListItem button onClick={() => handleListItemClick(backendId)} style={{ boxShadow: '0px 2px 8px 0px #00000024', width: '98%', marginLeft: '1.7%'}}>
                                        {openBackendIdList.indexOf(backendId) !== -1 ? <ChevronUp12Regular style={{ height: '16px', width: '16px'}} /> : <ChevronDown12Regular style={{ height: '16px', width: '16px'}} />}
                                        {/*<ListItemIcon>*/}
                                        {/*    <FiberManualRecordIcon style={{ color: row.status ? "green" : "red" }} />*/}
                                        {/*</ListItemIcon>*/}
                                        <ListItemText primary={backend.name} style={{marginLeft: '10px'}}/>
                                        <ListItemSecondaryAction>
                                            {backend.isOwner && (
                                                <>
                                                    <Tooltip  title="Download DB Backend">
                                                        <IconButton color="primary" size="small" onClick={() => handleDownloadClick(backendId)}>
                                                            <ArrowDownload20Filled />
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip  title="Download DB Backend Properties">
                                                        <IconButton color="primary" size="small" onClick={() => handleDownloadPropertiesClick(backendId)}>
                                                            <DrawerArrowDownload20Filled fontSize="small"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Add User to the DB Backend">
                                                        <IconButton color="primary" size="small" onClick={() => handleAddUserClick(backendId)}>
                                                            <PersonAdd20Filled fontSize="small"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Edit DB Backend">
                                                        <IconButton color="primary" size="small" onClick={() => handleEditBackendClick(backendId)}>
                                                            <Edit20Filled fontSize="small"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                    <Tooltip title="Delete DB Backend">
                                                        <IconButton color="primary" size="small" onClick={() => handleDeleteBackendClick(backendId)}>
                                                            <Delete20Filled fontSize="small"/>
                                                        </IconButton>
                                                    </Tooltip>
                                                </>
                                            )}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <Collapse in={openBackendIdList.indexOf(backendId) !== -1} timeout="auto" collapsedSize={"10px"} >
                                        <List component="div" dense disablePadding>
                                            <ListItem style={{ width: '98%', marginLeft: '1.7%', marginBottom: '10px'}}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={2}>
                                                        <Typography variant="subtitle1"
                                                                    style={labelStyle}>Description:</Typography>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <Typography variant="body1"
                                                                    style={valueStyle}>{backend.description}</Typography>
                                                    </Grid>
                                                    {(backend.backendType != "CLOUD_BACKEND" ||
                                                     (backend.backendType == "CLOUD_BACKEND" && currentUserInfo.id === backend.owner.id)) && (
                                                        <>
                                                            <Grid item xs={2}>
                                                                <Typography variant="subtitle1"
                                                                            style={labelStyle}>Backend Key:</Typography>
                                                            </Grid>
                                                            <Grid item xs={10} container alignItems="center">
                                                                <Typography variant="body1"
                                                                            style={valueStyle}>{backend.backendKey}</Typography>
                                                                <CopyIconButton text={backend.backendKey} size="small" />
                                                            </Grid>
                                                            <Grid item xs={2}>
                                                                <Typography variant="subtitle1"
                                                                            style={labelStyle}>Secret Key:</Typography>
                                                            </Grid>
                                                            <Grid item xs={10} container alignItems="center">
                                                                <Typography
                                                                    variant="body1"
                                                                    style={valueStyle}
                                                                >
                                                                    {isBackendSecretKeyVisible(backendId) ? backend.backendSecretKey : SECRET_KEY_HIDDEN}
                                                                </Typography>
                                                                <IconButton
                                                                    size="small"
                                                                    onClick={() => handleSecretKeyVisibilityClick(backendId)}
                                                                >
                                                                    {isBackendSecretKeyVisible(backendId)
                                                                        ? <VisibilityOffIcon fontSize="small" />
                                                                        : <VisibilityIcon fontSize="small" />}
                                                                </IconButton>
                                                                <CopyIconButton text={backend.backendSecretKey} size="small" />
                                                            </Grid>
                                                        </>
                                                        )}
                                                    <Grid item xs={2}>
                                                        <Typography variant="subtitle1"
                                                                    style={labelStyle}>Owner:</Typography>
                                                    </Grid>
                                                    <Grid item xs={10}>
                                                        <Typography variant="body1"
                                                                    style={valueStyle}>{backend.owner.username}</Typography>
                                                    </Grid>
                                                    {backend.isOwner && (
                                                        <>
                                                            <Grid item xs={2}>
                                                                <Typography variant="subtitle1"
                                                                            style={labelStyle}>Shared with:</Typography>
                                                            </Grid>
                                                            <Grid item xs={10}>
                                                                <Grid container spacing={1}>
                                                                    {backend.users.map((backendUser: BackendUserDTO) => (
                                                                        <Grid item key={backendUser.id}>
                                                                            <Chip
                                                                                label={`${backendUser.name} (${backendUser.username})`}
                                                                                onDelete={() => handleDeleteUserClick(backendUser)}
                                                                                deleteIcon={<Tooltip title="Remove User from th DB Bsckend"><ClearIcon/></Tooltip>}
                                                                            />
                                                                        </Grid>
                                                                    ))}
                                                                </Grid>
                                                            </Grid>
                                                        </>
                                                    )}
                                                </Grid>
                                            </ListItem>
                                        </List>
                                    </Collapse>
                                </React.Fragment>
                            )
                        })}
                    </List>
                </Paper>
            </Box>
            <BackendDialog
                open={dialogOpen}
                backend={dialogBackend}
                onClose={closeBackendDialog}
                onSubmit={handleSaveBackend}
                onChange={handleChangeBackendProperty}
            />
            <ConfirmationDialog
                open={deleteConfirmationDialogOpen}
                object={deleteBackendId}
                title="Confirm delete Backend"
                message={`Do you want to delete Backend '${deleteBackendId ? backendEntities[deleteBackendId].name : ''}'`}
                submitLabel="Delete"
                onClose={closeDeleteConfirmationDialog}
                onSubmit={confirmDeleteBackend}
            />
            <ConfirmationDialog
                open={revokeAccessConfirmationDialogOpen}
                object={revokeAccessBackendUser}
                title="Revoke Backend Access"
                message={`Do you want to revoke access for '${revokeAccessBackendUser?.username}' from ${revokeAccessBackendUser?.backendId ? backendEntities[revokeAccessBackendUser?.backendId].name : ''}'`}
                submitLabel="Revoke Access"
                onClose={closeRevokeAccessConfirmationDialog}
                onSubmit={confirmRevokeAccess}
            />
            <GrantAccessDialog
                open={grantAccessDialogOpen}
                backend={grantAccessBackend}
                onClose={closeGrantAccessDialog}
                onSubmit={confirmGrantAccess}
            />
        </>
    );
};

export default BackendsListPage;
