import AppBar from "@material-ui/core/AppBar";
import Divider from "@material-ui/core/Divider";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import Grid from "@material-ui/core/Grid";
import MenuItem from "@material-ui/core/MenuItem";
import Hidden from "components/common/Hidden/Hidden";
import Select from "components/common/Select/Select";
import Switch from "components/common/Switch/Switch";
import TextField from "components/common/TextField/TextField";
import TimeRangeTab from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTab";
import TimeRangeTabPanel from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTabPanel";
import TimeRangeTabs from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTabs";
import React, { Component } from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import { Scrollbars } from 'react-custom-scrollbars';
import { Calendar12Regular, CalendarDate24Regular, Clock12Regular, Earth16Regular, Hourglass16Regular, Smartwatch24Regular, WeatherSunny16Regular } from "@fluentui/react-icons";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  root: {
    maxWidth: 420,
  },
  appBar: {
    backgroundColor: '#fff',
    boxShadow: 'none',
  },
  boxWrapper: {
    display: 'inline-flex',
    marginRight: '8px',
    height: '1rem'
  },
  formControl: {
    margin: theme.spacing(1),
  },
  formControlLabel: {
    margin: theme.spacing(1),
    minWidth: '40px'
  },
  formControlLabelLong: {
    margin: theme.spacing(1),
    minWidth: '160px'
  },
  formControlInput: {
    margin: theme.spacing(1),
    minWidth: '60px',
  },
  formControlInputLong: {
    margin: theme.spacing(1),
    minWidth: '168px',
  },
  formControlSelect: {
    margin: theme.spacing(1),
    minWidth: '92px',
  },
  formControlDatetime: {
    margin: theme.spacing(1),
    width: '220px',
    minWidth: '160px',
  },
  footer: {
    display: 'flex',
    '& > *': {
      margin: theme.spacing(1),
    }
  },
  grow: {
    flexGrow: 1,
  },
  switchRoot: {
    marginLeft: 0,
    marginRight: 0
  },
  switchLabel: {
    // minWidth: 100,
    marginLeft: 8,
  },
  groupBySelect: {
    // minWidth: 200,
    float: 'right',
    paddingRight: '4px',
  },
  panel: {

  },
  legend: {
    fontSize: '14px',
  },
  formLabel: {
    color: '#242424',
  },
});


class HLCChartDashletSettings extends Component {

  handleChangeInput = (event) => {
    this.props.handleChangeProperty(event.target.name, event.target.value);
  };

  toggle = (event) => {
    const name = event.target.name;
    this.props.handleChangeProperty(name, !this.props.settings[name]);
  };


  render() {
    const {
      classes,
      settings: {
        groupBy,
        metricsPerGroup,
        isFilterLowValue,
        isSpikeAnalysis,
        filterLowValue,
        spikeAnalysisValue,
        isFilterHighValue,
        filterHighValue,
        isSplitMetrics,
        chartsPerPage,
        isSmoothData,
        smoothDataValue,
        smoothDataMeasure,
        isNormalizeScale,
        isRegressionTrend,
        isLogarithmic,
        reportMeasurementsPerGroup,
        refreshRateValue,
        refreshRateMeasure,
      },
      dashlet,
      handleConfirm,
      handleClose
    } = this.props;

    const displayAs = this.props.settings?.displayAs ? this.props.settings?.displayAs : "chart"

    return (
      // <Paper className={classes.root}>
      <div>
        <AppBar position="relative" className={classes.appBar} component="div">
          <TimeRangeTabs value="Settings">
            <TimeRangeTab
              label="Settings"
              value="Settings"
            />
          </TimeRangeTabs>
        </AppBar>

        <Scrollbars style={{ width: '52vh', height: '44vh', marginRight: 5 }}>
          <TimeRangeTabPanel>
            <Grid
              container
              spacing={1}
              direction="row"
              justify="center"
              alignItems="center">

              <Grid item xs={12}>
                <FormLabel component="legend" className={classes.legend}>
                  Defination
                </FormLabel>
              </Grid>

              <Grid item xs={5}>
                <FormLabel className={classes.formLabel}>
                  Display As
                </FormLabel>
              </Grid>
              <Grid item xs={7}>
                <Select value={displayAs} name="displayAs" onChange={this.handleChangeInput} className={classes.groupBySelect}>
                  <MenuItem value="chart">Chart</MenuItem>
                  <MenuItem value="report">Report</MenuItem>
                </Select>
              </Grid>

              <Grid item xs={12}>
                <FormLabel component="legend" className={classes.legend}>
                  Grouping
                </FormLabel>
              </Grid>
              <Grid item xs={5}>
                <FormLabel className={classes.formLabel}>
                  Group By
                </FormLabel>
              </Grid>
              <Grid item xs={7}>
                <Select value={groupBy} name="groupBy" onChange={this.handleChangeInput} className={classes.groupBySelect}>
                  <MenuItem value="none">None</MenuItem>
                  <MenuItem value="target">Target</MenuItem>
                  <MenuItem value="capture">Capture</MenuItem>
                  <MenuItem value="metric">Metric</MenuItem>
                  <MenuItem value="measurement">Measurement</MenuItem>
                  <MenuItem value="metric_and_measurement">Metric and Measurement</MenuItem>
                </Select>
              </Grid>

              <Hidden hidden={displayAs !== 'chart'}>
                <Grid item xs={5}>
                  <FormLabel className={classes.formLabel}>
                    Metrics Per Group
                  </FormLabel>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    name="metricsPerGroup"
                    onChange={this.handleChangeInput}
                    value={metricsPerGroup}
                    fullWidth
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 120,
                      type: 'number',
                    }}
                  />
                </Grid>
              </Hidden>

              <Grid item xs={12}>
                <FormLabel component="legend" className={classes.legend}>
                  Filtering
                </FormLabel>
              </Grid>

              <Grid item xs={5}>
                <FormControlLabel
                  control={<Switch checked={isFilterLowValue}
                    onChange={this.toggle}
                    name="isFilterLowValue"
                  />}
                  label="Low Value"
                  classes={{ root: classes.switchRoot, label: classes.switchLabel }}
                />
              </Grid>
              <Grid item xs={7}>
                <TextField
                  name="filterLowValue"
                  onChange={this.handleChangeInput}
                  value={filterLowValue}
                  fullWidth
                  inputProps={{
                    step: 0.1,
                    min: 0.1,
                    type: 'number',
                  }}
                  disabled={!isFilterLowValue}
                />
              </Grid>

              <Hidden hidden={displayAs !== 'chart'}>
                <Grid item xs={5}>
                  <FormControlLabel
                    control={<Switch checked={isFilterHighValue}
                      onChange={this.toggle}
                      name="isFilterHighValue"
                    />}
                    label="High Value"
                    classes={{ root: classes.switchRoot, label: classes.switchLabel }}
                  />
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    name="filterHighValue"
                    onChange={this.handleChangeInput}
                    value={filterHighValue}
                    fullWidth
                    inputProps={{
                      step: 0.1,
                      min: 0.1,
                      type: 'number',
                    }}
                    disabled={!isFilterHighValue}
                  />
                </Grid>
              </Hidden>

              <Grid item xs={12}>
                <FormControlLabel
                  control={<Switch checked={isSplitMetrics}
                    onChange={this.toggle}
                    name="isSplitMetrics"
                  />}
                  label="Split Metrics"
                  classes={{ root: classes.switchRoot, label: classes.switchLabel }}
                />
              </Grid>

              <Hidden hidden={displayAs !== 'chart'}>
                <Grid item xs={12}>
                  <FormLabel component="legend" className={classes.legend}>
                    Chart
                  </FormLabel>
                </Grid>

                <Grid item xs={5}>
                  <FormControlLabel
                    control={<Switch checked={isSpikeAnalysis}
                      onChange={this.toggle}
                      name="isSpikeAnalysis"
                    />}
                    label="Spike Analysis"
                    classes={{ root: classes.switchRoot, label: classes.switchLabel }}
                  />
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    name="spikeAnalysisValue"
                    onChange={this.handleChangeInput}
                    value={spikeAnalysisValue}
                    fullWidth
                    inputProps={{
                      step: 0.1,
                      min: 0.1,
                      type: 'number',
                    }}
                    disabled={!isSpikeAnalysis}
                  />
                </Grid>

                <Grid item xs={5}>
                  <FormLabel className={classes.formLabel}>
                    Charts Per Page
                  </FormLabel>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    name="chartsPerPage"
                    onChange={this.handleChangeInput}
                    value={chartsPerPage}
                    fullWidth
                    inputProps={{
                      step: 1,
                      min: 1,
                      // max: 120,
                      type: 'number',
                    }}
                  />
                </Grid>

                <Grid item xs={5}>
                  <FormControlLabel
                    control={<Switch checked={isSmoothData}
                      onChange={this.toggle}
                      name="isSmoothData"
                    />}
                    label="Smooth Data"
                    classes={{ root: classes.switchRoot, label: classes.switchLabel }}
                  />
                </Grid>

                <Grid item xs={7}>
                  <Grid
                    container
                    spacing={1}
                    direction="row"
                    justify="center"
                    alignItems="center">
                    <Grid item xs={4}>
                      <TextField
                        name="smoothDataValue"
                        onChange={this.handleChangeInput}
                        value={smoothDataValue}
                        inputProps={{
                          step: 1,
                          min: 1,
                          // max: 120,
                          type: 'number',
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <Select value={smoothDataMeasure} name="smoothDataMeasure" onChange={this.handleChangeInput} style={{ paddingRight: '4px' }} renderValue={(selected) => { return selected; }}>
                        <MenuItem value="Minute"><Clock12Regular style={{ marginRight: '8px', color: '#B2BEB5', height: '20px', width: '20px' }} />Minutes</MenuItem>
                        <MenuItem value="Hour"><Smartwatch24Regular style={{ marginRight: '8px', height: '20px', width: '20px' }} />Hours</MenuItem>
                        <MenuItem value="Day"><WeatherSunny16Regular style={{ marginRight: '8px', height: '20px', width: '20px' }} />Days</MenuItem>
                        <MenuItem value="Week"><Calendar12Regular style={{ marginRight: '8px', color: '#fd5c63', height: '20px', width: '20px' }} />Weeks</MenuItem>
                        <MenuItem value="Month"><CalendarDate24Regular style={{ marginRight: '8px', height: '20px', width: '20px' }} />Months</MenuItem>
                        <MenuItem value="Year"><Earth16Regular style={{ marginRight: '8px', color: '#0066b2', height: '20px', width: '20px' }} />Years</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>


                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Switch checked={isNormalizeScale}
                      onChange={this.toggle}
                      name="isNormalizeScale"
                    />}
                    label="Normalize Scale"
                    classes={{ root: classes.switchRoot, label: classes.switchLabel }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Switch checked={isRegressionTrend}
                      onChange={this.toggle}
                      name="isRegressionTrend"
                    />}
                    label="Regression Trend"
                    classes={{ root: classes.switchRoot, label: classes.switchLabel }}
                  />
                </Grid>

                <Grid item xs={12}>
                  <FormControlLabel
                    control={<Switch checked={isLogarithmic}
                      onChange={this.toggle}
                      name="isLogarithmic"
                    />}
                    label="Logarithmic"
                    classes={{ root: classes.switchRoot, label: classes.switchLabel }}
                  />
                </Grid>
              </Hidden>

              <Hidden hidden={displayAs !== 'report'}>
                <Grid item xs={5}>
                  <FormLabel className={classes.formLabel}>
                    Measurements Per Group
                  </FormLabel>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    name="reportMeasurementsPerGroup"
                    onChange={this.handleChangeInput}
                    value={reportMeasurementsPerGroup}
                    fullWidth
                    inputProps={{
                      step: 1,
                      min: 1,
                      max: 120,
                      type: 'number',
                    }}
                  />
                </Grid>
              </Hidden>

              {dashlet.config.parentDashletId ? (
                <>
                  <Grid item xs={5}>
                    <FormLabel className={classes.formLabel}>
                      Refresh Rate
                    </FormLabel>
                  </Grid>

                  <Grid item xs={7}>
                    <Grid
                      container
                      spacing={1}
                      direction="row"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item xs={4}>
                        <TextField
                          name="refreshRateValue"
                          onChange={this.handleChangeInput}
                          value={refreshRateValue}
                          inputProps={{
                            step: 1,
                            min: 10,
                            // max: 120,
                            type: 'number',
                          }}
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <Select
                          value={refreshRateMeasure}
                          name="refreshRateMeasure"
                          onChange={this.handleChangeInput}
                          style={{ paddingRight: '4px' }}
                          renderValue={(selected) => { return selected; }}
                        >
                          <MenuItem value="Second"><Hourglass16Regular style={{ marginRight: '8px', color: '#0066b2', height: '20px', width: '20px' }} />Second</MenuItem>
                          <MenuItem value="Minute"><Clock12Regular style={{ marginRight: '8px', color: '#B2BEB5', height: '20px', width: '20px' }} />Minutes</MenuItem>
                          <MenuItem value="Hour"><Smartwatch24Regular style={{ marginRight: '8px', height: '20px', width: '20px' }} />Hours</MenuItem>
                          <MenuItem value="Day"><WeatherSunny16Regular style={{ marginRight: '8px', height: '20px', width: '20px' }} />Days</MenuItem>
                          <MenuItem value="Week"><Calendar12Regular style={{ marginRight: '8px', color: '#fd5c63', height: '20px', width: '20px' }} />Weeks</MenuItem>
                          <MenuItem value="Month"><CalendarDate24Regular style={{ marginRight: '8px', height: '20px', width: '20px' }} />Months</MenuItem>
                          <MenuItem value="Year"><Earth16Regular style={{ marginRight: '8px', color: '#0066b2', height: '20px', width: '20px' }} />Years</MenuItem>
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                </>
              ) : null}
            </Grid>
          </TimeRangeTabPanel>
        </Scrollbars>


        <Divider />

        <div className={classes.footer}>
          <div className={classes.grow} />
          <Button onClick={handleConfirm} variant="contained">Apply</Button>
          <Button onClick={handleClose} variant="outlined">Cancel</Button>
        </div>
      </div>
    );
  }
}

HLCChartDashletSettings.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  settings: PropTypes.object,
  errors: PropTypes.object,
  isLocked: PropTypes.bool,
  open: PropTypes.bool,

  //func
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,
  handleChangeProperty: PropTypes.func,
};

export default withStyles(styles)(HLCChartDashletSettings);
