import React from "react";
import BooleanTableCell from "./BooleanTableCell";
import DecimalCurrencyTableCell from "./DecimalCurrencyTableCell";
import DecimalNumberTableCell from "./DecimalNumberTableCell";
import NumberTableCell from "./NumberTableCell";
import StringTableCell from "./StringTableCell";
import NumberDeltaPctTableCell from "./NumberDeltaPctTableCell";
import DecimalDeltaPctTableCell from "./DecimalNumberDeltaPctTableCell";
import DecimalCurrencyDeltaPctTableCell from "./DecimalCurrencyDeltaPctTableCell";

interface GenericTableCellProps {
    type: string;
    value: any;
}

const CELL_COMPONENTS = {
    "boolean": BooleanTableCell,
    "currency": DecimalCurrencyTableCell,
    "decimal": DecimalNumberTableCell,
    "number": NumberTableCell,
    "number+delta_pct": NumberDeltaPctTableCell,
    "decimal+delta_pct": DecimalDeltaPctTableCell,
    "currency+delta_pct": DecimalCurrencyDeltaPctTableCell,
    "string": StringTableCell,
}

const GenericTableCell: React.FC<GenericTableCellProps> = ({ type, value }) => {
    // @ts-ignore
    let TableCellComponent = CELL_COMPONENTS[type];
    if (!TableCellComponent) {
        TableCellComponent = StringTableCell;
    }

    return <TableCellComponent value={value} />;
};

export default GenericTableCell;
