import * as React from 'react';
import uuid from 'uuid'
import * as _ from 'lodash'
import CloseIcon from "@material-ui/icons/Close";
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import AppBar from "@material-ui/core/AppBar";
import {Toolbar, Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import Dialog from "@material-ui/core/Dialog";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {loadTargetSpikePreviewChart} from "../actions";
import ReactHighcharts from "react-highcharts/ReactHighstock";
import {
    HEATMAP_CREATE_TEMPLATE_SUBSCRIPTION
} from "../webSocketClient";
import {selectHLCStompClient, selectIsHLCStompClientConnected} from "reducers/webSocketSlice";
import withStyles from "@material-ui/core/styles/withStyles";
import {buildSpikePreviewChartConfig, openSpikeInAnalyzer} from "../oldUtils";
import {
    closeSpikePreview,
    selectIsSpikePreviewLoading,
    selectSpikePreviewData, selectSpikePreviewTargetName, selectSpikePreviewTimestamp, setSpikePreviewData
} from "reducers/dashboard/dashlets/heatmap/heatmapSpikePreviewSlice";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
// import { handleExportDashlet } from 'actions/dashboard/dashletActions';
// import { EXPORT_DASHLET_SUBSRIPTION } from 'dashlets/HLCChartDashlet/webSocketClient';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import LaunchIcon from '@material-ui/icons/Launch';
import { setTimeRanges } from 'reducers/dashboard/dashboardSlice';
import { ArrowForward20Filled, DatabaseSearch24Filled, Dismiss20Filled, ZoomOut24Regular } from '@fluentui/react-icons';
import { generateReport } from 'dashlets/HLCChartDashlet/actions';
const styles = theme => ({ 
    grow: {
        flexGrow: 1,
    },
    appBar: {
        width: '100%',
        maxWidth: 'none',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 1px 7px rgba(0, 0, 0, 0.25)',
        height: '48px',
        minHeight: '48px',
    },
    button: {
        top: '-8px',
        color:"#242424"
    }
});

const HeatmapDashletSpikePreview = ({classes, dashlet}) => {
    const isLoading = useSelector(selectIsSpikePreviewLoading)
    const data = useSelector(selectSpikePreviewData);
    const targetName = useSelector(selectSpikePreviewTargetName);
    const timestamp = useSelector(selectSpikePreviewTimestamp);
    const isStompClientConnected = useSelector(selectIsHLCStompClientConnected);
    const stompClient = useSelector(selectHLCStompClient);
    const dispatch = useDispatch();

    const handleLoadTargetSpikePreviewChart = (response) => {
        dispatch(setSpikePreviewData(response));
    };

    const handleClose = () => {
        dispatch(closeSpikePreview());
    }
    const setTimeRange = (data) => {
        dispatch(setTimeRanges(data))
    }
    const handleResetZoom = () => {
        _.each(ReactHighcharts.Highcharts.charts, (chart) => {
            if (chart) {
                chart.xAxis[0].setExtremes(null, null);
                chart.yAxis[0].setExtremes(null, null);
                dispatch(setTimeRanges({startTime: null, endTime: null}))
            }
        });
    }

    const openSpikeChart = (templateName, dashletId) => {
        dispatch(openSpikeInAnalyzer(templateName, dashletId));
    }

    const handleGenerateReport = () => {
        dispatch(generateReport(dashlet));
    }

    useEffect(() => {
        dispatch(loadTargetSpikePreviewChart(dashlet, targetName, timestamp));
    }, []);

    const chartsConfig = buildSpikePreviewChartConfig(data, setTimeRange);

    console.log(chartsConfig)

    return (
        <div>
            <Dialog
                fullScreen
                open={true}
                onClose={handleClose}
            >
                <AppBar sx={{ position: 'relative' }} className={classes.appBar}>
                    <Toolbar>

                        <div className={classes.grow} />
                        <Tooltip title="Generate Report">
                            <IconButton
                                edge="start"
                                // color="inherit"
                                onClick={handleGenerateReport}
                                aria-label="close"
                                className={classes.button}
                            >
                                <DatabaseSearch24Filled />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Reset Zoom">
                            <IconButton
                                edge="start"
                                // color="inherit"
                                onClick={handleResetZoom}
                                aria-label="close"
                                className={classes.button}
                            >
                                <ZoomOut24Regular />
                            </IconButton>
                        </Tooltip>
                        <IconButton
                            edge="start"
                            // color="inherit"
                            onClick={() => openSpikeChart(data.spikeTemplate.templateName, data.dashletId)}
                            aria-label="close"
                            className={classes.button}
                        >
                            <ArrowForward20Filled />
                        </IconButton>
                        <IconButton
                            edge="start"
                            // color="inherit"
                            onClick={handleClose}
                            aria-label="close"
                            className={classes.button}
                        >
                            <Dismiss20Filled />
                        </IconButton>
                    </Toolbar>
                </AppBar>

                {/**/}

                <div id="target_spike_chart_container" style={{height: '100%', width: '100%', marginTop: '70px'}}>
                    {!isLoading && chartsConfig && _.map(chartsConfig, (config, index) => {
                        return <ReactHighcharts key={`target_spike_chart-${index}`} config={config}></ReactHighcharts>;
                    })}
                </div>
            </Dialog>
            {isStompClientConnected && [
                <HLCUserSessionWebSocketSubscription
                    key={`hlc-ws-subscr-heatmap-dashlet-template-create-${dashlet.id}`}
                    dashletId={dashlet.id}
                    // onSubscribeCallback={() => dispatch(loadTargetSpikePreviewChart(dashlet, targetName, timestamp))}
                    // subscriptionDestination={`${HEATMAP_CREATE_TEMPLATE_SUBSCRIPTION}-${dashlet.config.backendKey}-${dashlet.id}`}
                    subscriptionDestination={HEATMAP_CREATE_TEMPLATE_SUBSCRIPTION}
                    handleMessage={handleLoadTargetSpikePreviewChart}
                />
                ]}
        </div>
    );
}

export default withStyles(styles)(HeatmapDashletSpikePreview);