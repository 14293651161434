import Dialog from '@material-ui/core/Dialog';
import React, {FC, useState} from 'react';
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from 'components/common/DialogTitle/DialogTitle';
import InputLabel from "components/common/InputLabel/InputLabel";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {Theme, WithStyles} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import * as _ from "lodash";
import DashletTargetConfigurationOption from "../../types/DashletTargetConfigurationOption";
import DashletTargetConfigurationTreeView from "./DashletTargetConfigurationTreeView";
import TextField from "@material-ui/core/TextField";


const styles = (theme: Theme) => ({
    actionButtonLabel: {
        fontWeight: 600,
    },
    dialogPaper: {
        minHeight: '70vh',
        maxHeight: '70vh'
    },
    dialogActions: { 
        margin: '0px 10px 10px 10px'
    }
});

const filterTreeData = (data: DashletTargetConfigurationOption[], filterText: string): DashletTargetConfigurationOption[] => {
    if (filterText.length < 2) {
        return data; // Return the original data if the filter text is less than 3 characters
    }

    return data.filter(item => item.targetName.toLowerCase().includes(filterText.toLowerCase()));
};

interface DashletConfigSelectTargetNamesModalProps extends WithStyles<typeof styles> {
    open: boolean;
    multi?: boolean;
    targetOptions: DashletTargetConfigurationOption[];
    targetNames: string[];
    onSubmit: (targetNames: string[]) => void;
    onClose: () => void;
}

const DashletConfigSelectTargetNamesModal: FC<DashletConfigSelectTargetNamesModalProps> = ({
                                                                           open,
                                                                           multi,
                                                                           targetOptions,
                                                                           targetNames,
                                                                           onClose,
                                                                           onSubmit,
                                                                           classes
                                                                       }) => {
    const [selectedTargetNames, setSelectedTargetNames] = useState<string[]>(targetNames || []);
    const [filterText, setFilterText] = React.useState('');
    const filteredData = filterTreeData(targetOptions, filterText);

    const onToggleTargetName = (targetName: string) => {
        const prevTargetNames = selectedTargetNames || [];
        let nextTargetNames: string[];
        if (multi) {
            nextTargetNames = prevTargetNames.indexOf(targetName) !== -1
                ? _.without(prevTargetNames, targetName)
                : _.concat(prevTargetNames, targetName);
        } else {
            nextTargetNames = prevTargetNames.indexOf(targetName) !== -1 ? [] : [targetName];
        }
        setSelectedTargetNames(nextTargetNames);
    }

    const onSelectAllTargetNames = () => {
        setSelectedTargetNames(targetOptions?.map(opt => opt.targetName) || []);
    }

    const onUnselectAllTargetNames = () => {
        setSelectedTargetNames([]);
    }

    return <React.Fragment>
        <Dialog
            fullWidth
            maxWidth="xs"
            open={open}
            onClose={onClose}
            classes={{ paper: classes.dialogPaper }}
        >
            <DialogTitle id="alert-dialog-title" onClose={onClose}>
                Select Targets
            </DialogTitle>
            <DialogContent>
              <InputLabel id="hlc-target">Filter by Target Name</InputLabel>
                <TextField
                    // label="Filter by Target Name"
                    variant="outlined"
                    fullWidth
                    margin="dense"
                    onChange={(e) => setFilterText(e.target.value)}
                />
                <DashletTargetConfigurationTreeView
                    data={filteredData}
                    selectedTargetNames={selectedTargetNames}
                    filterText={filterText}
                    toggleTargetName={onToggleTargetName}
                />
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                {multi && <>
                    <Button style={{fontSize:"13px"}}  variant="contained"
                            onClick={onUnselectAllTargetNames}
                            classes={{
                                label: classes.actionButtonLabel
                            }}
                    >
                        Unselect All
                    </Button>
                    <Button variant="contained"
                            onClick={onSelectAllTargetNames}
                            classes={{
                                label: classes.actionButtonLabel
                            }}
                    >
                        Select All
                    </Button>
                </>}
                <Button variant="contained"
                        onClick={() => onSubmit(selectedTargetNames)}
                        classes={{
                            label: classes.actionButtonLabel
                        }}
                        data-testid="submit-button"
                >
                    Apply
                </Button>
                <Button onClick={onClose} variant="outlined" classes={{
                    label: classes.actionButtonLabel
                }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>;
}

export default withStyles(styles)(DashletConfigSelectTargetNamesModal);