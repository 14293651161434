import * as jobsWebSocketClient from "api/websocket/hlc/jobsWebSocketClient"
import {setJobs, setJobsLoading, setJobStatus} from "reducers/infrastructure/jobsSlice";
import ListJobsStompResponse from "types/payload/infrastructure/jobs/ListJobsStompResponse";
import {toastr} from "react-redux-toastr";
import JobOperationStompResponse from "types/payload/infrastructure/jobs/JobOperationStompResponse";
import { showErrorNotification } from "components/toastr/notificationActions";

export const listJobs = (backendKey: string) => (dispatch: any, getState: any) => {
    dispatch(setJobsLoading(true));
    jobsWebSocketClient.listJobs(getState(), backendKey);
};

export const handleListJobs = (response: ListJobsStompResponse) => (dispatch: any) => {
    const {hasErrors, errorMessage, jobs} = response;
    if (!hasErrors) {
        dispatch(setJobs(jobs));
    } else {
        showErrorNotification("List Jobs", `Unable to load the list of Jobs: ${errorMessage}`);
    }
    dispatch(setJobsLoading(false));
};

export const pauseJob =
    (backendKey: string, jobId: string, jobGroupName: string, jobName: string) => (dispatch: any, getState: any) =>
        jobsWebSocketClient.pauseJob(getState(), backendKey, { jobId, jobGroupName, jobName });

export const resumeJob =
    (backendKey: string, jobId: string, jobGroupName: string, jobName: string) => (dispatch: any, getState: any) =>
        jobsWebSocketClient.resumeJob(getState(), backendKey, { jobId, jobGroupName, jobName });

export const handleJobOperation = (response: JobOperationStompResponse) => (dispatch: any) => {
    const {hasErrors, errorMessage, jobId, jobStatus} = response;
    if (!hasErrors) {
        dispatch(setJobStatus(jobId, jobStatus));
    } else {
        showErrorNotification("Job Operation", `Unable to pause/resume: ${errorMessage}`);
    }
};
