import React, { useState } from "react";
import EmailIntegration from "types/model/settings/integration/EmailIntegration";
import useIntegrationSectionState from "hooks/integration/useIntegrationSectionState";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "components/common/Switch/Switch";
import TextField from "components/common/TextField/TextField";
import Select from "components/common/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { InputAdornment, IconButton, OutlinedInput } from "@material-ui/core";
import { Visibility, VisibilityOff } from "@material-ui/icons";
import Button from "@material-ui/core/Button";

interface EmailIntegrationSectionProps {
    integration: EmailIntegration;
    onSave: (integration: EmailIntegration) => void;
    onTest: (integration: EmailIntegration) => void;
}
interface ErrorMessages {
    emailType: string | null;
    host: string | null;
    port: string | null;
    username: string | null;
    password: string | null;
}
const EmailIntegrationSection: React.FC<EmailIntegrationSectionProps> = ({
    onSave,
    onTest,
    ...props
                                                                         }) => {
    const { integration, handleChangeProperty, handleEnabledToggle }
        = useIntegrationSectionState(props.integration);
    const {
        enabled,
        emailType,
        host,
        port,
        username,
        password
    } = integration as EmailIntegration;
    const [errors, setErrors] = useState<ErrorMessages>({
        emailType: null,
        host: null,  
        port: null,
        username: null,
        password: null,
    });

const [showPassword, setShowPassword] = useState(false);
const handleTogglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSave = () => {
        let hasError = false;
        const newErrors: ErrorMessages = { emailType: null, host: null, port: null, username: null, password: null };

        if (enabled) {
            
            if (!emailType) {
                newErrors.emailType = "Email Type cannot be empty.";
                hasError = true;
            }

            if (!host) {
                newErrors.host = "Host cannot be empty.";
                hasError = true;
            }

            if (!port) {
                newErrors.port = "Port cannot be empty.";
                hasError = true;
            }

            if (!username) {
                newErrors.username = "Username (Email) cannot be empty.";
                hasError = true;
            }

            if (!password) {
                newErrors.password = "Password cannot be empty.";
                hasError = true;
            }

            setErrors(newErrors);
        }

        if (!hasError) {
            setErrors(newErrors);
            onSave(integration as EmailIntegration);
        }
    };
    return (
        <>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="flex-start"
                style={{marginTop: "8px", marginBottom: "8px"}}
            >
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="enable">Enabled</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <Switch checked={enabled || false}
                                onChange={handleEnabledToggle}
                                name="enabled"
                        />
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="enable">Security</InputLabel>
                    </Grid>
                    <Grid item xs={5} style={{paddingRight: "12px"}}>
                        <Select value={emailType} name="emailType" disabled={!enabled}
                                onChange={(event: any) =>
                                    handleChangeProperty(event?.target?.name, event?.target?.value)}
                        >
                            <MenuItem
                                data-testid="email-type-dropdown-option-secure-TLS"
                                key="email-type-dropdown-option-TLS"
                                value="Secure (TLS)"
                            >
                                Secure (TLS)
                            </MenuItem>
                            <MenuItem
                                data-testid="email-type-dropdown-option-secure-SSL"
                                key="email-type-dropdown-option-SSL"
                                value="Secure (SSL)"
                            >
                                Secure (SSL)
                            </MenuItem>
                        </Select>
                        {enabled && errors.emailType && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.emailType}</div>}
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="host">Host</InputLabel>
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            disabled={!enabled}
                            name="host"
                            fullWidth
                            textAlign="left"
                            value={host}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                        {enabled && errors.host && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.host}</div>}
                    </Grid>
                    <Grid item xs={1} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="port">Port</InputLabel>
                    </Grid>
                    <Grid item xs={1}>
                        <TextField
                            disabled={!enabled}
                            name="port"
                            fullWidth
                            textAlign="left"
                            value={port}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                        {enabled && errors.port && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left'}}>{errors.port}</div>}
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="username">Username (Email)</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="username"
                            fullWidth
                            textAlign="left"
                            value={username}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                        {enabled && errors.username && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.username}</div>}
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="password">Password</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <OutlinedInput
                        style={{width: '99%', height: '42px'}}
                            disabled={!enabled}
                            name="password"
                            type={showPassword ? "text" : "password"}
                            fullWidth
                            value={password}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                            endAdornment={
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleTogglePasswordVisibility}
                                    edge="end"
                                  >
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              }
                              inputProps={{
                                style: { height: '0px'}
                            }}
                        />
                        {enabled && errors.password && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.password}</div>}
                    </Grid>
                </Grid>

                <Grid
                    item
                    container
                    spacing={2}
                    xs={7}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Grid item xs={1} container spacing={1} justifyContent="flex-end"  style={{ flexWrap: 'nowrap' }} >
                        <Grid item>
                            <Button disabled={!enabled} onClick={() => onTest(integration as EmailIntegration)} variant="contained">Test</Button>
                        </Grid>
                        <Grid item>
                            <Button onClick={handleSave} variant="contained">Save</Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
}

export default EmailIntegrationSection;
