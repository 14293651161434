import DemoTargetsGenerationParams from "types/model/infrastructure/simulation/DemoTargetsGenerationParams";
import createDemoTargetParams from "./DemoTargetParamsFactory";

const createDemoTargetGenerationParams = (defaults: Partial<DemoTargetsGenerationParams> = {}): DemoTargetsGenerationParams => {
    return {
        demoTargetsParams: defaults.demoTargetsParams || [],
        targetNamePrefix: defaults.targetNamePrefix || "DEMO_",
        timeAmount: defaults.timeAmount || 1,
        timeAmountUnit: defaults.timeAmountUnit || "Day",
        frequencySeconds: defaults.frequencySeconds || 60
    }
};

export default createDemoTargetGenerationParams;
