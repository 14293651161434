import React from "react";
import {Box, Grid} from "@material-ui/core";
import DemoTargetsGenerationParams from "types/model/infrastructure/simulation/DemoTargetsGenerationParams";
import createDemoTargetGenerationParams
    from "factories/model/simulation/DemoTargetsGenerationParamsFactory";
import createDemoTargetParams from "factories/model/simulation/DemoTargetParamsFactory";
import BackendSelectionRow from "components/common/BackendSelectionRow";
import InputLabel from "@material-ui/core/InputLabel";
import TextField from "components/common/TextField/TextField";
import Select from "components/common/Select/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {useDispatch} from "react-redux";
import {
    deleteDemoTargets,
    generateDemoTargets,
    handleSimulationOperationResponse
} from "actions/infrastructure/simulationActions";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import {SIMULATION_OPERATION_SUBSCRIPTION} from "api/websocket/hlc/simulationWebSocketClient";
import {HLC_WEB_SOCKET_URL, IS_HLC_WS_ENABLED, IS_PEI_WS_ENABLED, PEI_WEB_SOCKET_URL} from "config";
import StompClient from "websocket/StompClient";
import {STOMP_CLIENT} from "reducers/webSocketSlice";
import Button from "@material-ui/core/Button";

const initialDemoTargetsGenerationParams = createDemoTargetGenerationParams(
    {
        demoTargetsParams: [
            createDemoTargetParams({databaseType: "MySQL"}),
            createDemoTargetParams({databaseType: "Oracle"}),
            createDemoTargetParams({databaseType: "Microsoft SQL Server"}),
            createDemoTargetParams({databaseType: "PostgreSQL"}),
        ]
    }
);

interface TargetsCountRowProps {
    databaseType: string;
    index: number;
    targetsCount: number;
    handleChange: (index: number, targetsCount: number) => void;
}

const TargetsCountRow: React.FC<TargetsCountRowProps> = ({
                                                             databaseType,
                                                             index,
                                                             targetsCount,
                                                             handleChange
                                                         }) => {
    const name = `targetsCount_${index}`;
    const label = `${databaseType} Targets`;

    return (
        <Grid
            item
            container
            spacing={2}
            xs={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center">
            <Grid item xs={3} md={2} style={{textAlign: "left"}}>
                <InputLabel htmlFor={name}>{label}</InputLabel>
            </Grid>
            <Grid item xs={2} md={1}>
                <TextField
                    name={name}
                    fullWidth
                    textAlign="right"
                    value={targetsCount}
                    inputProps={{
                        step: 1,
                        min: 1,
                        max: 10000,
                        type: 'number',
                    }}
                    onChange={(event: any) => handleChange(index, event.target.value)}
                />
            </Grid>
        </Grid>
    );
}

const SimulationContent: React.FC = () => {
    const dispatch = useDispatch();
    const [backendKey, setBackendKey]
        = React.useState<string | null>(null);

    const [demoTargetsGenerationParams, setDemoTargetsGenerationParams]
        = React.useState<DemoTargetsGenerationParams>(createDemoTargetGenerationParams(initialDemoTargetsGenerationParams));

    const handleChangeTargetsCount = (index: number, targetsCount: number) => {
        setDemoTargetsGenerationParams(prevState => ({
            ...prevState,
            demoTargetsParams: prevState.demoTargetsParams.map((param, i) =>
                i === index ? {...param, targetsCount: targetsCount || 0} : param
            ),
        }));
    };

    const handleChangeProperty = (name: string, value: string | number) => {
        setDemoTargetsGenerationParams(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    const handleGenerate = () =>
        backendKey ? dispatch(generateDemoTargets(backendKey, demoTargetsGenerationParams)) : {};

    const handleDeleteAll = () =>
        backendKey ? dispatch(deleteDemoTargets(backendKey)) : {};

    const {
        demoTargetsParams,
        targetNamePrefix,
        timeAmount,
        timeAmountUnit,
        frequencySeconds
    } = demoTargetsGenerationParams;

    return (
        <Box style={{
            display: 'block',
            height: "100%",
            width: "100%",
            overflowY: "scroll",
            overflowX: "hidden"
        }}>
            <BackendSelectionRow backendKey={backendKey} onChangeBackend={setBackendKey}/>

            {backendKey && (
                <Grid
                    container
                    spacing={2}
                    direction="column"
                    justifyContent="flex-start"
                    style={{marginTop: "8px", marginBottom: "8px", marginLeft: "24px"}}
                >
                    {demoTargetsParams.map((params, index) => (
                        <TargetsCountRow
                            key={params.databaseType}
                            index={index}
                            databaseType={params.databaseType}
                            targetsCount={params.targetsCount}
                            handleChange={handleChangeTargetsCount}
                        />
                    ))}

                    <Grid
                        item
                        container
                        spacing={2}
                        xs={12}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center">
                        <Grid item xs={3} md={2} style={{textAlign: "left"}}>
                            <InputLabel htmlFor="frequencySeconds">Data Frequency (seconds)</InputLabel>
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <TextField
                                name="frequencySeconds"
                                fullWidth
                                textAlign="right"
                                value={frequencySeconds}
                                inputProps={{
                                    step: 1,
                                    min: 1,
                                    type: 'number',
                                }}
                                onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        container
                        spacing={2}
                        xs={12}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center">
                        <Grid item xs={3} md={2} style={{textAlign: "left"}}>
                            <InputLabel htmlFor="targetNamePrefix">Target Prefix</InputLabel>
                        </Grid>
                        <Grid item xs={5} md={3}>
                            <TextField
                                name="targetNamePrefix"
                                fullWidth
                                textAlign="left"
                                value={targetNamePrefix}
                                onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                            />
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        container
                        spacing={2}
                        xs={12}
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center">
                        <Grid item xs={3} md={2} style={{textAlign: "left"}}>
                            <InputLabel htmlFor="timeAmount">Time Amount</InputLabel>
                        </Grid>
                        <Grid item xs={2} md={1}>
                            <TextField
                                name="timeAmount"
                                fullWidth
                                textAlign="right"
                                value={timeAmount}
                                inputProps={{
                                    step: 1,
                                    min: 1,
                                    type: 'number',
                                }}
                                onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                            />
                        </Grid>
                        <Grid item xs={3} md={2}>
                            <Select
                                value={timeAmountUnit}
                                name="timeAmountUnit"
                                onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                            >
                                <MenuItem value="Minute">Minutes</MenuItem>
                                <MenuItem value="Hour">Hours</MenuItem>
                                <MenuItem value="Day">Days</MenuItem>
                                <MenuItem value="Week">Weeks</MenuItem>
                                <MenuItem value="Month">Months</MenuItem>
                                <MenuItem value="Year">Years</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>

                    <Grid
                        item
                        container
                        spacing={2}
                        xs={8}
                        md={7}
                        direction="row"
                        justifyContent="flex-end"
                        alignItems="center">
                        <Grid item xs={4} md={2}>
                            <Button onClick={handleDeleteAll} variant="contained">Delete All</Button>
                        </Grid>
                        <Grid item xs={4} md={2}>
                            <Button onClick={handleGenerate} variant="contained">Generate</Button>
                        </Grid>
                    </Grid>
                </Grid>
            )}

            {/* todo: move to common place */}
            {IS_PEI_WS_ENABLED ? [
                <StompClient
                    key="hlc-ws-stomp-client-1582840873831"
                    stompClientKey={STOMP_CLIENT.PEI}
                    sockJsEndpoint={PEI_WEB_SOCKET_URL}
                    debugMode
                />,
            ] : null}
            {IS_HLC_WS_ENABLED ? [
                <StompClient
                    key="pei-ws-stomp-client-1582840873831"
                    stompClientKey={STOMP_CLIENT.HLC}
                    sockJsEndpoint={HLC_WEB_SOCKET_URL}
                    debugMode
                />,
            ] : null}

            <HLCUserSessionWebSocketSubscription
                subscriptionDestination={SIMULATION_OPERATION_SUBSCRIPTION}
                handleMessage={(response) => dispatch(handleSimulationOperationResponse(response))}
            />
        </Box>
    );
};

export default SimulationContent;
