import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import {Tooltip} from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';

const useStyles = makeStyles({
    tableCell: {
        textAlign: "left",
        // fontSize: "0.6vw",
        fontSize: "12px",
    },
});

interface ToggleActionTableCellProps {
    index: number;
    active: boolean;
    color: string;
    toggle: (index: number) => void;
}

const ToggleActionTableCell: React.FC<ToggleActionTableCellProps> = ({
                                                                         index,
                                                                         active,
                                                                         color,
                                                                         toggle
                                                                     }) => {
    const classes = useStyles();

    const tooltip = active ? "Hide" : "Show";
    const icon = active ? (
        <RadioButtonCheckedIcon
            style={{color: color}}
            fontSize="small"
        />
    ) : (
        <RadioButtonUncheckedIcon
            style={{color: color}}
            fontSize="small"
        />
    );

    return (
        <TableCell className={classes.tableCell}>
            <Tooltip
                key={`dashlet-${index}-toggle-target-button`}
                title={tooltip}
            >
                <IconButton
                    aria-label="Show/Hide"
                    size="small"
                    onClick={() => toggle(index)}
                >
                    {icon}
                </IconButton>
            </Tooltip>
        </TableCell>
    );
};

export default ToggleActionTableCell;
