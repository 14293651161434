import React from "react";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import IconButton from "@material-ui/core/IconButton";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import { ChevronDown12Regular, ChevronUp12Regular } from "@fluentui/react-icons";

interface IntegrationSectionProps {
    title: string;
    content: React.ReactNode;
}

const IntegrationSection: React.FC<IntegrationSectionProps> = ({title, content}) => {
    const [expanded, setExpanded] = React.useState(true);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return (
        <>
            <ListItem
                button
                onClick={handleExpandClick}
                style={{
                    // backgroundColor: "#fafafa",
                    // borderBottom: "1px solid #eaeded"
                    boxShadow: '0px 1px 4px 1px #00000024',
                    height: '40px',
                }}
            >
                <ListItemIcon>
                    <IconButton size="small" edge="start" aria-label="expand">
                        {expanded ? <ChevronUp12Regular style={{ height: '16px', width: '16px'}} /> : <ChevronDown12Regular style={{ height: '16px', width: '16px'}} />}
                    </IconButton>
                </ListItemIcon>
                <ListItemText primary={title}/>
            </ListItem>
            <Collapse in={expanded} timeout="auto" style={{width: "100%", paddingLeft: "32px"}} collapsedSize={"10px"}>
                {content}
            </Collapse>
        </>
    );
};

export default IntegrationSection;
