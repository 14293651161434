import {publish} from '../../api/websocket/hlc/hlcCommonWebSocketClient';
import {WEB_SOCKET_ACTION_PREFIX, WEB_SOCKET_SUBSCR_PREFIX} from '../../constants/webSocketConstants';

export const LOAD_HLC_CHART_TEMPLATES_ACTION =         `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.templates.list.load`;
export const LOAD_HLC_CHART_TEMPLATES_SUBSCRIPTION =   `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.templates.list`;
export const LOAD_HLC_STATIC_CHART_ACTION =            `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.dashlet.chart.static.create`;
export const LOAD_HLC_STATIC_CHART_SUBSCRIPTION =      `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.dashlet.chart.static`;
export const LOAD_HLC_CHART_ACTION =                   `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.dashlet.chart.create`;
export const LOAD_HLC_CHART_SUBSCRIPTION =             `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.dashlet.chart`;
export const LOAD_CHART_ON_HLC_ACTION =                `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.load.chartTemplate`;

//export dashlet
export const EXPORT_DASHLET_ACTION =                   `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.export.dashlet`;
export const EXPORT_DASHLET_SUBSRIPTION =              `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.export.dashlet.response`

//import dashlet
export const IMPORT_DASHLET_ACTION =                   `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.import.dashlet`;
export const IMPORT_DASHLET_SUBSRIPTION =              `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.import.dashlet.response`

//Craete defult target
export const CREATE_TARGET_ACTION =                    `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.create.target`;     
export const CREATE_TARGET_SUBSCRIPTION =              `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.create.target.result`;

//Test backend key
export const TEST_BACKEND_ACTION =                      `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.test.backend`;
export const TEST_BACKEND_SUBSCRIPTION =                `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.test.backend.result`;

//Laod anomaly dashboard
export const LOAD_ANOMALY_DASHBOARD_DATA =                    `${WEB_SOCKET_ACTION_PREFIX}/ui.load.anomaly.dashboard.data`;     
export const LOAD_ANOMALY_DASHBOARD_DATA_SUBSCRIPTION =       `${WEB_SOCKET_SUBSCR_PREFIX}/ui.load.anomaly.dashboard.data.result`;

export const SET_USER_SECRET =                          `${WEB_SOCKET_ACTION_PREFIX}/ui.cloud.user.register.execute`;

//Generate Gen Report
export const GENERATE_GEN_REPORT =                      `${WEB_SOCKET_ACTION_PREFIX}/ui.generate.gen.report`;     
export const GENERATE_GEN_REPORT_SUBSCRIPTION =         `${WEB_SOCKET_SUBSCR_PREFIX}/ui.generate.gen.report.response`;

export const loadChartTemplates = (appState, backendKey) =>
    publish(appState, backendKey, LOAD_HLC_CHART_TEMPLATES_ACTION, {});

export const loadStaticChart = (appState, backendKey, loadChartStompFrame) =>
    publish(appState, backendKey, LOAD_HLC_STATIC_CHART_ACTION, loadChartStompFrame);

export const loadChart = (appState, backendKey, loadChartStompFrame) =>
    publish(appState, backendKey, LOAD_HLC_CHART_ACTION, loadChartStompFrame);

export const loadChartToHLC = (appState, backendKey, loadChartParams) =>
    publish(appState, backendKey, LOAD_CHART_ON_HLC_ACTION, loadChartParams);

export const createTarget = (appState, backendKey, CreateDefaultTargetDashletStompFrame) =>
    publish(appState, backendKey, CREATE_TARGET_ACTION,  CreateDefaultTargetDashletStompFrame);

export const checkBackendConnection = (appState, backendKey, ListHLCBackendStompFrame) =>
    publish(appState, backendKey, TEST_BACKEND_ACTION,  ListHLCBackendStompFrame);

export const exportDashlet = (appState, backendKey, exportDashletStompFrame) => 
    publish(appState, backendKey, EXPORT_DASHLET_ACTION, exportDashletStompFrame);

export const importDashlet = (appState, backendKey, importDashletStompFrame) => {
    publish(appState, backendKey, IMPORT_DASHLET_ACTION, importDashletStompFrame);
}

export const loadAnomalyDashboardData = (appState, backendKey, timeSettings) => {
    publish(appState, backendKey, LOAD_ANOMALY_DASHBOARD_DATA, timeSettings);
}

export const setSecretKeyAndUserInHLC = (appState, backendKey, userStompFrame, userSecret) =>
    publish(appState, backendKey, SET_USER_SECRET, userStompFrame, userSecret);


export const generateReport = (appState, backendKey, genReportStompFrame) =>
    publish(appState, backendKey, GENERATE_GEN_REPORT, genReportStompFrame);