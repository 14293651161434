import * as backendCollectorsWebSocketClient from "api/websocket/hlc/backendCollectorsWebSocketClient";
import {
    setBackendCollectorStatus,
    setBackendCollectors,
    setBackendCollectorsLoading,
    updateBackendCollectorStatuses
} from "reducers/infrastructure/backendCollectorsSlice";
import {toastr} from "react-redux-toastr";
import ListCollectorsStompResponse from "types/payload/infrastructure/collector/ListCollectorStompResponse";
import CollectorStompResponse from "../../types/payload/infrastructure/collector/CollectorStompResponse";
import CollectorStatusStompResponse from "../../types/payload/infrastructure/collector/CollectorStatusStompResponse";
import { showErrorNotification, showSuccessNotification } from "components/toastr/notificationActions";

export const listCollectors = (backendKey: string) => (dispatch: any, getState: any) => {
    dispatch(setBackendCollectorsLoading(true));
    backendCollectorsWebSocketClient.listCollectors(getState(), backendKey);
};

export const handleListCollectors = (response: ListCollectorsStompResponse) => (dispatch: any) => {
    const {hasErrors, errorMessage, collectors} = response;
    if (!hasErrors) {
        dispatch(setBackendCollectors(collectors));
    } else {
        showErrorNotification("List Collectors", `Unable to load the list of Collectors: ${errorMessage}`);
    }
    dispatch(setBackendCollectorsLoading(false));
};

export const startCollector = (backendKey: string, targetId: number) => (dispatch: any, getState: any) =>
    backendCollectorsWebSocketClient.startCollector(getState(), backendKey, { collectorId: targetId });

export const stopCollector = (backendKey: string, targetId: number) => (dispatch: any, getState: any) =>
    backendCollectorsWebSocketClient.stopCollector(getState(), backendKey, { collectorId: targetId });

export const handleCollectorOperation = (response: CollectorStompResponse) => (dispatch: any) => {
    const { hasErrors, errorMessage, message, status, collectorId} = response;
    if (!hasErrors) {
        showSuccessNotification("Collector Operation", message);
        dispatch(setBackendCollectorStatus(collectorId, status));
    } else {
        showErrorNotification("Collector Operation", errorMessage);
    }
}

export const handleCollectorStatus = (response: CollectorStatusStompResponse) => (dispatch: any) =>
    dispatch(updateBackendCollectorStatuses(response.statuses));