import {createTheme} from '@material-ui/core/styles';

export const theme = createTheme({
  palette: {
    primary: {
      // main: '#5797c7'
      main: '#0F6CBD'
    },
    text: {
      primary: '#242424',
      secondary: '#242424'
    },
  },
  typography: {
    // fontFamily: "Amazon Ember Regular, \"Roboto\", \"Helvetica\", \"Arial\", sans-serif",
        fontFamily: 'Segoe UI',
    h5: {
      // fontWeight: 300,
      fontWeight: 400,
    },
    body1: {
      // fontWeight: 300,
      fontWeight: 400,
    },
    body2: {
      // fontWeight: 300,
      fontWeight: 400,
    }
  },
  shape: {
    borderRadius: 0
  },
  overrides: {
    MuiFormLabel: {
        root: {
          color: '#242424 !important',
          '&.Mui-focused': {
            color: '#242424 !important',
          },
        },
     },
    MuiInputBase: {
      root: {
        fontSize: '14px',
        // borderBottom: '1px solid #616161 !important',
        outline: 'none !important',
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderBottom: '1px solid #616161 !important',
        },
        '&:hover': {
          // borderBottom: '1px solid #616161 !important',
        },
        '& .MuiInputBase-input': {
          padding: '10px',
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        outline: 'none !important',
        borderRadius: '4px !important',
        border: '1px solid #D1D1D1 !important',
        borderBottom: '1px solid #616161 !important',
        '&:hover .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #D1D1D1 !important',
          borderBottom: '1px solid #616161 !important',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: '1px solid #D1D1D1 !important',
          borderBottom: '1px solid #616161 !important',
        },
      },
      input: {
        // padding: '10px',
      },
    },
    MuiButton: {
      root: {
          padding: '6px 12px',
          borderRadius: '4px',
          textTransform: 'capitalize',
          fontSize: '14px',
          lineHeight: '20px',
          gap: '6px',
       // Custom styles for variant="contained" (for .saveButton)
        '&.MuiButton-contained': {
          height: '32px',
          width: '96px',
          minWidth: '96px',
          backgroundColor: '#0F6CBD',
          color: '#FFFFFF',
        },
        '&.MuiButton-contained .MuiButton-label': {
           fontWeight: '600',
        },
        '&.MuiButton-contained:hover': {
          backgroundColor: '#0F6CBD',
          color: '#FFFFFF',
        },
        '&.MuiButton-contained.Mui-disabled': {
          backgroundColor: '#F0F0F0',
          color: '#B0B0B0',
          border: '1px solid #E0E0E0',
        },
          // Custom styles for variant="outlined" (for .cancelButton)
          '&.MuiButton-outlined': {
            height: '32px',
            width: '96px',
            minWidth: '96px',
            border: '1px solid #D1D1D1',
            color: '#242424',
          },
          '&.MuiButton-outlined:hover': {
            color: '#242424',
            border: '1px solid #D1D1D1',
          },
          '&.MuiButton-outlined.Mui-disabled': {
            backgroundColor: '#F0F0F0',
            color: '#B0B0B0',
            border: '1px solid #E0E0E0',
          },
          '&.MuiButton-outlined .MuiButton-label': {
            fontWeight: '600',
         },
          // General CSS for navigation buttons (Back, Prev, Next)
          '&.navigationButton':{
            height: '32px',
            width: '96px',
            minWidth: '96px',
          backgroundColor: '#E0E0E0',
          color: '#242424',
          border: '1px solid #BDBDBD',
          '&:hover': {
            backgroundColor: '#E0E0E0',
            color:'#242424'
          },
          '&.Mui-disabled': {
            backgroundColor: '#F0F0F0',
            color: '#B0B0B0',
            border: '1px solid #E0E0E0',
          },
          '& .MuiButton-label':{
            fontWeight: '600',
          }
         }
      }
    },
  },
});