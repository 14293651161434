import React from "react";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import {
    TEMPLATES_LIST_TEMPLATE_NAMES_SUBSCRIPTION,
    TEMPLATES_LOAD_TEMPLATE_SUBSCRIPTION, TEMPLATES_SAVE_TEMPLATE_SUBSCRIPTION
} from "api/websocket/hlc/templatesWebSocketClient";
import SaveTemplateStompResponse from "types/payload/templates/SaveTemplateStompResponse";
import ListTemplateNamesStompResponse from "types/payload/templates/ListTemplateNamesStompResponse";
import {useDispatch, useSelector} from "react-redux";
import ListDialog from "components/common/ListDialog";
import { showErrorNotification, showSuccessNotification } from "components/toastr/notificationActions";
import {
    addAvailableTemplateName,
    closeLoadTemplateDialog, closeSaveTemplateDialog, listTemplateNamesByType, loadTemplate,
    selectAvailableTemplateNames,
    selectLoadTemplateDialogOpen,
    selectSaveTemplateDialogOpen, selectSelectedTemplateName, setAvailableTemplateNames, setSelectedTemplateName
} from "../../templateActions";
import TemplateType from "types/model/templates/TemplateType";
import Templates from "types/model/templates/Templates";

interface TemplateDialogsProps {
    dashletId: number;
    templateType: TemplateType;
    handleLoadTemplate: (template: Templates) => void;
    saveTemplate: (dashletId: number, templateName: string) => void
}

const TemplateDialogs: React.FC<TemplateDialogsProps> = ({
    dashletId,
    templateType,
    handleLoadTemplate,
    saveTemplate,
}) => {
    const dispatch = useDispatch();
    const saveTemplateDialogOpen = useSelector(state => selectSaveTemplateDialogOpen(state, dashletId));
    const loadTemplateDialogOpen = useSelector(state => selectLoadTemplateDialogOpen(state, dashletId));

    const availableTemplateNames = useSelector(state => selectAvailableTemplateNames(state, dashletId));
    const selectedTemplateName = useSelector(state => selectSelectedTemplateName(state, dashletId));

    const selectTemplateName = (templateName: string | null) =>
        dispatch(setSelectedTemplateName(dashletId, templateName));

    const handleChange = (templateName: string | null) =>
        selectTemplateName(templateName);

    const handleSave = () => {
        if (selectedTemplateName) {
            saveTemplate(dashletId, selectedTemplateName);
        }
    };

    const handleLoad = () => {
        if (selectedTemplateName) {
            dispatch(loadTemplate(dashletId, selectedTemplateName));
        }
    };

    const handleLoadTemplateResponse = (response: SaveTemplateStompResponse) => {
        const {hasErrors, errorMessage, template, templateName} = response;
        if (!hasErrors && template) {
            handleLoadTemplate(template);
            if (template.templateType && template.templateName) {
                selectTemplateName(template.templateName);
            }
            dispatch(closeLoadTemplateDialog(dashletId));
        } else {
            showErrorNotification("Load Template", `Failed to load template '${templateName}': ${errorMessage}`);
        }
    };

    const handleSaveTemplateResponse = (response: SaveTemplateStompResponse) => {
        const {hasErrors, errorMessage, templateName, template} = response;
        if (!hasErrors && template) {
            showSuccessNotification("Save as Template", `Template '${templateName}' was saved.`);

            dispatch(addAvailableTemplateName(dashletId, templateName));
            selectTemplateName(templateName);
            dispatch(closeSaveTemplateDialog(dashletId));
        } else {
            showErrorNotification("Save as Template", `Failed to save template: ${errorMessage}`);
        }
    };

    const handleListTemplateNamesResponse = (
        response: ListTemplateNamesStompResponse
    ) => {
        const {hasErrors, errorMessage, templateNames} = response;
        if (!hasErrors) {
            dispatch(setAvailableTemplateNames(dashletId, templateNames));
        } else {
            showErrorNotification("Save as Template", `Failed to load the list of templates: ${errorMessage}`);
        }
    };

    return (
        <>
            <ListDialog
                open={saveTemplateDialogOpen}
                mode="save"
                title="Save As Template"
                options={availableTemplateNames}
                value={selectedTemplateName}
                submitLabel="Save Template"
                onChange={handleChange}
                onSubmit={handleSave}
                onCancel={() => dispatch(closeSaveTemplateDialog(dashletId))}
                // onRemove={handleDelete}
                // onExport={handleExport}
            />
            <ListDialog
                open={loadTemplateDialogOpen}
                mode="load"
                title="Load Template"
                options={availableTemplateNames}
                value={selectedTemplateName}
                submitLabel="Load Template"
                onChange={handleChange}
                onSubmit={handleLoad}
                onCancel={() => dispatch(closeLoadTemplateDialog(dashletId))}
                // onRemove={handleDelete}
                // onExport={handleExport}
            />
            <HLCUserSessionWebSocketSubscription
                subscriptionDestination={TEMPLATES_LIST_TEMPLATE_NAMES_SUBSCRIPTION}
                dashletId={dashletId}
                handleMessage={handleListTemplateNamesResponse}
                onSubscribeCallback={() => dispatch(listTemplateNamesByType(dashletId, templateType))}
            />
            <HLCUserSessionWebSocketSubscription
                subscriptionDestination={TEMPLATES_LOAD_TEMPLATE_SUBSCRIPTION}
                dashletId={dashletId}
                handleMessage={handleLoadTemplateResponse}
            />
            <HLCUserSessionWebSocketSubscription
                subscriptionDestination={TEMPLATES_SAVE_TEMPLATE_SUBSCRIPTION}
                dashletId={dashletId}
                handleMessage={handleSaveTemplateResponse}
            />
        </>
    );
};

export default TemplateDialogs;
