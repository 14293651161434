import React from "react";
import {TableCell} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {formatDecimalNumber, formatDecimalPct} from "utils/FormattingUtils";

interface ValueDeltaPct {
    value: number;
    delta_pct: number;
}

interface DecimalDeltaPctTableCellProps {
    value: ValueDeltaPct | null;
}

const useStyles = makeStyles({
    tableCell: {
        textAlign: "right",
        // fontSize: "0.6vw",
        fontSize: "12px",
    }
});

export const formatDecimalDeltaPct = (value?: number | null) => {
    if (!value) {
        return "";
    }

    if (value > 0) {
        return (
            <span style={{ color: "red"}}>+{formatDecimalPct(value)}</span>
        )
    } else {
        return (
            <span style={{color: "green"}}>{formatDecimalPct(value)}</span>
        )
    }
}

const DecimalDeltaPctTableCell: React.FC<DecimalDeltaPctTableCellProps> = ({ value }) => {
    const classes = useStyles();

    const _value = value?.value;
    const _delta_pct = value?.delta_pct;

    let cellValue = "N/A";
    let cellValueDeltaPct = null;
    if (_value) {
        cellValue = formatDecimalNumber(_value);
        if (_delta_pct) {
            cellValueDeltaPct = formatDecimalDeltaPct(_delta_pct)
        }
    }

    return <TableCell className={classes.tableCell}>{cellValue} {cellValueDeltaPct}</TableCell>;
};

export default DecimalDeltaPctTableCell;
