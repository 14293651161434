import React from "react";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "components/common/Select/Select";
import {MenuItem} from "@material-ui/core";
import { ChevronDown12Regular } from "@fluentui/react-icons";

interface IntegrationDropdownRowProps {
    label: string;
    name: string;
    value: string;
    defaultValue: string
    options: Record<any, any>;
    enabled: boolean | null;
    onChange: (name: string, value: string) => void
}

const IntegrationDropdownRow: React.FC<IntegrationDropdownRowProps> = ({
                                                                           label,
                                                                           name,
                                                                           value,
                                                                           defaultValue,
                                                                           options,
                                                                           enabled,
                                                                           onChange
                                                                       }) => {

    return (
        <Grid
            item
            container
            spacing={2}
            xs={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            style={{marginTop: '5px'}}>
            <Grid item xs={2} style={{textAlign: "left"}}>
                <InputLabel htmlFor={name}>{label}</InputLabel>
            </Grid>
            <Grid item xs={5}>
                <Select
                    disabled={!enabled}
                    name={name}
                    value={value || defaultValue}
                    onChange={(event: any) => onChange(event.target.name, event.target.value)}
                    IconComponent={(props: any) => (
                        <ChevronDown12Regular {...props} style={{ height: '20px', width: '20px', top: '8px' }} />
                    )}
                >
                    {
                        Object.keys(options).map((optKey: any) => (
                            <MenuItem key={optKey} value={optKey}>
                                {options[optKey]}
                            </MenuItem>
                        ))
                    }
                </Select>
            </Grid>
        </Grid>
    );
};

export default IntegrationDropdownRow;
