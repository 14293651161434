import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import AddIcon from '@material-ui/icons/Add';
import { Add24Filled } from '@fluentui/react-icons';

const styles = (theme) => ({
  card: {
    width: 'calc(100% - 56%)',
    height: '85px !important',
    boxShadow: '0 0 8px rgba(0, 0, 0, 0.2)',
    position: 'relative',
    marginLeft: '8px',
    cursor: 'pointer',
    bottom: '5px',
  },
  addIcon: {
    position: 'absolute',
    top: '50%',
    right: '50%',
    transform: 'translate(50%, -50%)',
    color: theme.palette.primary.main,
    cursor: 'pointer',
  },
  cardContent: {
    // height: '237px !important',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
});

class NewDashlet extends Component {
  handleCardClick = () => {
    const { onAddDashletHandler, dashboardData } = this.props;
    if (onAddDashletHandler && dashboardData) {
      onAddDashletHandler(dashboardData.id);
    }
  };

  handleAddIconClick = () => {
    const { onAddDashletHandler, dashboardData } = this.props;
    if (onAddDashletHandler && dashboardData) {
      onAddDashletHandler(dashboardData.id);
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <Card className={classes.card} onClick={this.handleCardClick}>
        <CardContent className={classes.cardContent}>
          {/* Your card content here */}
        </CardContent>
        <Add24Filled className={classes.addIcon} onClick={this.handleAddIconClick} />
      </Card>
    );
  }
}

NewDashlet.propTypes = {
  classes: PropTypes.object.isRequired,
  onAddDashletHandler: PropTypes.func.isRequired,
  dashboardData: PropTypes.object,

  onAddDashletHandler: PropTypes.func,
};

export default withStyles(styles)(NewDashlet);
