import React from "react";
import IconButton, {IconButtonProps} from "@material-ui/core/IconButton";
import FileCopyIcon from '@material-ui/icons/FileCopy';
import CheckIcon from '@material-ui/icons/Check';
import Tooltip from "@material-ui/core/Tooltip";
import { Checkmark12Filled, Checkmark20Filled, Copy16Filled, Copy20Filled } from "@fluentui/react-icons";

interface CopyIconButtonProps extends IconButtonProps {
    text: string;
}

const CopyIconButton: React.FC<CopyIconButtonProps> = ({
                                                           text,
                                                           size,
                                                           ...otherProps
                                                       }) => {
    const [copied, setCopied] = React.useState(false);

    const copyToClipboard = () => {
        navigator.clipboard.writeText(text).then(() => {
            setCopied(true);
            setTimeout(() => setCopied(false), 2000); // Reset after 2 seconds
        });
    };

    return <Tooltip title={copied ? "Copied" : "Copy"}>
        <IconButton
            onClick={copyToClipboard}
            size={size}
            {...otherProps}
        >
            {copied ? <Checkmark20Filled /> : <Copy20Filled />}
        </IconButton>
    </Tooltip>;
}

export default CopyIconButton;
