import React, { useState } from "react";
import NewRelicIntegration from "types/model/settings/integration/NewRelicIntegration";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "components/common/Switch/Switch";
import TextField from "components/common/TextField/TextField";
import useIntegrationSectionState from "hooks/integration/useIntegrationSectionState";
import Button from "@material-ui/core/Button";

interface NewRelicIntegrationSectionProps {
    integration: NewRelicIntegration;
    onSave: (integration: NewRelicIntegration) => void;
}
interface ErrorMessages {
    apiKey: string | null;
}
const NewRelicIntegrationSection: React.FC<NewRelicIntegrationSectionProps> = ({
                                                                                   onSave,
                                                                                   ...props
                                                                               }) => {
    const { integration, handleChangeProperty, handleEnabledToggle }
        = useIntegrationSectionState(props.integration);
    const { enabled, apiKey} = integration as NewRelicIntegration;
    const [errors, setErrors] = useState<ErrorMessages>({
        apiKey: null, 
    });
    const handleSave = () => {
        let hasError = false;
        const newErrors: ErrorMessages = { apiKey: null };
        if (enabled) {
            if (!apiKey) {
                newErrors.apiKey = "apiKey cannot be empty.";
                hasError = true;
            }
            setErrors(newErrors);
        }
        if (!hasError) {
            newErrors.apiKey = "";
            setErrors(newErrors);
            onSave(integration as NewRelicIntegration);
        }
    };                                                                             
    return (
        <>
            <Grid
                container
                spacing={2}
                direction="column"
                justifyContent="flex-start"
                style={{marginTop: "8px", marginBottom: "8px"}}
            >
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="enable">Enabled</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <Switch checked={enabled || false}
                                onChange={handleEnabledToggle}
                                name="enabled"
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={12}
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center">
                    <Grid item xs={2} style={{textAlign: "left"}}>
                        <InputLabel htmlFor="apiKey">API Key</InputLabel>
                    </Grid>
                    <Grid item xs={5}>
                        <TextField
                            disabled={!enabled}
                            name="apiKey"
                            fullWidth
                            textAlign="left"
                            value={apiKey}
                            onChange={(event: any) => handleChangeProperty(event.target.name, event.target.value)}
                        />
                        {enabled && errors.apiKey && <div style={{ color: 'red', marginTop: '4px', textAlign: 'left' }}>{errors.apiKey}</div>}
                    </Grid>
                </Grid>
                <Grid
                    item
                    container
                    spacing={2}
                    xs={7}
                    direction="row"
                    justifyContent="flex-end"
                    alignItems="center">
                    <Grid item xs={1}>
                        <Button onClick={handleSave} variant="contained">Save</Button>
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default NewRelicIntegrationSection;
