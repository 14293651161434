import React, {useState} from "react";
import AWSIntegration from "types/model/settings/integration/cloud/AWSIntegration";
import useIntegrationSectionState from "hooks/integration/useIntegrationSectionState";
import AWSAuthenticationType from "types/model/settings/integration/cloud/AWSAuthenticationType";
import IntegrationCheckboxRow from "components/integrations/IntegrationCheckboxRow";
import IntegrationDropdownRow from "components/integrations/IntegrationDropdownRow";
import IntegrationTextFieldRow from "components/integrations/IntegrationTextFieldRow";
import IntegrationSaveButtonRow from "components/integrations/IntegrationSaveButtonRow";
import IntegrationContainer from "components/integrations/IntegrationContainer";

interface AWSIntegrationSectionProps {
    integration: AWSIntegration;
    onSave: (integration: AWSIntegration) => void;
}

interface ErrorMessages {
    accessKeyId: string | null;
    secretAccessKey: string | null;
}

const emptyErrorMessages = () => {
    return {
        accessKeyId: null,
        secretAccessKey: null
    };
};

const authenticationTypes = {
    [AWSAuthenticationType.KEY_BASED_AUTHENTICATION]: "Key-Based Authentication",
}

const AWSIntegrationSection: React.FC<AWSIntegrationSectionProps> = ({
                                                                         onSave,
                                                                         ...props
                                                                     }) => {
    const {integration, handleChangeProperty, handleEnabledToggle}
        = useIntegrationSectionState(props.integration);
    const {
        enabled,
        authenticationType,
        accessKeyId,
        secretAccessKey
    } = integration as AWSIntegration;
    const [errors, setErrors] = useState<ErrorMessages>(emptyErrorMessages());

    const handleSave = () => {
        let hasError = false;
        const newErrors: ErrorMessages = emptyErrorMessages();

        if (enabled) {
            if (!accessKeyId) {
                newErrors.accessKeyId = "Access Key ID can't be empty.";
                hasError = true;
            }
            if (!secretAccessKey) {
                newErrors.secretAccessKey = "Secret Access Key can't be empty."
                hasError = true;
            }

            setErrors(newErrors);
        }
        if (!hasError) {
            newErrors.accessKeyId = "";
            newErrors.secretAccessKey = "";
            setErrors(newErrors);
            onSave(integration as AWSIntegration);
        }
    };

    return (
        <>
            <IntegrationContainer>
                <IntegrationCheckboxRow
                    label="Enabled"
                    name="enabled"
                    value={enabled}
                    defaultValue={false}
                    toggle={handleEnabledToggle}
                />
                <IntegrationDropdownRow
                    label="Authentication"
                    name="authenticationType"
                    value={authenticationType}
                    defaultValue={AWSAuthenticationType.KEY_BASED_AUTHENTICATION}
                    options={authenticationTypes}
                    enabled={enabled}
                    onChange={handleChangeProperty}
                />
                <IntegrationTextFieldRow
                    label="Access Key ID"
                    name="accessKeyId"
                    value={accessKeyId}
                    enabled={enabled}
                    error={errors.accessKeyId}
                    onChange={handleChangeProperty}
                />
                <IntegrationTextFieldRow
                    label="Secret Access Key"
                    name="secretAccessKey"
                    value={secretAccessKey}
                    enabled={enabled}
                    error={errors.secretAccessKey}
                    onChange={handleChangeProperty}
                />
                <IntegrationSaveButtonRow onSave={handleSave} />
            </IntegrationContainer>
        </>
    );
};

export default AWSIntegrationSection;
