import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const getContent = (title, message) => {
  const titleStyle = {
    marginRight: "7px",
    fontWeight: "bold",
  };

  return (
    <p>
      <strong style={titleStyle}>{title}</strong>
      {message}
    </p>
  );
};

export const showNotification = (type, title, message) => {
  type = type.toLowerCase();
  switch (type) {
    case "success":
      showSuccessNotification(title, message);
    case "error":
      showErrorNotification(title, message);
    case "warning":
      showWarningNotification(title, message);
    case "info":
      showInfoNotification(title, message);
  }
};

// Notification functions
export const showErrorNotification = (title, message) => {
  const content = getContent(title, message);

  toast.error(content, {
    className: 'custom-toast-error',
  });
};

export const showWarningNotification = (title, message) => {
  const content = getContent(title, message);

  toast.warn(content, {
    className: 'custom-toast-warn',
  });
};

export const showSuccessNotification = (title, message) => {
  const content = getContent(title, message);

  toast.success(content, {
    className: 'custom-toast-success',
  });
};


export const showInfoNotification = (title, message) => {
  const content = getContent(title, message);

  toast.info(content, {
    className: 'custom-toast-info',
  });
};
