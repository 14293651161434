import React from "react";
import ReportSection from "types/model/report/ReportSection";
import {makeStyles} from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import {Table, TableBody, TableHead, TableRow} from "@material-ui/core";
import SectionHeader from "dashlets/components/atomic/SectionHeader";
import HeaderTableCell from "dashlets/components/atomic/table/HeaderTableCell";
import ActionTableCell from "dashlets/components/atomic/table/ActionTableCell";
import GenericTableCell from "dashlets/components/atomic/table/GenericTableCell";
import SectionContainer from "dashlets/components/atomic/SectionContainer";
import ToggleActionTableCell from "dashlets/components/atomic/table/ToggleActionTableCell";

const useStyles = makeStyles({
    tableContainer: {
        width: "100%",
        maxHeight: 400,
        boxShadow: "none",
        overflowX: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: '#888 #f0f0f0',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f0f0f0',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px',
        },
    },
});

interface TargetDetailsSectionProps {
    data: ReportSection;
    hiddenTargetIndexes: number[];
    targetColors: string[];
    toggleTarget: (targetIndex: number) => void;
    showRecommendations: (targetName: string) => void;
    showObservations: (targetName: string) => void;
}

const TargetDetailsSection: React.FC<TargetDetailsSectionProps> = ({
    data,
    hiddenTargetIndexes,
    targetColors,
    toggleTarget,
    showRecommendations,
    showObservations,
}) => {
    const classes = useStyles();

    const {name, table} = data;
    const {columns, rows} = table;


    return (
        <SectionContainer>
            <SectionHeader>{name}</SectionHeader>
            <TableContainer className={classes.tableContainer}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            {columns.map(col => <HeaderTableCell key={col.name}>{col.name}</HeaderTableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, rowIndex) =>
                            <TableRow key={row.key} style={{opacity: hiddenTargetIndexes.includes(rowIndex) ? 0.5 : 1}}>
                                {row.values.map((value, valIndex) => {
                                    const cellKey = `${row.key}-${valIndex}`
                                    const valueType = columns[valIndex].type;
                                    if (valueType === "observations") {
                                        return (
                                            <ActionTableCell
                                                key={cellKey}
                                                title="Open"
                                                onClick={() => showObservations(value)}
                                            />
                                        );
                                    }
                                    if (valueType === "recommendations") {
                                        return (
                                            <ActionTableCell
                                                key={cellKey}
                                                title="Open"
                                                onClick={() => showRecommendations(value)}
                                            />
                                        );
                                    }
                                    if (valueType === "action:chart_toggle") {
                                        return (
                                            <ToggleActionTableCell
                                                index={rowIndex}
                                                active={!hiddenTargetIndexes.includes(rowIndex)}
                                                color={targetColors[rowIndex]}
                                                toggle={toggleTarget}
                                            />
                                        )
                                    }

                                    return (
                                        <GenericTableCell key={cellKey} type={valueType} value={value} />
                                    );
                                })}
                            </TableRow>)}
                    </TableBody>
                </Table>
            </TableContainer>
        </SectionContainer>
    );
}

export default TargetDetailsSection;
