import dashboardGroupSchema from "schemas/dashboard/DashboardGroupSchema";
import * as _ from 'lodash';
import { normalize } from 'normalizr';
import {activeDashletsSelector, dashboardByIdSelector, dashletByIdSelector} from "selectors/dashboard/DashboardSelectors";
import {deserializeDashletTimeRange} from "utils/dashboard/dashletTimeRangeUtils";
import {openCreateDashlet , setIsOpenImportDashletModal} from "reducers/dashboard/createDashletSlice";
import DashboardRestClient from "api/rest/DashboardRestClient";
import DashboardGroupRestClient from "api/rest/DashboardGroupRestClient";
import {
  addDashboardGroup,
  addDashboardToTree,
  removeDashboardFromTree,
  removeDashboardGroupFromTree,
  setDashboardLayout, setDashboardModal,
  setDashboardTree,
  setEditDashboardGroup,
  setIsDashboardTreeLoading, setUpdatedDashboard,
  setUpdatedDashboardGroup,
  openChartDialog,
  selectDashletData,
  selectDashlet,
  setCreateDashlet,
  selectCraeteDashletList,
  updateCreateDashlet,
  openDashletPreview,
  setIsChartDataLoading,
  updateDashboardAccordianPanel,
  updateAccordions,
  onAddAccordion as addAccordion,
  setSelectedDashletId,
  unSetTargetConfiguration,
  setActiveDashboardId,
  toogleIsQuickDahboardModalOpen,
  setQuickDashboardModal,
} from "reducers/dashboard/dashboardSlice";
import DashboardTreeRestClient from "api/rest/DashboardTreeRestClient";
import { loadHLCChart, createTarget, createAnomalyDashboard } from "dashlets/HLCChartDashlet/actions";
import { HLC_SERVER_URL, PEI_SERVER_URL } from "config";
import { loadPEIChart, loadChartOnPEI } from "dashlets/PEIChartBagDashlet/actions";
import {createDashlet, refreshDashletData, refreshDashletDataById, removeDashlet, updateDashlet, updateDashletConfig, updateDashletSettings} from "./dashboard/dashletActions";
import { toastr } from "react-redux-toastr";
import { MAX_ALLOWED_DYNAMIC_DASHLETS_COUNT, DYNAMIC_DASHLETS_EXPIRATION_TIME } from "constants/dashboard/timeRangeTypes";
import AccordionPanelRestClient from "api/rest/AccordionPanelRestClient";
import { createPanel, removePanel } from "./AccordionPanelAction";
import { getTimeAmountMilliseconds } from "dashlets/HLCChartDashlet/utils";
import { ANOMALY_DASHBOARD, DEFAULT_DASHBOARD } from "dashlets/AnomalyDetectionDashlet/constants";
import { newDashboardObject } from "objects/dashboardObjects";
import { showErrorNotification } from "components/toastr/notificationActions";

export const loadTreeData = () => dispatch => {
  dispatch(setIsDashboardTreeLoading(true));
  DashboardTreeRestClient.load()
      .then(resultObject => dispatch(loadTreeDataRequestSuccess(resultObject)))
      .catch(error => console.error(error))
      .finally(() => dispatch(setIsDashboardTreeLoading(false)));
};

const loadTreeDataRequestSuccess = resultObject => (dispatch, getState) => {
  if (resultObject.groups.length > 0) {
    let dashboardTree = normalize(resultObject.groups, [dashboardGroupSchema]);
    if (!dashboardTree.entities.dashlets) {
      dashboardTree.entities.dashlets = {}
    }
    if (!dashboardTree.entities.dashboards) {
      dashboardTree.entities.dashboards = {}
    }
    //deserialize dashboard layout configs
    _.each(_.keys(dashboardTree.entities.dashboards), (dashboardId) => {
      dashboardTree.entities.dashboards[dashboardId].layoutConfig = JSON.parse(dashboardTree.entities.dashboards[dashboardId].layoutConfig);
      if (dashboardTree.entities.dashboards[dashboardId].dashboardType === ANOMALY_DASHBOARD) {
        dashboardTree.entities.dashboards[dashboardId].timeSettings = JSON.parse(dashboardTree.entities.dashboards[dashboardId].timeSettings);
      }
    });
    //deserialize dashlet configs
    _.each(_.keys(dashboardTree.entities.dashlets), (dashletId) => {
      dashboardTree.entities.dashlets[dashletId].config = JSON.parse(dashboardTree.entities.dashlets[dashletId].config);
      dashboardTree.entities.dashlets[dashletId].settings = JSON.parse(dashboardTree.entities.dashlets[dashletId].settings);
      dashboardTree.entities.dashlets[dashletId].timeRange = deserializeDashletTimeRange(JSON.parse(dashboardTree.entities.dashlets[dashletId].timeRange));
    });

    dispatch(setDashboardTree(dashboardTree));
  } else {
    // dispatch(createUserDashboard("TEST_GROUP", objectData, backendKey));
    dispatch(setQuickDashboardModal(getState()));
    dispatch(toogleIsQuickDahboardModalOpen(getState()))
  }
};

export const editDashboardGroup = dashboardGroupId => dispatch =>
    dispatch(loadDashboardGroup(dashboardGroupId));

export const cancelEditDashboardGroup = () => dispatch =>
    dispatch(setEditDashboardGroup(null));

export const saveEditDashboardGroup = dashboardGroup => dispatch =>
    dispatch(updateDashboardGroup(dashboardGroup.id, dashboardGroup));

export const editDashboard = dashboardId => dispatch =>
    DashboardRestClient.load(dashboardId)
        .then(dashboard => dispatch(setDashboardModal(dashboard)))
        .catch(error => console.error(error));

export const cancelEditDashboard = () => {
  return (dispatch, getState) => {
    dispatch(setDashboardModal(null));
    dispatch(unSetTargetConfiguration(getState()))
  }
};

export const cancelQuickDashboardModal = () => {
  return (dispatch, getState) => {
    dispatch(toogleIsQuickDahboardModalOpen());
    dispatch(setDashboardModal(null));
    dispatch(unSetTargetConfiguration(getState()))
  }
}
export const cancelImportDashlet = () => {
  return (dispatch, getState) => {
    dispatch(setIsOpenImportDashletModal(null));
  }
};

export const createDashboard = (newDashboard, targetName, createNewTarget) => (dispatch, getState) =>
  DashboardRestClient.create(newDashboard)
    .then(dashboard => {
      dispatch(addDashboardToTree(dashboard));
      const state = getState();
      if (dashboard.dashboardType === DEFAULT_DASHBOARD) {
        const targetConfiguration = state.dashboard.targetConfiguration;
        const CreateDefaultTargetDashletStompFrame = {
          params: {
            ...targetConfiguration,
            acceptPolicy: newDashboard.acceptPolicy
          },
          dashboardId: dashboard.id,
          targetName,
          createNewTarget: createNewTarget,
          onlyTargetCreate: false,
          cloudBackendId: newDashboard.cloudBackendId,
        }
        dispatch(createTarget(CreateDefaultTargetDashletStompFrame, dashboard.defaultBackendKey));
      } else if (dashboard.dashboardType === ANOMALY_DASHBOARD) {
        // dispatch(createAnomalyDashboard(dashboard, true))
      } else {
        const accordion = {
          name: dashboard.dashboardType != ANOMALY_DASHBOARD ? "Overview" : "Target Name",
          dashboardId: dashboard.id
        }
        dispatch(createPanel(accordion));
      }
      dispatch(setActiveDashboardId(dashboard.id));
    }).catch(error => console.error(error));

export const updateDashboard = dashboard => (dispatch, getState) =>
    DashboardRestClient.update(dashboard.id, dashboard)
        .then(dashboard => {
          if (dashboard.dashboardType === ANOMALY_DASHBOARD) {
            // const dashlets = dashboardByIdSelector(getState(), dashboard.id).dashlets;
            // if (dashlets && dashlets.length > 0) {
            //   const dashlet = selectDashlet(getState(), dashlets[0])
            // }
            dispatch(createAnomalyDashboard(dashboard, false));
          }
          dispatch(setUpdatedDashboard(dashboard))
        })
        .catch(error => console.error(error));

export const saveEditDashboard = (dashboard, backendKey, targetName, createNewTarget) => {
  return (dispatch, getState) => {
    if (dashboard.id) {
      dispatch(updateDashboard(dashboard));
    } else {
      
      const timeSettings = {
        isTimeRange: false,
        timeRangeStartTime: 0,
        timeRangeEndTime: new Date().getTime(),
        isSuperimposeTimeRange: false,
        isAdjustEndTime: false,
        isSuperimposeTimeAmount: false,
        superimposeTimeAmountAgoValue: 1,
        superimposeTimeAmountAgoMeasure: "Day",
        isTimeAmount: true,
        timeAmount: 1,
        timeMeasure: "Hour",
        timeAmountAgo: 0,
        timeMeasureAgo: "Hour",
        isTextInput: false,
        textInputValue: "",
        refreshTime: "1M",
      }
      let newDashboard = {
        ...dashboard,
        defaultBackendKey: dashboard.dashboardType === ANOMALY_DASHBOARD || dashboard.dashboardType === DEFAULT_DASHBOARD ? backendKey : null,
        timeSettings: dashboard.dashboardType === ANOMALY_DASHBOARD ? timeSettings : null,
      }
      dispatch(createDashboard(newDashboard, targetName, createNewTarget));
    }
  }
};

export const removeDashboard = dashboardId => dispatch =>
    DashboardRestClient.delete(dashboardId)
        .then(dashboard => dispatch(removeDashboardFromTree(dashboard.id)))
        .catch(error => console.error(error));


export const addDashletOnDashboard = (dashboardId, accordionId) => {
  return (dispatch, getState) => {
    if (!accordionId) {
      const accordionPanels = getState().dashboard.dashboardTree.entities.dashboards[dashboardId].accordionPanels;
      if (Object.keys(accordionPanels).length > 0) {
        accordionId = accordionPanels[Object.keys(accordionPanels)[0]].id;
      }
    }
    dispatch(openCreateDashlet(dashboardId, accordionId));
  }
};

export const updateAccordion = (accordion) => {
  return (dispatch, getState) => {
    AccordionPanelRestClient.update(accordion.id, accordion)
    .then(accordion => dispatch(updateAccordions(accordion)));
  }
}

export const onAddAccordion = () => {
  return (dispatch, getState) => {
    const state = getState();
    const activeDashboardId = state.dashboard.activeDashboardId;
    const activeDashboard = state.dashboard.dashboardTree.entities.dashboards[activeDashboardId];
    const accordionPanels = activeDashboard.accordionPanels || {};

    // Find the highest index among existing accordion panels
    let maxIndex = 0;
    Object.values(accordionPanels).forEach(panel => {
      const index = parseInt(panel.name.replace('Section ', ''), 10);
      if (!isNaN(index) && index > maxIndex) {
        maxIndex = index;
      }
    });

    const newName = `Section ${maxIndex + 1}`;

    let accordion = {
      name: newName,
      dashboardId: activeDashboardId
    };

    dispatch(createPanel(accordion));
    // AccordionPanelRestClient.save(accordion)
    //   .then(accordion => {
    //     dispatch(addAccordion(accordion))
    //   }
    //   );
  };
};

export const refreshActiveDashboard = () => {
  return (dispatch, getState) => {
    dispatch(refreshDashboard(getState().dashboard.activeDashboardId));
  }
};

export const refreshDashboard = (dashboardId) => {
  return (dispatch, getState) => {
    const dashboard = getState().dashboard.dashboardTree.entities.dashboards[dashboardId];
    if (dashboard && dashboard.dashlets) {
      _.each(getState().dashboard.dashboardTree.entities.dashboards[dashboardId].dashlets, (dashletId) => {
        dispatch(refreshDashletDataById(dashletId));
      })
    }
  }
};

export const saveLayout = (dashboardId, layoutConfig) => dispatch =>
    DashboardRestClient.saveLayout(dashboardId, layoutConfig)
        .then(resultObject => dispatch(setDashboardLayout(resultObject)))
        .catch(error => console.error(error));

export const getCustomerPortalUrl = (subscription) => dispatch =>
    DashboardRestClient.getCustomerPortalUrl(subscription)
        .then(resultObject => 
          {
            if (resultObject.hasErrors) {
              showErrorNotification("Get URL", resultObject.errorMessage);
            } else {
              window.location = resultObject.url;
            }
          })
        .catch(error => console.error(error));

export const loadDashboardGroup = dashboardGroupId => dispatch =>
    DashboardGroupRestClient.load(dashboardGroupId)
        .then(dashboardGroup => dispatch(setEditDashboardGroup(dashboardGroup)))
        .catch(error => console.error(error));

export const createDashboardGroup = (dashboardGroup, userDashboardData, backendKey) => dispatch =>
    DashboardGroupRestClient.create(dashboardGroup)
        .then(dashboardGroup => {
          dispatch(addDashboardGroup(dashboardGroup));
          if (userDashboardData) {
            const dashboard = {
              ...userDashboardData,
              dashboardGroupId: dashboardGroup.id,
            }
            dispatch(saveEditDashboard(dashboard, backendKey, "", true));
            dispatch(updateDashboardGroup(dashboardGroup.id, {...dashboardGroup, name : dashboardGroup.name + "_" + dashboardGroup.id}))
          }
        })
        .catch(error => console.error(error));

export const updateDashboardGroup = (dashboardGroupId, dashboardGroup) => dispatch =>
    DashboardGroupRestClient.update(dashboardGroupId, dashboardGroup)
        .then(dashboardGroup => dispatch(setUpdatedDashboardGroup(dashboardGroup)))
        .catch(error => {
          console.error(error);
          dispatch(setEditDashboardGroup(null));
        });

export const removeDashboardGroup = dashboardGroupId => dispatch =>
    DashboardGroupRestClient.delete(dashboardGroupId)
        .then(dashboardGroup => dispatch(removeDashboardGroupFromTree(dashboardGroup.id)))
        .catch(error => console.error(error));

export const addChartOnDashboard = () => {
  return (dispatch, getState) => {
    dispatch(openChartDialog(getState().dashboard.activeDashboardId));
  }
}

export const dashletPreview = (dashletId) =>{ 
  return (dispatch, getState) => {
    dispatch(openDashletPreview(dashletId));
    dispatch(setIsChartDataLoading(true))
  }
}

export const handleClickOnPoint = (templateName) => {
  return async (dispatch, getState) => {
    const state = getState();
    const activeDashlets = activeDashletsSelector(getState());
    const dashlet = activeDashlets[state.dashboard.selectedDashletId];
    window.open(HLC_SERVER_URL + `?backend_key=${dashlet.config.backendKey}&templateName=${templateName}&templateType=charts_template`, "_blank", 'noopener,noreferrer');
    // setTimeout(() => dispatch(loadChartOnHLC(templateName, null, null)), 2500);
  }
}

export const handleLoadSelectedChart = () => {
  return async (dispatch, getState) => {
    const state = getState();
    let templateName = state.dashboard.chartData.chartsTemplate.templateName;
    let startTime = state.dashboard.chartData.xMin;
    let endTime = state.dashboard.chartData.xMax;
    const activeDashlets = activeDashletsSelector(getState());
    const dashlet = activeDashlets[state.dashboard.selectedDashletId];
    let requestParams = `?backend_key=${dashlet.config.backendKey}&templateName=${templateName}&templateType=charts_template`;
    if (startTime != undefined || endTime != undefined) {
      requestParams = requestParams + `&startTime=${startTime}&endTime=${endTime}`;
    }
    window.open(HLC_SERVER_URL + requestParams, "_blank", 'noopener,noreferrer');
    // setTimeout(() => dispatch(loadChartOnHLC(templateName, startTime, endTime)), 2500);
  }
}

export const handleClickOnPointPEI = (templateName) => {
  return async (dispatch, getState) => {
    window.open(PEI_SERVER_URL, "_blank");
    setTimeout(() => dispatch(loadChartOnPEI(templateName)), 2500);
  }
}

/* Dashlet Chart Image to Chart */

export const generateHLCChart = (id) => {
  return (dispatch, getState) => {
      const activeDashlets = activeDashletsSelector(getState());
      const dashlet = activeDashlets[id];
      dispatch(setSelectedDashletId(id));
      dispatch(loadHLCChart(dashlet));
  }
}

export const generatePEIChart = (id) => {
  return (dispatch, getState) => {
    const activeDashlets = activeDashletsSelector(getState());
    const dashlet = activeDashlets[id];
    dispatch(loadPEIChart(dashlet));
  }
}

export const generateDashlet = (dashlet, alertsWithDifferentTimestamps) => {
  return (dispatch, getState) => {
    dashlet = selectDashlet(getState(), dashlet.id);
    let groupByTemplateName = Object.groupBy(alertsWithDifferentTimestamps, ({ templateName }) => templateName);
    let selectedCreateDashlet = selectCraeteDashletList(getState(), dashlet.id);
    let creatingDashlets = {}
    let creatingDashletList = {}
    let isUpdated = false;

    if (selectedCreateDashlet) {
      let updatedParentDashlet = selectedCreateDashlet;
      Object.keys(groupByTemplateName).forEach(templateName => {
        if (!Object.keys(selectedCreateDashlet).includes(templateName)) {
          updatedParentDashlet = {
            ...selectedCreateDashlet,
            [templateName] : false
          };
          isUpdated = true;
        }
      });
      if (isUpdated) {
        dispatch(updateCreateDashlet(dashlet.id, updatedParentDashlet))
      }
    } 
    else {
      Object.keys(groupByTemplateName).forEach(templateName => {
        creatingDashlets[templateName] = false;
      });
      creatingDashletList[dashlet.id] = creatingDashlets
      dispatch(setCreateDashlet(dashlet.id, creatingDashlets))
    }
    Object.keys(groupByTemplateName).forEach(templateName => {
      if (dashlet.config.subDashlets && Object.keys(dashlet.config.subDashlets).includes(templateName)) {
        if (dashlet.config.subDashlets[templateName]) {
          let subDashlet = selectDashlet(getState(), dashlet.config.subDashlets[templateName])
          if (subDashlet) {
            if (subDashlet.settings) {
              let mills = getTimeAmountMilliseconds(subDashlet.settings.refreshRateValue, subDashlet.settings.refreshRateMeasure);
              if (subDashlet.settings.lastUpdatedRefreshRate === undefined || (new Date().getTime() - mills) > subDashlet.settings.lastUpdatedRefreshRate) {
                // dispatch(refreshDashletData(subDashlet));
                subDashlet = {
                  ...subDashlet,
                  settings: {
                    ...subDashlet.settings,
                    lastUpdatedRefreshRate: new Date().getTime()
                  }
                }
                dispatch(updateDashletSettings(subDashlet.id, subDashlet.settings));
              }
            }
            subDashlet = {
              ...subDashlet,
              config: {
                ...subDashlet.config,
                dashletDeleteTime: new Date().getTime() + DYNAMIC_DASHLETS_EXPIRATION_TIME
              }
            }
            dispatch(updateDashletConfig(subDashlet.id, subDashlet.config, false));
          }
        }
      } else {
        if (dashlet.config.subDashlets && Object.keys(dashlet.config.subDashlets).length >= MAX_ALLOWED_DYNAMIC_DASHLETS_COUNT) {
          let minKey = Object.keys(dashlet.config.subDashlets).reduce((a, b) => dashlet.config.subDashlets[a] < dashlet.config.subDashlets[b] ? a : b);
          dispatch(removeDashlet(dashlet.config.subDashlets[minKey]));
          const subDashlets = _.omit(dashlet.config.subDashlets, [minKey]);
          const updatedParentDashlet = {
            ...dashlet,
            config: {
                ...dashlet.config,
                subDashlets: subDashlets
            }
          };
          dispatch(updateDashletConfig(updatedParentDashlet.id, updatedParentDashlet.config, false));
        }

        let dashletData = {
          config: {
              backendKey: dashlet.config.backendKey,
              templateName: templateName,
              autoDelete: true,
              parentDashletId: dashlet.id,
              dashletDeleteTime: new Date().getTime() + DYNAMIC_DASHLETS_EXPIRATION_TIME
          },
          accordionId: dashlet.accordionId,
          dashboardId: dashlet.dashboardId,
          name: dashlet.name + "1",
          settings: null,
          timeRange: null, 
          type: "HLC_CHART_DASHLET_TYPE"
        }

        let selectedCreateDashletList = selectCraeteDashletList(getState(), dashlet.id);
        if (!selectedCreateDashletList[templateName]) {
          let createDashletList = {}
          createDashletList = {
            ...selectedCreateDashletList,
            [templateName]: true
          }
          dispatch(updateCreateDashlet(dashlet.id, createDashletList));
          setTimeout(() => {dispatch(createDashlet(dashletData));}, 500)
        }
      }
    });
  }
}

export const onRemoveAccordionPanel = (accordionId) => (dispatch, getState) => {
  const activeDashboardId = getState().dashboard.activeDashboardId;
  const accordion = getState().dashboard.dashboardTree.entities.dashboards[activeDashboardId].accordionPanels[accordionId];
  accordion.dashlets.forEach((dashletId) => {
    dispatch(removeDashlet(dashletId))
  })
  dispatch(removePanel(accordionId));
}

/* END: Dashlet Chart Image to Chart */

export const removeDashlets = (dashlets) => {
  return (dispatch, getState) => {
    dashlets.forEach(dashlet => {
        if (dashlet.config.parentDashletId) {
          let createdDashletList = selectCraeteDashletList(getState(), dashlet.config.parentDashletId);
          let newCreatedList = _.omit(createdDashletList, dashlet.config.templateName);
          dispatch(updateCreateDashlet(dashlet.config.parentDashletId, newCreatedList));

          const parentDashlet = selectDashlet(getState(), dashlet.config.parentDashletId);
          if (parentDashlet) {
            const subDashlets = _.omit(parentDashlet.config.subDashlets, [dashlet?.config.templateName]);
            const updatedParentDashlet = {
                ...parentDashlet,
                config: {
                    ...parentDashlet.config,
                    subDashlets: subDashlets
                }
            };
            dispatch(updateDashletConfig(updatedParentDashlet.id, updatedParentDashlet.config, false));
          }
        }
        if (dashlet && dashlet.id) {
          dispatch(removeDashlet(dashlet.id));
        }
    });
  }
}

export const createUserDashboard = (groupName, dashboard, backendKey) => (dispatch, getState) => {
  const dashboardGroup = {
    description: null,
    name: groupName
  }
  dispatch(toogleIsQuickDahboardModalOpen());
  dispatch(setDashboardModal(null));
  dispatch(createDashboardGroup(dashboardGroup, dashboard, backendKey));
}

export const createDefaultTarget = (backendKey, isCreateDefaultDashboard, acceptPolicy, cloudBackendId) => (dispatch, getState) => {
  let targetConfiguration = getState().dashboard.targetConfiguration;
  targetConfiguration = {
    ...targetConfiguration,
    acceptPolicy
  }

  if (isCreateDefaultDashboard) {
    const dashboard = {
      ...newDashboardObject(),
      ...targetConfiguration,
      acceptPolicy: acceptPolicy,
      cloudBackendId: cloudBackendId,
      name: targetConfiguration.databaseType + "_Dashboard",
      dashboardType: DEFAULT_DASHBOARD,
    }
    dispatch(createUserDashboard(targetConfiguration.databaseType + "_GROUP", dashboard, backendKey));
  } else {
    const CreateDefaultTargetDashletStompFrame = {
      params: targetConfiguration,
      dashboardId: 0,
      onlyTargetCreate: true,
      createNewTarget: true,
    }
    dispatch(createTarget(CreateDefaultTargetDashletStompFrame, backendKey));
  }
}
