import React from "react";
import {useDispatch} from "react-redux";
import {setDashletData} from "reducers/dashboard/dashboardSlice";
import GenerateFinOpsReportStompResponse from "../types/payload/GenerateFinOpsReportStompResponse";
import useCommonDashletProperties from "hooks/dashlets/useCommonDashletProperties";
import FinOpsReport from "../../../types/model/finops/regular/report/FinOpsReport";
import SummarySection from "./sections/SummarySection";
import ConsumptionSection from "./sections/ConsumptionSection";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import {FINOPS_GENERATE_REGULAR_REPORT_SUBSCRIPTION} from "../webSocketClient";
import {applyRegularFinOpsTemplate, refresh, saveRegularFinOpsTemplate} from "../actions";
import TemplateDialogs from "dashlets/components/dialogs/TemplateDialogs";
import TemplateType from "types/model/templates/TemplateType";
import RegularFinOpsTemplate from "types/model/templates/finops/RegularFinOpsTemplate";
import Templates from "types/model/templates/Templates";
import DynamicTableSection from "../../SnowflakeReportDashlet/components/sections/DynamicTableSection";


interface FinOpsDashletViewProp {
    dashlet: any;
}

const FinOpsDashletView: React.FC<FinOpsDashletViewProp> = ({
                                                                dashlet
                                                            }) => {
    const dispatch = useDispatch();

    const dashletId = dashlet.id;
    const {
        data,
        backendKey,
        isStompClientConnected,
    } = useCommonDashletProperties(dashlet);

    const handleFinOpsReportResponse = (response: GenerateFinOpsReportStompResponse) => {
        // setHiddenTargetIndexes([]);
        // @ts-ignore
        dispatch(setDashletData(dashletId, {report: response.report}));
    };

    const handleLoadTemplate = (template: Templates) =>
        dispatch(applyRegularFinOpsTemplate(dashletId, template as RegularFinOpsTemplate))

    const saveTemplate = (dashletId: number, templateName: string) =>
        dispatch(saveRegularFinOpsTemplate(dashletId, templateName));

    const report: FinOpsReport = data?.report;

    return (
        <>
            {report && (
                <>
                    <SummarySection title="Targets (Database Instances)" data={report?.summary} />
                    <ConsumptionSection
                        title="Consumption"
                        data={report?.consumption}
                    />

                    {report?.sections.map((sectionData, index) => (
                        <DynamicTableSection
                            key={`dynamic-table-section-${sectionData?.name}`}
                            entityType="SQL"
                            data={sectionData}
                            showRecommendations={() => console.log(" ===> SQL - show recommendations")}
                            showObservations={() => console.log(" ===> SQL - show observations")}
                        />
                    ))}
                </>
            )}
            <TemplateDialogs
                dashletId={dashletId}
                templateType={TemplateType.REGULAR_FINOPS_TEMPLATE}
                handleLoadTemplate={handleLoadTemplate}
                saveTemplate={saveTemplate}
            />
            {isStompClientConnected && (
                <HLCUserSessionWebSocketSubscription
                    key={`hlc-ws-subscr-finops-report-dashlet-${dashletId}`}
                    subscriptionDestination={FINOPS_GENERATE_REGULAR_REPORT_SUBSCRIPTION}
                    dashletId={dashletId}
                    handleMessage={handleFinOpsReportResponse}
                    onSubscribeCallback={() => dispatch(refresh(dashlet))}
                />
            )}
        </>
    );
};

export default FinOpsDashletView;
