import React, {FC, useState} from 'react';
import {Theme, WithStyles} from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "../../../components/common/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import {getEditDashletConfigComponent} from "../../../dashlets/dashletsConfig";
import {useDispatch} from "react-redux";
import {updateDashletConfig} from "../../../actions/dashboard/dashletActions";

const styles = (theme: Theme) => ({
    root: {
        maxWidth: '700px',
    },
    dialogContent: {
        width: '400px',
    },
    actionButtonLabel: {
        fontWeight: 600,
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    }
});

interface EditDashletConfigProps extends WithStyles<typeof styles> {
    open: boolean;
    dashlet: any;
    onClose: () => void;
}

const EditDashletConfig: FC<EditDashletConfigProps> = ({
                                                           classes,
                                                           open,
                                                           dashlet,
                                                           onClose
                                                       }) => {
    const [dashletConfig, setDashletConfig] = useState(dashlet?.config || {});
    const dispatch = useDispatch();

    const changeDashletConfigProperty = (propertyName: string, propertyValue: unknown) => {
        setDashletConfig({
            ...dashletConfig,
            [propertyName]: propertyValue
        })
    }

    const onSaveDashletConfig = () => {
        dispatch(updateDashletConfig(dashlet.id, dashletConfig, true));
        onClose();
    }

    const renderEditDashletConfigByType = () => {
        const EditDashletConfigComponent = getEditDashletConfigComponent(dashlet?.type);

        return EditDashletConfigComponent
            ? <EditDashletConfigComponent
                dashletConfig={dashletConfig}
                setDashletConfigProperty={changeDashletConfigProperty}
            />
            : null;
    }

    return <React.Fragment>
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" onClose={onClose}>
                Dashlet Configuration
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
                {renderEditDashletConfigByType()}
            </DialogContent>

            <DialogActions>
                <Button onClick={onSaveDashletConfig} color="primary" variant="contained" classes={{
                    label: classes.actionButtonLabel
                }}>
                    Save
                </Button>
                <Button onClick={onClose} color="secondary" variant="outlined" classes={{
                    label: classes.actionButtonLabel
                }}>
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
    </React.Fragment>;
}

export default withStyles(styles)(EditDashletConfig);