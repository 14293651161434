import React from "react";
import {Table, TableBody, TableHead, TableRow} from "@material-ui/core";
import SnowflakeDatabaseReportInventorySection from "../../types/model/report/SnowflakeDatabaseReportInventorySection";
import {makeStyles} from "@material-ui/core/styles";
import TableContainer from "@material-ui/core/TableContainer";
import SectionContainer from "dashlets/components/atomic/SectionContainer";
import SectionHeader from "dashlets/components/atomic/SectionHeader";
import HeaderTableCell from "dashlets/components/atomic/table/HeaderTableCell";
import StringTableCell from "dashlets/components/atomic/table/StringTableCell";
import DecimalCurrencyTableCell from "dashlets/components/atomic/table/DecimalCurrencyTableCell";
import DecimalNumberTableCell from "dashlets/components/atomic/table/DecimalNumberTableCell";
import NumberTableCell from "dashlets/components/atomic/table/NumberTableCell";
import BooleanTableCell from "dashlets/components/atomic/table/BooleanTableCell";

const useStyles = makeStyles({
    tableContainer: {
        width: "100%",
        maxHeight: 400,
        boxShadow: "none",
        overflowX: 'auto',
        scrollbarWidth: 'thin',
        scrollbarColor: '#888 #f0f0f0',
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#f0f0f0',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#888',
            borderRadius: '4px',
        },
    },
});

interface InventorySectionProps {
    title: string;
    data: SnowflakeDatabaseReportInventorySection;
}

const InventorySection: React.FC<InventorySectionProps> = ({
    title,
    data
                                    }) => {
    const classes = useStyles();

    return (
        <SectionContainer>
            <SectionHeader>{title}</SectionHeader>
            <TableContainer className={classes.tableContainer}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        <TableRow>
                            <HeaderTableCell>Warehouse name</HeaderTableCell>
                            <HeaderTableCell>Cost ($)</HeaderTableCell>
                            <HeaderTableCell>Credits Used</HeaderTableCell>
                            <HeaderTableCell>WH Size</HeaderTableCell>
                            <HeaderTableCell>Scaling Policy</HeaderTableCell>
                            <HeaderTableCell>WH Cluster Size</HeaderTableCell>
                            <HeaderTableCell>Auto Suspend (s)</HeaderTableCell>
                            <HeaderTableCell>Auto Resume</HeaderTableCell>
                            <HeaderTableCell>Query Accl. Enabled</HeaderTableCell>
                            <HeaderTableCell>Query Accl. Max Scale Factor</HeaderTableCell>
                            <HeaderTableCell>WH Type</HeaderTableCell>
                            <HeaderTableCell>Owner</HeaderTableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data?.rows.map((row, index) => (
                            <TableRow key={`inv-table-row-${index}`}>
                                <StringTableCell value={row.warehouseName}></StringTableCell>
                                <DecimalCurrencyTableCell value={row.cost}></DecimalCurrencyTableCell>
                                <DecimalNumberTableCell value={row.creditsUsed}></DecimalNumberTableCell>
                                <StringTableCell value={row.whSize}></StringTableCell>
                                <StringTableCell value={row.scalingPolicy}></StringTableCell>
                                <StringTableCell value={row.whClusterSize}></StringTableCell>
                                <NumberTableCell value={row.autoSuspend}></NumberTableCell>
                                <BooleanTableCell value={row.autoResume}></BooleanTableCell>
                                <BooleanTableCell value={row.queryAcclEnabled}></BooleanTableCell>
                                <NumberTableCell value={row.queryAcclMaxScaleFactor}></NumberTableCell>
                                <StringTableCell value={row.whType}></StringTableCell>
                                <StringTableCell value={row.owner}></StringTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </SectionContainer>
    )
};

export default InventorySection;
