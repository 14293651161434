import React, {useEffect} from "react";
import {Box} from "@material-ui/core";
import {useDispatch, useSelector} from "react-redux";
import * as paths from "routes/paths";
import {
    selectIntegrationEntities,
    selectIntegrationsBackendKey,
    setIntegrationsBackendKey,
} from "reducers/settingsSlice";
import IntegrationType from "types/model/settings/integration/IntegrationType";
import Integration from "types/model/settings/integration/Integration";
import {handleListIntegrations, listIntegrations, saveIntegration, handleSaveIntegrationSettings, testIntegration, handleTestIntegrationResponse} from "actions/settings/integrationActions";
import {LIST_INTEGRATIONS_SETTINGS_SUBSCRIPTION, SAVE_INTEGRATION_SETTINGS_SUBSCRIPTION, TEST_INTEGRATION_CONFIG_URL_FOR_HLC_SUBSCRIPTION} from "api/websocket/hlc/integrationWebSocketClient";
import {HLC_WEB_SOCKET_URL, IS_HLC_WS_ENABLED, IS_PEI_WS_ENABLED, PEI_WEB_SOCKET_URL} from "config";
import StompClient from "websocket/StompClient";
import {selectIsHLCStompClientConnected, STOMP_CLIENT} from "reducers/webSocketSlice";
import {Route, Router, Switch} from "react-router-dom";
import APMIntegrations from "./apm/APMIntegrations";
import history from "routes/history";
import TicketingIntegrations from "./ticketing/TicketingIntegrations";
import BotsIntegrations from "./bots/BotsIntegrations";
import OtherIntegrations from "./anomalyAI/OtherIntegrations";
import LegacyChannelsIntegrations from "./legacyChannels/LegacyChannelsIntegrations";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import BackendSelectionRow from "components/common/BackendSelectionRow";
import GenAiIntegration from "./genAi/GenAiIntegration";
import FinOpsIntegrations from "./finops/FinOpsIntegrations";
import {INTEGRATIONS_CLOUD} from "routes/paths";
import CloudIntegrations from "./cloud/CloudIntegrations";


const IntegrationContent: React.FC = ({}) => {
    const dispatch = useDispatch();
    const backendKey: string | null = useSelector(selectIntegrationsBackendKey);
    const isStompClientConnected = useSelector(selectIsHLCStompClientConnected);
    const integrationEntities: Record<IntegrationType, Integration> | {} = useSelector(selectIntegrationEntities);

    const handleSaveIntegration = (integration: Integration) => {
        if (backendKey) {
            dispatch(saveIntegration(backendKey, integration));
        }
    };

    const handleTestIntegration = (integration: Integration) => {
        if (backendKey) {
            dispatch(testIntegration(backendKey, integration));
        }
    }

    const handleChangeBackend = (backendKey: string) => dispatch(setIntegrationsBackendKey(backendKey));

    const loadIntegrations = () => {
        if (backendKey && isStompClientConnected) {
            dispatch(listIntegrations(backendKey));
        }
    }

    useEffect(() => {
        loadIntegrations();
    }, [backendKey]);

    return (
        <>
            <Box style={{
                display: 'block',
                height: "100%",
                width: "100%",
                overflowY: "scroll",
                overflowX: "hidden"
            }}>
                <Box style={{
                    margin: '12px',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    width: "100%",
                }}
                >
                    <BackendSelectionRow backendKey={backendKey} onChangeBackend={handleChangeBackend}/>

                    <Router history={history}>
                        <Switch>
                            <Route
                                path={paths.INTEGRATIONS_APM}
                                render={() => <APMIntegrations integrations={integrationEntities} onSaveIntegration={handleSaveIntegration} />}
                            />
                            <Route
                                path={paths.INTEGRATIONS_TICKETING}
                                render={() => <TicketingIntegrations integrations={integrationEntities} onSaveIntegration={handleSaveIntegration} />}
                            />
                            <Route
                                path={paths.INTEGRATIONS_BOTS}
                                render={() => <BotsIntegrations integrations={integrationEntities} onSaveIntegration={handleSaveIntegration} onTestIntegration={handleTestIntegration} />}
                            />
                            <Route
                                path={paths.INTEGRATIONS_LEGACY_CHANNELS}
                                render={() => <LegacyChannelsIntegrations integrations={integrationEntities} onSaveIntegration={handleSaveIntegration} onTestIntegration={handleTestIntegration} />}
                            />
                            <Route
                                path={paths.INTEGRATIONS_GEN_AI}
                                render={() => <GenAiIntegration integrations={integrationEntities} onSaveIntegration={handleSaveIntegration} />}
                            />
                            <Route
                                path={paths.INTEGRATIONS_ANOMALY}
                                render={() => <OtherIntegrations integrations={integrationEntities} onSaveIntegration={handleSaveIntegration} />}
                            />
                            <Route
                                path={paths.INTEGRATIONS_FINOPS}
                                render={() => <FinOpsIntegrations integrations={integrationEntities} onSaveIntegration={handleSaveIntegration} />}
                            />
                            <Route
                                path={paths.INTEGRATIONS_CLOUD}
                                render={() => <CloudIntegrations integrations={integrationEntities} onSaveIntegration={handleSaveIntegration} />}
                            />
                        </Switch>
                    </Router>
                </Box>
            </Box>

            {IS_PEI_WS_ENABLED ? [
                <StompClient
                    key="hlc-ws-stomp-client-1582840873831"
                    stompClientKey={STOMP_CLIENT.PEI}
                    sockJsEndpoint={PEI_WEB_SOCKET_URL}
                    debugMode
                />,
            ] : null}
            {IS_HLC_WS_ENABLED ? [
                <StompClient
                    key="pei-ws-stomp-client-1582840873831"
                    stompClientKey={STOMP_CLIENT.HLC}
                    sockJsEndpoint={HLC_WEB_SOCKET_URL}
                    debugMode
                />,
            ] : null}

            <HLCUserSessionWebSocketSubscription
                subscriptionDestination={LIST_INTEGRATIONS_SETTINGS_SUBSCRIPTION}
                handleMessage={(response) => dispatch(handleListIntegrations(response))}
                onSubscribeCallback={loadIntegrations}
            />
            <HLCUserSessionWebSocketSubscription
                subscriptionDestination={SAVE_INTEGRATION_SETTINGS_SUBSCRIPTION}
                handleMessage={(response) => dispatch(handleSaveIntegrationSettings(response))}
                onSubscribeCallback={loadIntegrations}
            />
             <HLCUserSessionWebSocketSubscription
                subscriptionDestination={TEST_INTEGRATION_CONFIG_URL_FOR_HLC_SUBSCRIPTION}
                handleMessage={(response) => dispatch(handleTestIntegrationResponse(response))}
            />
        </>
    );
};

export default IntegrationContent;
