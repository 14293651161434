import React from "react";
import {Grid} from "@material-ui/core";
import {formatDecimalCurrency, formatDecimalNumber, formatDecimalPct, formatNumber} from "utils/FormattingUtils";
import SectionContainer from "dashlets/components/atomic/SectionContainer";
import SectionHeader from "dashlets/components/atomic/SectionHeader";
import HeaderLabel from "dashlets/components/atomic/HeaderLabel";
import HeaderValue from "dashlets/components/atomic/HeaderValue";
import FinOpsReportSummary from "../../../../types/model/finops/regular/report/FinOpsReportSummary";

interface SummarySectionProps {
    title: string;
    data: FinOpsReportSummary;
}

export const formatDecimalDeltaPct = (value?: number | null) => {
    if (!value) {
        return "";
    }

    if (value > 0) {
        return (
            <span style={{ color: "red"}}>+{formatDecimalPct(value)}</span>
        )
    } else {
        return (
            <span style={{color: "green"}}>{formatDecimalPct(value)}</span>
        )
    }
}

const SummarySection: React.FC<SummarySectionProps> = ({
                                                           title,
                                                           data
                                                       }) => (
    <SectionContainer>
        <SectionHeader>{title}</SectionHeader>
        <Grid container spacing={1}>
            <Grid item xs={2}><HeaderLabel>Targets</HeaderLabel></Grid>
            <Grid item xs={2}><HeaderLabel>Credits Used</HeaderLabel></Grid>
            <Grid item xs={2}><HeaderLabel>Compute Cost</HeaderLabel></Grid>
            <Grid item xs={2}><HeaderLabel>Avg. Daily Cost</HeaderLabel></Grid>
            <Grid item xs={2}><HeaderLabel>Number of queries</HeaderLabel></Grid>
            <Grid item xs={2}><HeaderLabel>Elapsed time (ms)</HeaderLabel></Grid>

            <Grid item xs={2}><HeaderValue>{formatNumber(data?.targetsCount)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalNumber(data?.creditsUsed)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalCurrency(data?.cost)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalCurrency(data?.avgDailyCost)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatNumber(data?.numberOfQueries)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatNumber(data?.elapsedTimeMs)}</HeaderValue></Grid>

            <Grid item xs={2}><HeaderValue>{""}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalDeltaPct(data?.creditsUsedDeltaPct)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalDeltaPct(data?.costDeltaPct)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalDeltaPct(data?.avgDailyCostDeltaPct)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalDeltaPct(data?.numberOfQueriesDeltaPct)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalDeltaPct(data?.elapsedTimeMsDeltaPct)}</HeaderValue></Grid>
        </Grid>
    </SectionContainer>
)

export default SummarySection;
