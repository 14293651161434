import React, {Component} from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "components/common/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

const styles = theme => ({
  root: {
  },
  dialogTitle: {
    padding : '15px 18px 0px'
},
dialogContent: {
  width: '450px',
  padding : '5px 18px 0px',
    // padding : '3px 18px 7px',
},
dialogContentText:{
  fontSize:"14px",
  color:"#707070",
  lineHeight:"20px",
  marginBottom:"3px"
},
  removeButton:{
    height:"32px",
    width:"96px",
    minWidth:"96px",
    padding:"6px 12px",
    // border: "1px solid #0F6CBD",
    backgroundColor: '#0F6CBD',
    borderRadius: '4px', 
    textTransform: "capitalize",
    color: '#FFFFFF',
    fontSize:"14px",
    LineHeight:"20px",
    gap:"6px",
    "& .MuiButton-label": {
        fontWeight: 600, 
    },
    borderRadius: '5px',
    "&:hover": {
      backgroundColor: '#0F6CBD', 
      color:'#FFFFFF'
  },
  },
  cancelButton:{
  
  },
  dialogActions: { 
    margin: '0px 10px 10px 0px',
}
});

class RemoveConfirmation extends Component {
  render() {
    const {classes, open, title, message, handleClose, handleConfirm} = this.props;
    return (
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">{title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogContentText} id="alert-dialog-description">
            {message}
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button className={classes.removeButton} onClick={handleConfirm} variant="contained" autoFocus>
            Remove
          </Button>
          <Button onClick={handleClose} variant="outlined">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

RemoveConfirmation.propTypes = {
  //data
  classes: PropTypes.object.isRequired,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,

  //func
  handleClose: PropTypes.func,
  handleConfirm: PropTypes.func,

  //...
};

export default withStyles(styles)(RemoveConfirmation);