import * as webSocketClient from './webSocketClient';
import {serializeDashletTimeRange} from "utils/dashboard/dashletTimeRangeUtils";
import {selectDashlet} from "reducers/dashboard/dashboardSlice";
import TemplateType from "types/model/templates/TemplateType";
import * as templatesWebSocketClient from "../../api/websocket/hlc/templatesWebSocketClient";
import RegularFinOpsTemplate from "types/model/templates/finops/RegularFinOpsTemplate";
import {updateDashletTimeRangeAndSettings} from "actions/dashboard/dashletActions";
import FinOpsReportSettings from "../../types/model/finops/regular/FinOpsReportSettings";
import FinanceTimeRangeSettings from "../../types/model/finance/FinanceTimeRangeSettings";
import FinOpsReportParams from "../../types/model/finops/regular/FinOpsReportParams";

const getDefaultSettings = (): FinOpsReportSettings => {
    return {
        topSqlCount: 10,
        sqlCostDeltaPctThreshold: 0.1,
    };
};

export const refresh = (dashlet: any) => (dispatch: any, getState: any) => {
    const {backendKey, targetNames} = dashlet.config;
    const {id, timeRange, settings} = dashlet;

    webSocketClient.generateFinOpsReport(getState(), backendKey, {
        dashletId: id,
        targetNames: targetNames,
        reportParams: {
            timeRangeSettings: serializeDashletTimeRange(timeRange),
            ...settings,
        },
    });
};

export const saveRegularFinOpsTemplate = (dashletId: number, templateName: string) => (dispatch: any, getState: any) => {
    const appState = getState();
    const dashlet = selectDashlet(appState, dashletId);
    const backendKey = dashlet.config.backendKey;
    const targetNames = dashlet.config.targetNames;
    const timeRangeSettings = serializeDashletTimeRange(dashlet.timeRange);
    const reportParams: FinOpsReportParams = {
        timeRangeSettings,
        ...dashlet.settings,
    }

    const template: RegularFinOpsTemplate = {
        templateType: TemplateType.REGULAR_FINOPS_TEMPLATE,
        templateName,
        targetNames,
        reportParams,
    };

    templatesWebSocketClient.saveTemplate(appState, backendKey, {
        dashletId,
        templateName,
        template
    });
}

export const applyRegularFinOpsTemplate = (dashletId: number, template: RegularFinOpsTemplate) => (dispatch: any) => {
    const timeRangeSettings: FinanceTimeRangeSettings = template.reportParams.timeRangeSettings;

    const settings: FinOpsReportSettings = {
        topSqlCount: template.reportParams.topSqlCount,
        sqlCostDeltaPctThreshold: template.reportParams.sqlCostDeltaPctThreshold,
    };

    dispatch(updateDashletTimeRangeAndSettings(dashletId, timeRangeSettings, settings));
}

export const validateConfig = (dashletConfig: any) => {
    const errors = {};

    console.debug('===> HeatmapDashlet validateConfig() ');

    return errors;
};

export const buildDashletObject = (dashletData: any, configOptions: any) => {
    const dashletSettings = dashletData?.settings && Object.keys(dashletData.settings).length > 0
        ? dashletData.settings
        : getDefaultSettings();

    return {
        ...dashletData,
        settings: dashletSettings,
        backendKey: dashletData.config.backendKey
    }
}

