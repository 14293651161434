import {includes as _includes} from "lodash";
import {putInDashletStorage, selectDashletStorageValue} from "reducers/dashboard/dashletsStorageSlice";
import {selectDashlet, selectDashletBackendKey} from "reducers/dashboard/dashboardSlice";
import * as templatesWebSocketClient from "api/websocket/hlc/templatesWebSocketClient";
import TemplateType from "types/model/templates/TemplateType";
import {AVAILABLE_TEMPLATE_NAMES, SAVE_TEMPLATE_DIALOG_OPEN, SELECTED_TEMPLATE_NAME} from "./dashletStorageKeys";
import {LOAD_TEMPLATE_DIALOG_OPEN} from "./SnowflakeReportDashlet/dashletStorageKeys";

export const selectSelectedTemplateName = (state: any, dashletId: number) =>
    selectDashletStorageValue(state, dashletId, SELECTED_TEMPLATE_NAME);

export const setSelectedTemplateName = (dashletId: number, selectedTemplateName: string | null) => (dispatch: any) =>
    dispatch(putInDashletStorage(dashletId, SELECTED_TEMPLATE_NAME, selectedTemplateName));

export const selectAvailableTemplateNames = (state: any, dashletId: number) =>
    selectDashletStorageValue(state, dashletId, AVAILABLE_TEMPLATE_NAMES) || [];

export const setAvailableTemplateNames = (dashletId: number, availableTemplateNames: string[]) => (dispatch: any) =>
    dispatch(putInDashletStorage(dashletId, AVAILABLE_TEMPLATE_NAMES, availableTemplateNames));

export const addAvailableTemplateName = (dashletId: number, templateName: string) => (dispatch: any, getState: any) => {
    const prevAvailableTemplateNames = selectAvailableTemplateNames(getState(), dashletId);
    if (!_includes(prevAvailableTemplateNames, templateName)) {
        dispatch(putInDashletStorage(dashletId, AVAILABLE_TEMPLATE_NAMES, prevAvailableTemplateNames.concat(templateName)));
    }
}

export const selectLoadTemplateDialogOpen = (state: any, dashletId: number) =>
    selectDashletStorageValue(state, dashletId, LOAD_TEMPLATE_DIALOG_OPEN);

export const openLoadTemplateDialog = (dashletId: number) => (dispatch: any) =>
    dispatch(putInDashletStorage(dashletId, LOAD_TEMPLATE_DIALOG_OPEN, true));

export const closeLoadTemplateDialog = (dashletId: number) => (dispatch: any) =>
    dispatch(putInDashletStorage(dashletId, LOAD_TEMPLATE_DIALOG_OPEN, false));

export const selectSaveTemplateDialogOpen = (state: any, dashletId: number) =>
    selectDashletStorageValue(state, dashletId, SAVE_TEMPLATE_DIALOG_OPEN);

export const openSaveTemplateDialog = (dashletId: number) => (dispatch: any) =>
    dispatch(putInDashletStorage(dashletId, SAVE_TEMPLATE_DIALOG_OPEN, true));

export const closeSaveTemplateDialog = (dashletId: number) => (dispatch: any) =>
    dispatch(putInDashletStorage(dashletId, SAVE_TEMPLATE_DIALOG_OPEN, false));

export const listTemplateNamesByType = (dashletId: number, templateType: TemplateType) => (dispatch: any, getState: any) => {
    const appState = getState();
    const backendKey = selectDashletBackendKey(appState, dashletId);

    templatesWebSocketClient.listTemplateNames(appState, backendKey, {
        dashletId,
        templateType
    })
}

export const loadTemplate = (dashletId: number, templateName: string) => (dispatch: any, getState: any) => {
    const appState = getState();
    const dashlet = selectDashlet(appState, dashletId);
    const backendKey = dashlet.config.backendKey;

    templatesWebSocketClient.loadTemplate(appState, backendKey, {
        dashletId,
        templateName,
        templateExecution: false,
        startTime: null,
        endTime: null
    })
}

export const listSnowflakeFinOpsTemplateNames = (dashletId: number) => (dispatch: any, getState: any) => {
    const appState = getState();
    const backendKey = selectDashletBackendKey(appState, dashletId);

    templatesWebSocketClient.listTemplateNames(appState, backendKey, {
        dashletId,
        templateType: TemplateType.SNOWFLAKE_FINOPS_TEMPLATE
    })
}