import React from "react";
import {Grid} from "@material-ui/core";

interface IntegrationContainerProps {
    children: React.ReactNode;
}

const IntegrationContainer: React.FC<IntegrationContainerProps> = ({ children }) => (
    <Grid
        container
        spacing={2}
        direction="column"
        justifyContent="flex-start"
        style={{marginTop: "8px", marginBottom: "8px"}}
    >
        {children}
    </Grid>
);

export default IntegrationContainer;
