import SnowflakeFinOpsTemplate from "types/model/templates/finops/SnowflakeFinOpsTemplate";
import React from "react";
import HLCUserSessionWebSocketSubscription from "websocket/HLCUserSessionWebSocketSubscription";
import {
    TEMPLATES_LIST_TEMPLATE_NAMES_SUBSCRIPTION,
    TEMPLATES_LOAD_TEMPLATE_SUBSCRIPTION, TEMPLATES_SAVE_TEMPLATE_SUBSCRIPTION
} from "api/websocket/hlc/templatesWebSocketClient";
import {
    addAvailableTemplateName,
    closeLoadTemplateDialog,
    closeSaveTemplateDialog,
    listSnowflakeFinOpsTemplateNames,
    loadSnowflakeFinOpsTemplate,
    saveSnowflakeFinOpsTemplate,
    selectAvailableTemplateNames,
    selectLoadTemplateDialogOpen,
    selectSaveTemplateDialogOpen,
    selectSelectedTemplateName,
    setAvailableTemplateNames,
    setSelectedTemplateName
} from "../../actions";
import SaveTemplateStompResponse from "types/payload/templates/SaveTemplateStompResponse";
import ListTemplateNamesStompResponse from "types/payload/templates/ListTemplateNamesStompResponse";
import {useDispatch, useSelector} from "react-redux";
import ListDialog from "components/common/ListDialog";
import {toastr} from "react-redux-toastr";
import { showErrorNotification, showSuccessNotification } from "components/toastr/notificationActions";

interface TemplateDialogsProps {
    dashletId: number;
    onLoadTemplate: (template: SnowflakeFinOpsTemplate) => void;
}

const TemplateDialogs: React.FC<TemplateDialogsProps> = ({
    dashletId,
    onLoadTemplate,
}) => {
    const dispatch = useDispatch();
    const saveTemplateDialogOpen = useSelector(state => selectSaveTemplateDialogOpen(state, dashletId));
    const loadTemplateDialogOpen = useSelector(state => selectLoadTemplateDialogOpen(state, dashletId));

    const availableTemplateNames = useSelector(state => selectAvailableTemplateNames(state, dashletId));
    const selectedTemplateName = useSelector(state => selectSelectedTemplateName(state, dashletId));

    const selectTemplateName = (templateName: string | null) =>
        dispatch(setSelectedTemplateName(dashletId, templateName));

    const handleChange = (templateName: string | null) =>
        selectTemplateName(templateName);

    const handleSave = () => {
        if (selectedTemplateName) {
            dispatch(saveSnowflakeFinOpsTemplate(dashletId, selectedTemplateName));
        }
    };

    const handleLoad = () => {
        if (selectedTemplateName) {
            dispatch(loadSnowflakeFinOpsTemplate(dashletId, selectedTemplateName));
        }
    };

    const handleLoadTemplateResponse = (response: SaveTemplateStompResponse) => {
        const {hasErrors, errorMessage, template, templateName} = response;
        if (!hasErrors && template) {
            onLoadTemplate(template as SnowflakeFinOpsTemplate);
            if (template.templateType && template.templateName) {
                selectTemplateName(template.templateName);
            }
            dispatch(closeLoadTemplateDialog(dashletId));
        } else {
            showErrorNotification("Load Snowflake Template", `Failed to load Snowflake template '${templateName}': ${errorMessage}`);
        }
    };

    const handleSaveTemplateResponse = (response: SaveTemplateStompResponse) => {
        const {hasErrors, errorMessage, templateName, template} = response;
        if (!hasErrors && template) {
            showSuccessNotification("Save as Snowflake Template", `Snowflake template '${templateName}' was saved.`);

            dispatch(addAvailableTemplateName(dashletId, templateName));
            selectTemplateName(templateName);
            dispatch(closeSaveTemplateDialog(dashletId));
        } else {
            showErrorNotification("Save as Snowflake Template", `Failed to save Snowflake template: ${errorMessage}`);
        }
    };

    const handleListTemplateNamesResponse = (
        response: ListTemplateNamesStompResponse
    ) => {
        const {hasErrors, errorMessage, templateNames} = response;
        if (!hasErrors) {
            dispatch(setAvailableTemplateNames(dashletId, templateNames));
        } else {
            showErrorNotification("Save as Snowflake Template", `Failed to load the list of Snowflake templates: ${errorMessage}`);
        }
    };

    return (
        <>
            <ListDialog
                open={saveTemplateDialogOpen}
                mode="save"
                title="Save As Template"
                options={availableTemplateNames}
                value={selectedTemplateName}
                submitLabel="Save Template"
                onChange={handleChange}
                onSubmit={handleSave}
                onCancel={() => dispatch(closeSaveTemplateDialog(dashletId))}
                // onRemove={handleDelete}
                // onExport={handleExport}
            />
            <ListDialog
                open={loadTemplateDialogOpen}
                mode="load"
                title="Load Template"
                options={availableTemplateNames}
                value={selectedTemplateName}
                submitLabel="Load Template"
                onChange={handleChange}
                onSubmit={handleLoad}
                onCancel={() => dispatch(closeLoadTemplateDialog(dashletId))}
                // onRemove={handleDelete}
                // onExport={handleExport}
            />
            <HLCUserSessionWebSocketSubscription
                subscriptionDestination={TEMPLATES_LIST_TEMPLATE_NAMES_SUBSCRIPTION}
                dashletId={dashletId}
                handleMessage={handleListTemplateNamesResponse}
                onSubscribeCallback={() => dispatch(listSnowflakeFinOpsTemplateNames(dashletId))}
            />
            <HLCUserSessionWebSocketSubscription
                subscriptionDestination={TEMPLATES_LOAD_TEMPLATE_SUBSCRIPTION}
                dashletId={dashletId}
                handleMessage={handleLoadTemplateResponse}
            />
            <HLCUserSessionWebSocketSubscription
                subscriptionDestination={TEMPLATES_SAVE_TEMPLATE_SUBSCRIPTION}
                dashletId={dashletId}
                handleMessage={handleSaveTemplateResponse}
            />
        </>
    );
};

export default TemplateDialogs;
