import LogoutHandler from '../handlers/LogoutHandler';
import StartPage from "views/gate/Start/StartPage";
import requireAuthentication from '../containers/common/auth/AuthenticatedComponent';
import requireNoAuthentication from '../containers/common/auth/NotAuthenticatedComponent';
import * as paths from './paths';
import ChangePasswordContainer from "../views/gate/ChangePassword/ChangePasswordContainer";
import LoginContainer from "../views/gate/Auth/LoginContainer";
import RegistrationContainer from "../views/gate/Auth/RegistrationContainer";
import ForgotPasswordContainer from "../views/gate/Auth/ForgotPasswordContainer";
import ForgotPasswordFinishContainer from "../views/gate/Auth/ForgotPasswordFinishContainer";
import DashboardHomeContainer from "containers/dashboard/DashboardPageContainer";
import AddEmailConfigurationContainer from "../views/gate/AddEmailConfiguration/AddEmailConfigurationContainer";
import SubscriptionContainer from 'views/gate/Subscription/SubscriptionContainer';
import Integration from "../views/gate/Integration";
import BackendsPage from "../views/gate/BackendsPage";
import JobsPage from "../views/gate/JobsPage";
import BackendsLogsPage from "../views/gate/BackendsLogs";
import BackendCollectors from "../views/gate/BackendCollectors";
import Updates from "../views/gate/infrastructure/Updates";
import Simulation from "views/gate/infrastructure/Simulation";



const indexRoutes = [
  { path: paths.HOME_PATH, component: requireAuthentication(StartPage) },
  { path: paths.SUBSCRIPTION_PATH, component: requireAuthentication(SubscriptionContainer) },
  { path: paths.INTEGRATIONS, component: requireAuthentication(Integration) },
  { path: paths.JOBS, component: requireAuthentication(JobsPage) },
  { path: paths.BACKENDS_LOGS, component: requireAuthentication(BackendsLogsPage) },
  { path: paths.BACKENDS_COLLECTORS, component: requireAuthentication(BackendCollectors) },
  { path: paths.BACKENDS_UPDATES, component: requireAuthentication(Updates) },
  { path: paths.BACKENDS, component: requireAuthentication(BackendsPage) },
  { path: paths.INFRASTRUCTURE_SIMULATION, component: requireAuthentication(Simulation) },
  { path: paths.CHANGE_PASSWORD_PATH, component: requireAuthentication(ChangePasswordContainer) },
  { path: paths.CHANGE_EMAIL_CONFIGURATION, component: requireAuthentication(AddEmailConfigurationContainer)},
  { path: paths.LOGOUT_PATH, component: requireAuthentication(LogoutHandler) },

  { path: paths.DASHBOARD_HOME_PATH, component: requireAuthentication(DashboardHomeContainer) },

  { path: paths.REGISTRATION_PATH, component: requireNoAuthentication(RegistrationContainer) },
  { path: paths.FORGOT_PASSWORD_FINISH_PATH, component: requireNoAuthentication(ForgotPasswordFinishContainer) },
  { path: paths.FORGOT_PASSWORD_PATH, component: requireNoAuthentication(ForgotPasswordContainer) },
  //should be last
  { path: paths.LOGIN_PATH, component: requireNoAuthentication(LoginContainer) },
  ];

export default indexRoutes;