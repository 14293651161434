import React from "react";
import Grid from "@material-ui/core/Grid";
import SnowflakeDatabaseReportWarehousesSection from "../../types/model/report/SnowflakeDatabaseReportWarehousesSection";
import {formatDecimalCurrency, formatDecimalNumber, formatNumber} from "utils/FormattingUtils";
import SectionContainer from "dashlets/components/atomic/SectionContainer";
import SectionHeader from "dashlets/components/atomic/SectionHeader";
import HeaderLabel from "dashlets/components/atomic/HeaderLabel";
import HeaderValue from "dashlets/components/atomic/HeaderValue";

interface WarehousesSectionProps {
    title: string;
    entityType: string;
    data: SnowflakeDatabaseReportWarehousesSection;
}

const WarehousesSection: React.FC<WarehousesSectionProps> = ({
    title,
    entityType,
    data
                                                             }) => (
    <SectionContainer>
        <SectionHeader>{title}</SectionHeader>
        <Grid container spacing={1}>
            <Grid item xs={2}><HeaderLabel>{`${entityType}s`}</HeaderLabel></Grid>
            <Grid item xs={2}><HeaderLabel>Credits Consumed</HeaderLabel></Grid>
            <Grid item xs={2}><HeaderLabel>Compute Cost</HeaderLabel></Grid>
            <Grid item xs={2}><HeaderLabel>Avg. Daily Credits Used</HeaderLabel></Grid>
            <Grid item xs={2}><HeaderLabel>Avg. Daily Cost</HeaderLabel></Grid>
            <Grid item xs={2}><HeaderLabel>Remote Spillage (GB)</HeaderLabel></Grid>
            <Grid item xs={2}><HeaderValue>{formatNumber(data?.warehouses)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalNumber(data?.creditsConsumed)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalCurrency(data?.computeCost)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalNumber(data?.avgDailyCreditsUsed)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalCurrency(data?.avgDailyCost)}</HeaderValue></Grid>
            <Grid item xs={2}><HeaderValue>{formatDecimalNumber(data?.remoteSpillageGb)}</HeaderValue></Grid>
        </Grid>
    </SectionContainer>
);

export default WarehousesSection;
