import React from "react";
import Collector from "../../../types/model/infrastructure/Collector";
import { makeStyles } from "@material-ui/core/styles";
import Paper from "@material-ui/core/Paper";
import TableContainer from "@material-ui/core/TableContainer";
import { Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import PauseIcon from "@material-ui/icons/Pause";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { Pause16Filled, Play16Filled } from "@fluentui/react-icons";

interface BackendCollectorsTableProps {
    rows: Collector[];
    startAction: (targetId: number) => void;
    stopAction: (targetId: number) => void;
}

const useStyles = makeStyles({
    root: {
        width: '97%',
        marginLeft: '2.5%',
        borderRadius: '8px',
        overflow: 'hidden',
        boxShadow: '0px 2px 8px 0px #00000024',
    },
    container: {
        // maxHeight: 600,
    },
    rows: {
        '&:hover': {
            '& svg': {
                color: "white"
            },
        }
    },
},
);

const BackendCollectorsTable: React.FC<BackendCollectorsTableProps> = ({
                                                                           rows,
                                                                           startAction,
                                                                           stopAction
                                                                       }) => {
    const classes = useStyles();

    return (
        <Paper className={classes.root}>
            <TableContainer className={classes.container}>
                <Table stickyHeader size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>Status</TableCell>
                            <TableCell>Target</TableCell>
                            <TableCell align="center">Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row: Collector) => {
                            return (
                                <TableRow hover key={row.id} className={classes.rows}>
                                    <TableCell>{row.status}</TableCell>
                                    <TableCell>{row.targetName}</TableCell>
                                    <TableCell align="center">
                                    <Tooltip title="Pause" arrow>
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            disabled={row.status === "DOWN"}
                                            onClick={() => stopAction(row.id)}
                                        >
                                            <Pause16Filled fontSize="small"/>
                                        </IconButton>
                                    </Tooltip>
                                    <Tooltip title="Start" arrow>
                                        <IconButton
                                            color="primary"
                                            size="small"
                                            disabled={!(row.status === "DOWN")}
                                            onClick={() => startAction(row.id)}
                                        >
                                            <Play16Filled fontSize="small"/>
                                        </IconButton>
                                    </Tooltip>
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
};

export default BackendCollectorsTable;
