import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";

interface IntegrationSaveButtonRowProps {
    onSave: () => void;
}

const IntegrationSaveButtonRow: React.FC<IntegrationSaveButtonRowProps> = ({ onSave }) => (
    <Grid
        item
        container
        spacing={2}
        xs={6}
        direction="row"
        justifyContent="flex-end"
        alignItems="center">
        <Grid item xs={1}>
            <Button onClick={onSave} variant="contained">Save</Button>
        </Grid>
    </Grid>
);

export default IntegrationSaveButtonRow;
