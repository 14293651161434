import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Paper from "@material-ui/core/Paper";
import FormControl from "@material-ui/core/FormControl";
import {ForgotPasswordLink, RegistrationLink} from "../../../routes/links";
import {Container} from "@material-ui/core";
import {AuthFormInput, AuthLinkBordered, AuthLogo, AuthSubmitButton} from "../../../components/gate/Auth/Auth";
import ErrorMessage from "../../../components/gate/Auth/ErrorMessage";
import AuthFormHeader from "../../../components/gate/Auth/AuthFormHeader";
import AuthLink from "../../../components/gate/Auth/AuthLink";
import BottomHint from "../../../components/gate/Auth/BottomHint";
import {authFormStyles} from "../../../jss/gate/Auth/authStyles";
import { HLC_WEB_SOCKET_URL, IS_HLC_WS_ENABLED, IS_PEI_WS_ENABLED, PEI_WEB_SOCKET_URL } from 'config';
import StompClient from 'websocket/StompClient';
import { STOMP_CLIENT } from 'reducers/webSocketSlice';
import PEIChartBagWebSocketSubscription from 'dashlets/PEIChartBagDashlet/components/PEIChartBagWebSocketSubscription';

class Login extends Component {
  render() {
    const {classes, errorMessage, disabled, onLogin, onChangeInputHandler} = this.props;

    return (
      <Container id="login-container" className={classes.container}>
        <main className={classes.main}>
          <Paper className={classes.paper}>
            <AuthLogo />
            <AuthFormHeader title="Sign in" />

            <ErrorMessage message={errorMessage} />

            <form className={classes.form}>
              <FormControl margin="normal" required fullWidth className={classes.formControl}>
                <AuthFormInput id="username" name="username" autoComplete="email" autoFocus onChange={onChangeInputHandler} disabled={disabled} placeholder="E-mail"/>
              </FormControl>
              <FormControl margin="normal" required fullWidth className={classes.formControl}>
                <AuthFormInput name="password" type="password" id="password" autoComplete="current-password" onChange={onChangeInputHandler} disabled={disabled} placeholder="Password"/>
              </FormControl>

              <AuthSubmitButton
                type="submit"
                fullWidth
                // variant="contained"
                onClick={onLogin}
                disabled={disabled}
              >
                Login
              </AuthSubmitButton>
            </form>

            <Container className={classes.linksContainer}>
              <AuthLinkBordered label="Forgot Password?" actionComponent={ForgotPasswordLink}/>
              <AuthLink label="Create new account" actionComponent={RegistrationLink}/>
            </Container>

            <BottomHint />

          </Paper>
        </main>
        {IS_PEI_WS_ENABLED ? [
          <StompClient
            key="hlc-ws-stomp-client-1582840873831"
            stompClientKey={STOMP_CLIENT.PEI}
            sockJsEndpoint={PEI_WEB_SOCKET_URL}
            debugMode
          />,
          <PEIChartBagWebSocketSubscription />
        ] : null}
        {IS_HLC_WS_ENABLED ? [
          <StompClient
            key="pei-ws-stomp-client-1582840873831"
            stompClientKey={STOMP_CLIENT.HLC}
            sockJsEndpoint={HLC_WEB_SOCKET_URL}
            debugMode
          />,
        ] : null}
      </Container>

    );
  }
}

Login.propTypes = {
  //data
  classes: PropTypes.object,
  errorMessage: PropTypes.string,
  disabled: PropTypes.bool,

  //func
  onLogin: PropTypes.func.isRequired,
  onChangeInputHandler: PropTypes.func.isRequired,
};

export default withStyles(authFormStyles)(Login);