import {includes as _includes} from "lodash";
import * as webSocketClient from './webSocketClient';
import * as templatesWebSocketClient from 'api/websocket/hlc/templatesWebSocketClient';
import {putInDashletStorage, selectDashletStorageValue} from "reducers/dashboard/dashletsStorageSlice";
import {selectDashlet, selectDashletBackendKey} from "reducers/dashboard/dashboardSlice";
import {
    AVAILABLE_TEMPLATE_NAMES,
    LOAD_TEMPLATE_DIALOG_OPEN,
    SAVE_TEMPLATE_DIALOG_OPEN,
    SELECTED_TEMPLATE_NAME
} from "./dashletStorageKeys";
import SnowflakeFinOpsReportParams from "../../types/model/finops/snowflake/SnowflakeFinOpsReportParams";
import SnowflakeFinOpsTemplate from "../../types/model/templates/finops/SnowflakeFinOpsTemplate";
import TemplateType from "../../types/model/templates/TemplateType";
import SnowflakeFinOpsTimeRange from "./types/model/SnowflakeFinOpsTimeRange";
import SnowflakeFinOpsSettings from "./types/model/SnowflakeFinOpsSettings";
import {updateDashletTimeRangeAndSettings} from "actions/dashboard/dashletActions";

const getDefaultTimeRangeSettings = (): SnowflakeFinOpsTimeRange => {
    return {
        timeUnitsCount: 3,
        timeUnit: "month"
    }
};

const getDefaultSettings = (): SnowflakeFinOpsSettings => {
    return {
        grouping: "Warehouse"
    }
};

export const refresh = (dashlet: any) => (dispatch: any, getState: any) => {
    const {backendKey, targetName} = dashlet.config;
    const {id, timeRange, settings} = dashlet;

    console.log('===> SnowflakeReportDashlet refresh() ');
    console.log(`  backendKey:[${backendKey}], targetName:[${targetName}]`);
    console.log('  settings:', settings);
    console.log('  timeRange', timeRange);
    webSocketClient.generateSnowflakeDatabaseReport(getState(), backendKey, {
        dashletId: id,
        targetName: targetName,
        reportParams: {
            ...timeRange,
            ...settings,
        },
    });
};

export const executeAssistant = (
    backendKey: string,
    dashletId: number,
    targetName: string,
    entityName: string,
    entityType: string,
    assistanceType: string,
    reportParams: SnowflakeFinOpsReportParams,
) => (dispatch: any, getState: any) => {
    webSocketClient.executeSnowflakeFinOpsReportAssistant(getState(), backendKey, {
        dashletId,
        targetName,
        entityName,
        entityType,
        assistanceType,
        reportParams
    });
};

export const validateConfig = (dashletConfig: any) => {
    const errors = {};

    console.debug('===> SnowflakeReportDashlet validateConfig() ');

    return errors;
};

export const buildDashletObject = (dashletData: any, configOptions: any) => {
    const dashletTimeRange = dashletData?.timeRange && Object.keys(dashletData.timeRange).length > 0
        ? dashletData.timeRange
        : getDefaultTimeRangeSettings();
    const dashletSettings = dashletData?.settings && Object.keys(dashletData.settings).length > 0
        ? dashletData.settings
        : getDefaultSettings();

    return {
        ...dashletData,
        timeRange: dashletTimeRange,
        settings: dashletSettings,
        backendKey: dashletData.config.backendKey
    }
};

export const selectSelectedTemplateName = (state: any, dashletId: number) =>
    selectDashletStorageValue(state, dashletId, SELECTED_TEMPLATE_NAME);

export const setSelectedTemplateName = (dashletId: number, selectedTemplateName: string | null) => (dispatch: any) =>
    dispatch(putInDashletStorage(dashletId, SELECTED_TEMPLATE_NAME, selectedTemplateName));

export const selectAvailableTemplateNames = (state: any, dashletId: number) =>
    selectDashletStorageValue(state, dashletId, AVAILABLE_TEMPLATE_NAMES) || [];

export const setAvailableTemplateNames = (dashletId: number, availableTemplateNames: string[]) => (dispatch: any) =>
    dispatch(putInDashletStorage(dashletId, AVAILABLE_TEMPLATE_NAMES, availableTemplateNames));

export const addAvailableTemplateName = (dashletId: number, templateName: string) => (dispatch: any, getState: any) => {
    const prevAvailableTemplateNames = selectAvailableTemplateNames(getState(), dashletId);
    if (!_includes(prevAvailableTemplateNames, templateName)) {
        dispatch(putInDashletStorage(dashletId, AVAILABLE_TEMPLATE_NAMES, prevAvailableTemplateNames.concat(templateName)));
    }
}

export const selectSaveTemplateDialogOpen = (state: any, dashletId: number) =>
    selectDashletStorageValue(state, dashletId, SAVE_TEMPLATE_DIALOG_OPEN);

export const openSaveTemplateDialog = (dashletId: number) => (dispatch: any) =>
    dispatch(putInDashletStorage(dashletId, SAVE_TEMPLATE_DIALOG_OPEN, true));

export const closeSaveTemplateDialog = (dashletId: number) => (dispatch: any) =>
    dispatch(putInDashletStorage(dashletId, SAVE_TEMPLATE_DIALOG_OPEN, false));

export const listSnowflakeFinOpsTemplateNames = (dashletId: number) => (dispatch: any, getState: any) => {
    const appState = getState();
    const backendKey = selectDashletBackendKey(appState, dashletId);

    templatesWebSocketClient.listTemplateNames(appState, backendKey, {
        dashletId,
        templateType: TemplateType.SNOWFLAKE_FINOPS_TEMPLATE
    })
}

export const saveSnowflakeFinOpsTemplate = (dashletId: number, templateName: string) => (dispatch: any, getState: any) => {
    const appState = getState();
    const dashlet = selectDashlet(appState, dashletId);
    const backendKey = dashlet.config.backendKey;
    const targetName = dashlet.config.targetName;
    const reportParams = {
        ...dashlet.timeRange,
        ...dashlet.settings,
    }

    const template: SnowflakeFinOpsTemplate = {
        templateType: TemplateType.SNOWFLAKE_FINOPS_TEMPLATE,
        templateName,
        targetName,
        reportParams,
    };

    templatesWebSocketClient.saveTemplate(appState, backendKey, {
        dashletId,
        templateName,
        template
    });
}

export const selectLoadTemplateDialogOpen = (state: any, dashletId: number) =>
    selectDashletStorageValue(state, dashletId, LOAD_TEMPLATE_DIALOG_OPEN);

export const openLoadTemplateDialog = (dashletId: number) => (dispatch: any) =>
    dispatch(putInDashletStorage(dashletId, LOAD_TEMPLATE_DIALOG_OPEN, true));

export const closeLoadTemplateDialog = (dashletId: number) => (dispatch: any) =>
    dispatch(putInDashletStorage(dashletId, LOAD_TEMPLATE_DIALOG_OPEN, false));

export const loadSnowflakeFinOpsTemplate = (dashletId: number, templateName: string) => (dispatch: any, getState: any) => {
    const appState = getState();
    const dashlet = selectDashlet(appState, dashletId);
    const backendKey = dashlet.config.backendKey;

    templatesWebSocketClient.loadTemplate(appState, backendKey, {
        dashletId,
        templateName,
        templateExecution: false,
        startTime: null,
        endTime: null
    })
}

export const applySnowflakeTemplate = (dashletId: number, template: SnowflakeFinOpsTemplate) => (dispatch: any) => {
    const timeRange: SnowflakeFinOpsTimeRange = {
        timeUnitsCount: template.reportParams.timeUnitsCount,
        timeUnit: template.reportParams.timeUnit,
    };

    const settings: SnowflakeFinOpsSettings = {
        grouping: template.reportParams.grouping,
    };

    dispatch(updateDashletTimeRangeAndSettings(dashletId, timeRange, settings));
}
