import React from "react";
import { Box, Grid, List, ListItem, ListItemSecondaryAction, ListItemText } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import GetAppIcon from '@material-ui/icons/GetApp';
import Tooltip from "@material-ui/core/Tooltip";
import IconButton from "@material-ui/core/IconButton";
import UpdatesRestClient from "../../../../api/rest/UpdatesRestClient";
import { ArrowDownload20Filled } from "@fluentui/react-icons";

const UpdatesList: React.FC = () => {

    const handleDownloadFile = (fileName: string) => {
        window.open(UpdatesRestClient.createDownloadFileLink(fileName), "_blank");
    }
    const boxShadow = {
        boxShadow: '0px 1px 0px 0px #00000024'
    }
    return (
        <>
            <Grid container alignItems="center" justifyContent="space-between" style={{ height: "48px" }}>
                <Grid item xs>
                    <Typography variant="h6" id="tableTitle" component="div" style={{ paddingLeft: "16px", textAlign: "left" }}>
                        Updates
                    </Typography>
                </Grid>
            </Grid>
            <Box style={{
                borderRadius: "8px", 
                margin: "16px",
                width: "98%",
                boxShadow: '0px 2px 8px 0px #00000024',
            }}>
                <List dense disablePadding>
                    <ListItem button style={boxShadow}>
                        <ListItemText primary="UpBeat Database Backend/hlc-db-backend.jar"/>
                        <ListItemSecondaryAction>
                            <Tooltip title="Download">
                                <IconButton color="primary" size="small" onClick={() => handleDownloadFile("hlc-db-backend.jar")}>
                                    <ArrowDownload20Filled fontSize="small" />
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem button style={boxShadow}>
                        <ListItemText primary="UpBeat Database Active Data Collector/DBActDC.jar"/>
                        <ListItemSecondaryAction>
                            <Tooltip title="Download">
                                <IconButton color="primary" size="small" onClick={() => handleDownloadFile("DBActDC.jar")}>
                                    <ArrowDownload20Filled fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                    <ListItem button >
                        <ListItemText primary="UpBeat Database Active Data Collector SQL Server 2005/DBActDC.jar"/>
                        <ListItemSecondaryAction>
                            <Tooltip title="Download">
                                <IconButton color="primary" size="small" onClick={() => handleDownloadFile("DBActDC-SQL2005.jar")}>
                                    <ArrowDownload20Filled fontSize="small"/>
                                </IconButton>
                            </Tooltip>
                        </ListItemSecondaryAction>
                    </ListItem>
                </List>
            </Box>
        </>
    );
}

export default UpdatesList;
