import React, { useState } from "react";
import {keys as _keys} from "lodash";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "components/common/Switch/Switch";
import useIntegrationSectionState from "hooks/integration/useIntegrationSectionState";
import GenAIIntegration from "types/model/settings/integration/GenAIIntegration";
import Select from "components/common/Select/Select";
import {MenuItem} from "@material-ui/core";
import ErrorMessages from "./ErrorMessages.types";
import OpenAIGenAIIntegrationConfig from "./OpenAIGenAIIntegrationConfig";
import CustomGenAIIntegrationConfig from "./GenericGenAIIntegrationConfig";
import GenericGenAIIntegrationConfig from "./GenericGenAIIntegrationConfig";
import Button from "@material-ui/core/Button";

interface GenAIIntegrationsProps {
    integration: GenAIIntegration;
    onSave: (integration: GenAIIntegration) => void;
}

const GEN_AI_TYPES = ["OpenAI", "Custom"];

const GEN_AI_CONFIG_COMPONENTS: Record<string, any> = {
    "OpenAI": OpenAIGenAIIntegrationConfig,
    "Custom": CustomGenAIIntegrationConfig,
}

const GenAiIntegrationSection: React.FC<GenAIIntegrationsProps> = ({
    onSave,
    ...props
}) => {
    const { integration, handleChangeProperty, handleEnabledToggle } = useIntegrationSectionState(props.integration);
    const { enabled, genAIType, openAIApiKey, openAIModel } = integration as GenAIIntegration;
    const [errors, setErrors] = useState<ErrorMessages>({});

    const handleSave = () => {
        const newErrors: ErrorMessages = {};
        if (enabled) {
            if (!openAIApiKey) {
                newErrors.openAIApiKey = "OpenAI API key cannot be empty.";
            }
            if (!openAIModel) {
                newErrors.openAIModel = "OpenAI model name cannot be empty.";
            }
        }
        if (_keys(newErrors).length > 0) {
            setErrors(newErrors);
        } else {
            setErrors({});
            onSave(integration as GenAIIntegration);
        }
    };

    // @ts-ignore
    let ConfigComponent: any = GEN_AI_CONFIG_COMPONENTS[genAIType];
    if (!ConfigComponent) {
        ConfigComponent = GenericGenAIIntegrationConfig;
    }

    return (
        <Grid container spacing={2} direction="column" justifyContent="flex-start" style={{ marginTop: "8px", marginBottom: "8px" }}>
            <Grid
                item
                container
                spacing={2}
                xs={12}
                direction="row"
                justifyContent="flex-start"
                alignItems="center">
                <Grid item xs={2} style={{ textAlign: "left" }}>
                    <InputLabel htmlFor="enable">Enabled</InputLabel>
                </Grid>
                <Grid item xs={5}>
                    <Switch checked={enabled || false}
                        onChange={handleEnabledToggle}
                        name="enabled"
                    />
                </Grid>
            </Grid>
            <Grid
                item
                container
                spacing={2}
                xs={12}
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                style={{ marginTop: '5px' }}>
                <Grid item xs={4} style={{ textAlign: "left" }}>
                    <InputLabel htmlFor="algorithmName">Generative AI</InputLabel>
                </Grid>
                <Grid item xs={2}>
                    <Select
                        disabled={!enabled}
                        name="genAIType"
                        value={genAIType || GEN_AI_TYPES[0]}
                        onChange={(event:any) => handleChangeProperty(event.target.name, event.target.value)}
                    >
                        {GEN_AI_TYPES.map((type) => (
                            <MenuItem key={type} value={type}>
                                {type}
                            </MenuItem>
                        ))}
                    </Select>
                </Grid>
            </Grid>

            <ConfigComponent
                integration={integration}
                handleChangeProperty={handleChangeProperty}
                errors={errors}
            />
            
            <Grid
                item
                container
                spacing={2}
                xs={7}
                direction="row"
                justifyContent="flex-end"
                alignItems="center">
                <Grid item xs={1}>
                    <Button onClick={handleSave} variant="contained">Save</Button>
                </Grid>
            </Grid>
            
        </Grid>
    );
};

export default GenAiIntegrationSection;
