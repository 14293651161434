import {publish} from 'api/websocket/hlc/hlcCommonWebSocketClient';
import {
    WEB_SOCKET_ACTION_PREFIX,
    WEB_SOCKET_SUBSCR_PREFIX
} from "constants/webSocketConstants";
import LoadFinancialDataStompFrame from "./types/payload/LoadFinancialDataStompFrame";
import GenerateFinOpsReportStompFrame from "./types/payload/GenerateFinOpsReportStompFrame";

// Load target names
export const HEATMAP_LIST_TARGET_NAMES_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.dashlet.heatmap.target.names.list.load`;
export const HEATMAP_LIST_TARGET_NAMES_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.dashlet.heatmap.target.names.list`;

export const listTargetNames = (appState: any, backendKey: string) =>
    publish(appState, backendKey, HEATMAP_LIST_TARGET_NAMES_ACTION, {
        empty: true
    });

const FINOPS_LOAD_DATA_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.hlc.dashlet.finops.load`;
export const FINOPS_LOAD_DATA_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.hlc.dashlet.finops`;

export const loadFinancialData = (appState: any, backendKey: string, payload: LoadFinancialDataStompFrame) =>
    publish(appState, backendKey, FINOPS_LOAD_DATA_ACTION, payload)

const FINOPS_GENERATE_REGULAR_REPORT_ACTION = `${WEB_SOCKET_ACTION_PREFIX}/ui.dashboard.finops.report.regular.generate`;
export const FINOPS_GENERATE_REGULAR_REPORT_SUBSCRIPTION = `${WEB_SOCKET_SUBSCR_PREFIX}/ui.dashboard.finops.report.regular`;

export const generateFinOpsReport = (appState: any, backendKey: string, payload: GenerateFinOpsReportStompFrame) =>
    publish(appState, backendKey, FINOPS_GENERATE_REGULAR_REPORT_ACTION, payload);
