import React from "react";
import ApplicationPage from "components/common/ApplicationPage/ApplicationPage";
import InfrastructureLeftNavBar from "components/gate/InfrastructureLeftNavBar";
import SimulationContent from "./SimulationContent";

const Simulation: React.FC = ({}) => (
    <ApplicationPage
        leftNavBarComponent={<InfrastructureLeftNavBar />}
        contentComponent={<SimulationContent />}
    />
)

export default Simulation;
