import React from "react";
import Grid from "@material-ui/core/Grid";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import SnowflakeFinOpsReportConsumption from "../../types/model/report/SnowflakeFinOpsReportConsumption";
import SectionContainer from "dashlets/components/atomic/SectionContainer";
import SectionHeader from "dashlets/components/atomic/SectionHeader";

interface ConsumptionSectionProps {
    title?: string;
    data: SnowflakeFinOpsReportConsumption;
}

const ConsumptionSection: React.FC<ConsumptionSectionProps> = ({
    title,
    data
                                      }) => {
    const buildBarChartOptions = (series: any[], categories: string[], yAxisTitle: string) => {
        return {
            chart: {
                type: 'column',
                height: 100,
                style: {
                    // fontFamily: 'Roboto'
                        fontFamily: 'Segoe UI'
                },
            },

            title: {
                text: ''
            },

            // legend: {
            //     enabled: true, // Enable legend
            //     align: 'right',
            //     verticalAlign: 'middle',
            //     layout: 'vertical',
            //     itemStyle: {
            //         fontSize: '8px',
            //         fontFamily: 'Roboto'
            //     }
            // },

            legend: {
                enabled: false
            },

            credits: {
                enabled: false
            },

            xAxis: {
                categories: categories
            },

            yAxis: {
                allowDecimals: false,
                min: 0,
                gridLineWidth: 0,
                title: {
                    text: yAxisTitle,
                    style: {
                        // fontFamily: 'Roboto',
                            fontFamily: 'Segoe UI',
                            fontWeight: 400,
                            fontSize: "8px"
                    },
                },
                stackLabels: {
                    enabled: true,
                    style: {
                        // fontFamily: 'Roboto',
                            fontFamily: 'Segoe UI',
                            fontWeight: 400,
                            fontSize: "8px"
                    },
                }
            },

            tooltip: {
                format: '<b>{key}</b><br/>{series.name}: {y}<br/>'
            },

            plotOptions: {
                column: {
                    stacking: 'normal'
                }
            },

            responsive: {
                rules: [{
                    condition: {
                        maxWidth: 400
                    },
                    chartOptions: {
                        xAxis: {
                            labels: {
                                enabled: false
                            }
                        }
                    }
                }]
            },

            series: series,
        };
    }


    return (
        <SectionContainer>
            {title && <SectionHeader>{title}</SectionHeader>}
            <Grid container spacing={1}>
                <Grid item xs={12} sm={6}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={buildBarChartOptions(data?.creditsUsedBarChartSeries, data?.barChartsCategories, "Credits Used")}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={buildBarChartOptions(data?.creditsCostsBarChartSeries, data?.barChartsCategories, "Credits Costs")}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={buildBarChartOptions(data?.numberOfQueriesBarChartSeries, data?.barChartsCategories, "Num. of queries")}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={buildBarChartOptions(data?.totalExecutionTimeBarChartSeries, data?.barChartsCategories, "Exec. time (ms)")}
                    />
                </Grid>
            </Grid>
        </SectionContainer>
    );
}

export default ConsumptionSection;
