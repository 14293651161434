import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {selectDashletSettings} from "reducers/dashboard/dashboardSlice";
import {updateDashletSettings} from "actions/dashboard/dashletActions";
import Paper from "@material-ui/core/Paper";
import {Theme, WithStyles} from "@material-ui/core";
import TimeRangeTabPanel from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTabPanel";
import Divider from "@material-ui/core/Divider";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import {withStyles} from "@material-ui/core/styles";
import TextField from "components/common/TextField/TextField";
import AppBar from "@material-ui/core/AppBar";
import TimeRangeTabs from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTabs";
import TimeRangeTab from "components/dashboard/dashlets/DashletTimeRange/TimeRangeTab";
import Button from "@material-ui/core/Button";

const styles = (theme: Theme) => ({
    root: {},
    appBar: {
        backgroundColor: '#fff',
        boxShadow: 'none',
    },
    formLabel: {
        padding: '0.4rem 0rem',
    },
    formControl: {
        margin: theme.spacing(1),
    },
    formGroupRow: {
      justifyContent: "space-between",
    },
    formControlLabel: {
        margin: theme.spacing(1),
        minWidth: '40px'
    },
    formControlInput: {
        margin: theme.spacing(1),
        minWidth: '60px',
        width: '60px',
    },
    formControlSelect: {
        margin: theme.spacing(1),
        minWidth: '120px',
    },
    formControlFullWidthSelect: {
        margin: theme.spacing(1),
        width: '192px',
        minWidth: '160px',
    },
    formControlDatetime: {
        margin: theme.spacing(1),
        width: '192px',
        minWidth: '160px',
    },
    footer: {
        display: 'flex',
        '& > *': {
            margin: theme.spacing(1),
        }
    },
    grow: {
        flexGrow: 1,
    },
    switchRoot: {
        marginLeft: 0,
    },
    switchLabel: {
        marginLeft: 8,
    },
});

interface FinOpsDashletSettingsProps extends WithStyles<typeof styles> {
    dashletId: number;
    backendKey: string;
    close: () => void;
}

const FinOpsDashletSettings: React.FC<FinOpsDashletSettingsProps> = ({
                                                                                           classes,
                                                                                           dashletId,
                                                                                           backendKey,
                                                                                           close
                                                                                       }) => {
    const dispatch = useDispatch();
    const initialSettings = useSelector(state => selectDashletSettings(state, dashletId));
    const [settings, setSettings] = React.useState(initialSettings);

    const { topSqlCount, sqlCostDeltaPctThreshold } = settings;

    const changeProperty = (name: string, value: any) => setSettings({
        ...settings,
        [name]: value
    });

    const handleChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        changeProperty(event.target.name, event.target.value);
    };

    const handleCancel = () => close();

    const handleSubmit = () => {
        dispatch(updateDashletSettings(dashletId, settings));
        close();
    }

    return (
        <>
            <Paper className={classes.root}>
                <AppBar position="relative" className={classes.appBar} component="div">
                    {/* @ts-ignore */}
                    <TimeRangeTabs value="Settings">
                        <TimeRangeTab
                            label="Settings"
                            value="Settings"
                        />
                    </TimeRangeTabs>
                </AppBar>
                {/* @ts-ignore */}
                <TimeRangeTabPanel>
                    <FormGroup row className={classes.formGroupRow}>
                        <FormControl className={classes.formControlLabel}>
                            <Typography component="div" className={classes.formLabel}>
                                Top SQL
                            </Typography>
                        </FormControl>
                        <FormControl className={classes.formControlInput}>
                            <TextField
                                name="topSqlCount"
                                onChange={handleChangeInput}
                                fullWidth
                                value={topSqlCount}
                                inputProps={{
                                    step: 1,
                                    min: 1,
                                    max: 100,
                                    type: 'number',
                                }}
                            />
                        </FormControl>
                    </FormGroup>
                    <FormGroup row className={classes.formGroupRow}>
                        <FormControl className={classes.formControlLabel}>
                            <Typography component="div" className={classes.formLabel}>
                                SQL Cost Delta Pct. Threshold
                            </Typography>
                        </FormControl>
                        <FormControl className={classes.formControlInput}>
                            <TextField
                                name="sqlCostDeltaPctThreshold"
                                onChange={handleChangeInput}
                                fullWidth
                                value={sqlCostDeltaPctThreshold}
                                inputProps={{
                                    step: 0.1,
                                    min: 1,
                                    max: 120,
                                    type: 'number',
                                }}
                            />
                        </FormControl>
                    </FormGroup>
                </TimeRangeTabPanel>

                <Divider />

                <div className={classes.footer}>
                    <div className={classes.grow}/>
                    <Button onClick={handleCancel}>Cancel</Button>
                    <Button onClick={handleSubmit} variant="contained">Apply</Button>
                </div>
            </Paper>
        </>
    );
};

export default withStyles(styles)(FinOpsDashletSettings);
