import ListItem from "@material-ui/core/ListItem";
import React, {Component} from 'react';
import PropTypes from "prop-types";
import {withStyles} from '@material-ui/core/styles';

const styles = theme => ({
  root: {
    "&:hover": {
      backgroundColor: '#fff',
      color:"#0F6CBD",
      '& svg': { 
      color: "#0F6CBD"
      }
    },
  },
});

class LeftNavBarListItem extends Component {
  render() {
    const {classes} = this.props;
    return (
        <ListItem
            className={classes.root}
            button
            disableRipple
            disableTouchRipple
            // disableFocusRipple
            {...this.props}
        >
          {this.props.children}
        </ListItem>
    );
  }
}

LeftNavBarListItem.propTypes = {
  //data
  classes: PropTypes.object.isRequired,

  //func
  onClick: PropTypes.func,
};

export default withStyles(styles)(LeftNavBarListItem);