import React from "react";
import Integration from "types/model/settings/integration/Integration";
import IntegrationType from "types/model/settings/integration/IntegrationType";
import IntegrationSection from "../IntegrationSection";
import AWSIntegration from "types/model/settings/integration/cloud/AWSIntegration";
import AWSIntegrationSection from "./sections/AWSIntegrationSection";
import AzureIntegration from "types/model/settings/integration/cloud/AzureIntegration";
import AzureIntegrationSection from "./sections/AzureIntegrationSection";

interface CloudIntegrationsProps {
    integrations: Record<string, Integration>;
    onSaveIntegration: (integration: Integration) => void;
}


const CloudIntegrations: React.FC<CloudIntegrationsProps> = ({
                                                                 integrations,
                                                                 onSaveIntegration,
                                                             }) => (
    <>
        {integrations[IntegrationType.AWS] && (
            <IntegrationSection
                title={integrations[IntegrationType.AWS].title}
                content={
                    <AWSIntegrationSection
                        integration={
                            integrations[IntegrationType.AWS] as AWSIntegration
                        }
                        onSave={onSaveIntegration}
                    />
                }
            />
        )}
        {integrations[IntegrationType.AZURE] && (
            <IntegrationSection
                title={integrations[IntegrationType.AZURE].title}
                content={
                    <AzureIntegrationSection
                        integration={
                            integrations[IntegrationType.AZURE] as AzureIntegration
                        }
                        onSave={onSaveIntegration}
                    />
                }
            />
        )}
    </>
);

export default CloudIntegrations;
