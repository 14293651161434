import LeftNavBar from "components/common/LeftNavBar/LeftNavBar";
import LeftNavBarListItem from "components/common/LeftNavBar/LeftNavBarListItem";
import LeftNavBarListItemText from "components/common/LeftNavBar/LeftNavBarListItemText";
import OpenInNewIcon from "components/common/OpenInNewIcon/OpenInNewIcon";
import {WRITE_TO_SUPPORT_URL, HELP_DOCS_URL, VIDEO_GUIDES_URL, REFER_URL, DASHBOARD_URL} from "config";
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import HelpIcon from '@material-ui/icons/Help'; 
import DocsIcon from '@material-ui/icons/Description'; 
import VideoIcon from '@material-ui/icons/VideoLibrary'; 
import GiftIcon from '@material-ui/icons/CardGiftcard'; 
import { ChatHelp20Filled, DocumentBulletListMultiple20Filled, GiftCard20Filled, Open20Regular, Video20Filled } from "@fluentui/react-icons";

const styles = theme => ({
  root: {
  },
  bottomOptions: {
    marginTop: 'auto',
  },
  icon: {
    color: '#606060',
    fontSize: '1em',
    marginRight: '10px'
  },
});

class StartPageLeftNavBar extends Component
{
  componentDidMount() {
    // First, check if jQuery is already loaded or not
    if (!window.jQuery) {
      const jqueryScript = document.createElement('script');
      jqueryScript.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js";
      jqueryScript.onload = () => {
        // Once jQuery is loaded, load the JIRA Issue Collector script
        this.loadJiraCollectorScript();
      };
      jqueryScript.onerror = () => {
        console.error("Failed to load jQuery");
      };
      console.log("Failed to load jQuery");
      document.body.appendChild(jqueryScript);
    } else {
      // If jQuery is already present, directly load the JIRA script
      this.loadJiraCollectorScript();
    }
  }

  loadJiraCollectorScript() {
    const script = document.createElement('script');
    script.src = "https://enteros.atlassian.net/s/d41d8cd98f00b204e9800998ecf8427e-T/xtxyma/b/7/b0105d975e9e59f24a3230a22972a71a/_/download/batch/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs/com.atlassian.jira.collector.plugin.jira-issue-collector-plugin:issuecollector-embededjs.js?locale=en-US&collectorId=2f5f3290";
    script.async = true;
    script.onload = () => {
      console.log("JIRA Issue Collector script loaded successfully");
    };
    script.onerror = () => {
      console.error("Failed to load the JIRA Issue Collector script");
    };
    document.body.appendChild(script);
    this.script = script;
  }
  

  componentWillUnmount() {
    if (this.script) {
      document.body.removeChild(this.script);
      this.script = null;
    }
  }

  triggerIssueCollector = () => {
    // Assuming ATL_JQ_PAGE_PROPS is available globally after including the script
    console.log("Calling triggerIssueCollector");
    const supportLink = document.getElementById("atlwdg-trigger");
    if (supportLink) {
      supportLink.click();
    }
    

  // if (window.ATL_JQ_PAGE_PROPS) {
  //     console.log("Calling triggerIssueCollector #2");
  //     window.ATL_JQ_PAGE_PROPS.trigger({
  //       triggerFunction: function(showCollectorDialog) {
  //         showCollectorDialog();
  //       }
  //     });
  //   }
  }

  openDashboard = () => {
    window.open(DASHBOARD_URL, "_blank", 'noopener,noreferrer');
  };

  redirectToLink = (link) => {
    window.open(link, '_blank');
  };

  render() {
    const {classes, isLeftMenuOpened, setLeftMenuOpened, startPageApplication, changeStartApplication, routeToDashboard} = this.props;

    return (
      <LeftNavBar
        title="Home"
        open={isLeftMenuOpened}
        setOpen={setLeftMenuOpened}
      >
        <div>
          <LeftNavBarListItem
            onClick={this.openDashboard}
            className={classes.listItem}
          >
            <LeftNavBarListItemText primary="Dashboard" />
            <Open20Regular className={classes.icon}/>
            {/* <OpenInNewIcon className={classes.icon} /> */}
          </LeftNavBarListItem>
          <LeftNavBarListItem
            onClick={() => changeStartApplication("PEI")}
            className={classes.listItem}
          >
            <LeftNavBarListItemText
              primary="Performance Explorer"
              active={startPageApplication === "PEI"}
            />
          </LeftNavBarListItem>
          <LeftNavBarListItem
            onClick={() => changeStartApplication("HLC")}
            className={classes.listItem}
          >
            <LeftNavBarListItemText
              primary="High Load Capture"
              active={startPageApplication === "HLC"}
            />
          </LeftNavBarListItem>
        </div>

        <div style={{ position: "absolute", bottom: "10vh" }}>
          <LeftNavBarListItem>
            <LeftNavBarListItemText primary="Help & Support" />
          </LeftNavBarListItem>
          <div style={{ marginLeft: "10px" }}>
            <LeftNavBarListItem>
              <ChatHelp20Filled className={classes.icon} />
              <LeftNavBarListItemText
                primary="Write to support"
                //onClick={() => this.redirectToLink(WRITE_TO_SUPPORT_URL)}
                onClick={this.triggerIssueCollector}
              />
            </LeftNavBarListItem>
            <LeftNavBarListItem>
              <DocumentBulletListMultiple20Filled className={classes.icon} />

              <LeftNavBarListItemText
                primary="Help Docs"
                onClick={() => this.redirectToLink(HELP_DOCS_URL)}
              />
            </LeftNavBarListItem>
            <LeftNavBarListItem>
              <Video20Filled className={classes.icon} />

              <LeftNavBarListItemText
                primary="Video Guides"
                onClick={() => this.redirectToLink(VIDEO_GUIDES_URL)}
              />
            </LeftNavBarListItem>
            <LeftNavBarListItem>
              <GiftCard20Filled className={classes.icon} />

              <LeftNavBarListItemText
                primary="Refer"
                onClick={() => this.redirectToLink(REFER_URL)}
              />
            </LeftNavBarListItem>
          </div>
        </div>
      </LeftNavBar>
    );
  }
}

StartPageLeftNavBar.propTypes = {
  //data
  classes: PropTypes.object,
  isLeftMenuOpened: PropTypes.bool,
  startPageApplication: PropTypes.string,

  //func
  setLeftMenuOpened: PropTypes.func,
  changeStartApplication: PropTypes.func,
  routeToDashboard: PropTypes.func,
};

export default withStyles(styles)(StartPageLeftNavBar);