import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControl,
    FormControlLabel,
    Grid,
    // InputLabel,
    MenuItem,
    Select,
    TextField,
    Backdrop,
    CircularProgress,
    Input,
    ListSubheader,
    Divider,
    FormHelperText
} from "@material-ui/core";
import InputLabel from "components/common/InputLabel/InputLabel";
import DialogTitle from "components/common/DialogTitle/DialogTitle";
import TargetDatabaseType from 'types/model/common/TargetDatabaseType';
import * as _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { REGULAR_DASHBOARD, ANOMALY_DASHBOARD, DEFAULT_DASHBOARD } from 'dashlets/AnomalyDetectionDashlet/constants';
import HLCDashletsRestClient from 'api/rest/HLCDashletsRestClient';
import { listTargetNames } from 'dashlets/actions';
import { LIST_TARGET_NAMES_SUBSCRIPTION } from 'dashlets/hlcDashletWebSocketClient';
import HLCUserSessionWebSocketSubscription from 'websocket/HLCUserSessionWebSocketSubscription';
import { setDashletOptionsProperty } from 'reducers/dashboard/createDashletSlice';
import { APP_LEGAL_URL } from 'config';
import { ChevronDown12Regular } from '@fluentui/react-icons';

const styles = theme => ({
    root: {
        maxWidth: '700px',
    },
    dialogContent: {
        width: '400px',
        // padding : '0px 18px',
    },
    cancelButton: {
    },
    saveButton: {
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    toggleButton: {
        padding: '8px',
        gap: '8px'
    },
    customTextField: {
    },
    inputLabel:{
        marginTop:"5px",
        color:"#BDBDBD",
        fontWeight: 400,
        fontSize:"12px",
        lineHeight:"16px",
    },
    customCheckbox: {
        borderRadius: '4px',
        '& .MuiCheckbox-root': {
            color: '#616161', 
          },
          '& .MuiCheckbox-root.Mui-checked': {
            color: '#0F6CBD',
          },
          '& .MuiCheckbox-root.Mui-disabled': {
            color: '#B0B0B0',
          },
          '& .MuiCheckbox-root.Mui-checked.Mui-disabled': {
            color: '#B0B0B0',
          },
        "& .MuiFormControlLabel-label": {
          fontSize: "14px",  
          lineHeight: "20px",  
        },
    },
    dialogActions: { 
        // marginRight: '15px',
        // marginBottom: '10px'
        margin: '0px 10px 10px 0px',
    },
    link: {
        color: '#0F6CBD', 
        textDecoration: 'none', 
        '&:hover': {
          textDecoration: 'underline',
        },
    },
});

const DashboardModal = (props) => {
    const { classes, open, handleClose, handleConfirm, isLocked, errors, objectData, handleChangeInput, backendKey, 
        dashboardType, handleChangeProperty, handleBackendSelect, handleDashboardTypeChange, createNewTarget, targetName, targetNames } = props;
    const [backends, setBackends] = useState([]);

    const dispatch = useDispatch();

    useEffect(() => {
        // Fetch the backends when the component mounts
        HLCDashletsRestClient.listUserBackends()
            .then(backends => {
                setBackends(backends);
            })
            .catch(error => {
                console.error(error);
            });
    }, []);

    useEffect(() => {
        if (backendKey) {
            dispatch(listTargetNames(backendKey));
        }
    }, [backendKey, dispatch]);

    const {
        targetConfiguration: {
            databaseType,
            databaseHost,
            databasePort,
            databaseName,
            databaseUser,
            databasePassword,
            databaseUrl,
            useDatabaseUrl,
            hidePassword,
            acceptPolicy
        },
    } = props;

    const isEditMode = Boolean(objectData.id);
    // const isDisabled = !databaseType || useDatabaseUrl || !backendKey;
    const isDisabled = !backendKey || !createNewTarget;

    const handleListTargetNamesStompResponse = useCallback((response) => {
        dispatch(setDashletOptionsProperty({propertyName: "targetOptions", propertyValue: response.options}))
    }, []);

    const cloudBackends = _.filter(backends, { backendType: 'CLOUD_BACKEND' });
    const standaloneBackends = _.filter(backends, { backendType: 'STANDALONE_BACKEND' });

    const getBackendId = (backendKey) => {
        const filteredData = cloudBackends.filter(backend => backend.backendKey == backendKey);
        if (filteredData && filteredData.length > 0) {
            return filteredData[0].id
        }
        return null;
    }

    return (
        <>
        <HLCUserSessionWebSocketSubscription
        subscriptionDestination={LIST_TARGET_NAMES_SUBSCRIPTION}
        handleMessage={handleListTargetNamesStompResponse}
        />
        <Dialog
            open={open}
            onClose={handleClose}
            onEnter={props.onOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title" onClose={handleClose}>
                {isEditMode ? 'Edit Dashboard' : 'Create Dashboard'}
            </DialogTitle>

            <Backdrop className={classes.backdrop} open={isLocked}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <DialogContent style={{padding:dashboardType === DEFAULT_DASHBOARD?"0px 1px 0px 18px":"0px 18px"}} className={classes.dialogContent}>
                <FormControl style={{marginBottom: "8px"}} key="dash-name" fullWidth required error={errors.name} margin="dense">
                    <TextField
                        id="dash-name"
                        name="name"
                        placeholder="Dashboard Name"
                        variant="outlined" 
                        value={objectData.name}
                        onChange={handleChangeInput}
                        fullWidth
                        // margin="dense"
                        size="small"
                    />
                </FormControl>
                
                <InputLabel id="dashboard-type-label">Dashboard Type</InputLabel>
                <FormControl variant="outlined" size="small" fullWidth margin="dense" >
                    <Select
                        // labelId="dashboard-type-label"
                        id="dashboardType"
                        name="dashboardType"
                        value={dashboardType}
                        onChange={handleDashboardTypeChange}
                        // label="Dashboard Type"
                        IconComponent={(props) => (
                            <ChevronDown12Regular {...props} style={{ height: '20px', width: '20px', top: '8px' }} />
                        )}
                        fullWidth
                    >
                        <MenuItem value={REGULAR_DASHBOARD}>Regular Dashboard</MenuItem>
                        <MenuItem value={ANOMALY_DASHBOARD}>Anomaly Dashboard</MenuItem>
                        <MenuItem value={DEFAULT_DASHBOARD}>Default Dashboard</MenuItem>
                    </Select>
                </FormControl>
                {dashboardType === ANOMALY_DASHBOARD && (
                        <Grid  item>
                           <InputLabel id="hlc-backend">Select Upbeat Backend</InputLabel>
                            <FormControl style={{marginTop:"8px",marginBottom:"8px"}} variant="outlined" size="small" fullWidth >
                                <Select
                                    // labelId="dashlet-hlc-chart-backendKey-label-1582673886219"
                                    id="backendKey"
                                    name="backendKey"
                                    value={backendKey ? backendKey : ''}
                                    onChange={(event) => handleBackendSelect(event.target.value, getBackendId(event.target.value))}
                                    // label="Select Upbeat Backend"
                                    fullWidth
                                    IconComponent={(props) => (
                                        <ChevronDown12Regular {...props} style={{ height: '20px', width: '20px', top: '8px' }} />
                                    )}
                                >
                                    <MenuItem key="dashlet-hlc-chart-backendKey-menu-item-1582673956819" value=""></MenuItem>
                                    <ListSubheader color='primary'>Cloud Backend</ListSubheader>
                                    <Divider />
                                    {cloudBackends.map(backend => (
                                        <MenuItem
                                            key={`dashlet-hlc-chart-backendKey-menu-item-key-${backend.backendKey}`}
                                            value={backend.backendKey}
                                        >
                                            {backend.name}
                                        </MenuItem>
                                    ))}
                                    <ListSubheader color='primary'>Standalone Backend</ListSubheader>
                                    <Divider />
                                    {standaloneBackends.map(backend => (
                                        <MenuItem
                                            key={`dashlet-hlc-chart-backendKey-menu-item-key-${backend.backendKey}`}
                                            value={backend.backendKey}
                                        >
                                            {backend.name}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </Grid>
                )}
                <Grid item>
                    {dashboardType === DEFAULT_DASHBOARD && (
                        <div>
                            <Grid item>
                            <InputLabel id="hlc-backend">Select Upbeat Backend</InputLabel>
                                <FormControl style={{marginTop:"8px",marginBottom:"8px"}} variant="outlined"  size="small" fullWidth>
                                    <Select
                                        // labelId="dashlet-hlc-chart-backendKey-label-1582673886219"
                                        id="backendKey"
                                        name="backendKey"
                                        value={backendKey ? backendKey : ''}
                                        onChange={(event) => handleBackendSelect(event.target.value, getBackendId(event.target.value))}
                                        // label="Select Upbeat Backend"
                                        fullWidth
                                        IconComponent={(props) => (
                                            <ChevronDown12Regular {...props} style={{ height: '20px', width: '20px', top: '8px' }} />
                                        )}
                                    >
                                        <MenuItem key="dashlet-hlc-chart-backendKey-menu-item-1582673956819" value=""></MenuItem>
                                            <ListSubheader color='primary'>Cloud Backend</ListSubheader>
                                            <Divider />
                                            {cloudBackends.map(backend => (
                                                <MenuItem
                                                    key={`dashlet-hlc-chart-backendKey-menu-item-key-${backend.backendKey}`}
                                                    value={backend.backendKey}
                                                >
                                                    {backend.name}
                                                </MenuItem>
                                            ))}
                                            <ListSubheader color='primary'>Standalone Backend</ListSubheader>
                                            <Divider />
                                            {standaloneBackends.map(backend => (
                                                <MenuItem
                                                    key={`dashlet-hlc-chart-backendKey-menu-item-key-${backend.backendKey}`}
                                                    value={backend.backendKey}
                                                >
                                                    {backend.name}
                                                </MenuItem>
                                            ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                               <InputLabel id="database-type-label">Select Target</InputLabel>
                                <FormControl margin="dense" variant="outlined" size="small" fullWidth >
                                    <Select
                                        id="targetName"
                                        name="targetName"
                                        // labelId="target-name-label"
                                        value={targetName}
                                        onChange={handleChangeInput}
                                        // label="Target Name"
                                        disabled={createNewTarget}
                                        fullWidth
                                        IconComponent={(props) => (
                                            <ChevronDown12Regular {...props} style={{ height: '20px', width: '20px', top: '8px' }} />
                                        )}
                                    >
                                        {targetNames?.map((targetName) => (
                                            <MenuItem key={`name-${targetName}`} value={targetName}>
                                                {targetName}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <FormControlLabel className={classes.customCheckbox}
                                    control={
                                        <Checkbox
                                            // color="#115EA3"
                                            id="createNewTarget"
                                            name="createNewTarget"
                                            size="small"
                                            checked={createNewTarget}
                                            onChange={(event) => handleChangeInput(event)}
                                            
                                        />
                                    }
                                    label="Is Create Dashboard With Default Target"
                                />
                            </Grid>
                            <Grid item>
                                <InputLabel id="database-type-label">Database Type</InputLabel>
                                <FormControl margin="dense" variant="outlined" size="small" fullWidth >
                                    <Select
                                        id="databaseType"
                                        name="databaseType"
                                        // labelId="database-type-label"
                                        value={databaseType}
                                        onChange={handleChangeInput}
                                        disabled={isDisabled}
                                        placeholder="Database Type"
                                        fullWidth
                                        IconComponent={(props) => (
                                            <ChevronDown12Regular {...props} style={{ height: '20px', width: '20px', top: '8px' }} />
                                        )}
                                    >
                                        {Object.values(TargetDatabaseType).map((dbType) => (
                                            <MenuItem key={`db-type-${dbType}`} value={dbType}>
                                                {dbType}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item>
                                <TextField
                                    required
                                    error={errors.databaseHost}
                                    id="databaseHost"
                                    name="databaseHost"
                                    placeholder="Host"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={objectData.databaseHost}
                                    onChange={handleChangeInput}
                                    disabled={isDisabled}
                                    className={classes.customTextField}
                                />
                            </Grid>

                            <Grid item>
                                <TextField
                                    required
                                    error={errors.databasePort}
                                    id="databasePort"
                                    name="databasePort"
                                    placeholder="Port"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={objectData.databasePort}
                                    onChange={handleChangeInput}
                                    disabled={isDisabled}
                                    className={classes.customTextField}
                                />
                            </Grid>

                            <Grid item>
                                <InputLabel  id="databaseUser">Username</InputLabel>
                                <TextField
                                    required 
                                    error={errors.databaseUser}
                                    id="databaseUser"
                                    name="databaseUser"
                                    placeholder="Username"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={objectData.databaseUser}
                                    onChange={handleChangeInput}
                                    disabled={isDisabled}
                                    className={classes.customTextField}
                                />
                                </Grid>

                                <Grid item>
                                <InputLabel  id="databasePassword">Password</InputLabel>
                                <TextField
                                    required 
                                    error={errors.databasePassword}
                                    id="databasePassword"
                                    name="databasePassword"
                                    placeholder="Password"
                                    type="password"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    value={objectData.databasePassword}
                                    onChange={handleChangeInput}
                                    disabled={isDisabled}
                                    className={classes.customTextField}
                                />
                            </Grid>

                            <Grid item>
                                <FormControlLabel
                                className={classes.customCheckbox}
                                    control={
                                        <Checkbox
                                            id="hidePassword"
                                            name="hidePassword"
                                            size="small"
                                            color='primary'
                                            checked={hidePassword}
                                            disabled={isDisabled}
                                            onChange={(event) => {
                                                handleChangeProperty(event.target.name, event.currentTarget.checked);
                                            }}
                                        />
                                    }
                                    label="Hide Password"
                                />
                            </Grid>

                            <Grid item>
                                <TextField
                                    required 
                                    error={errors.databaseName}
                                    id="databaseName"
                                    name="databaseName"
                                    placeholder="Database"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    style={{marginTop:"0px"}}
                                    size="small"
                                    value={objectData.databaseName}
                                    onChange={handleChangeInput}
                                    disabled={isDisabled}
                                />
                            </Grid>

                            <Grid item>
                                <FormControlLabel
                                    className={classes.customCheckbox}
                                    control={
                                        <Checkbox
                                            color='primary'
                                            id="useDatabaseUrl"
                                            name="useDatabaseUrl"
                                            size="small"
                                            checked={useDatabaseUrl}
                                            disabled={isDisabled}
                                            onChange={(event) => {
                                                handleChangeProperty(event.target.name, event.currentTarget.checked);
                                            }}
                                        />
                                    }
                                    label="Use Database/JDBC URL"
                                />
                            </Grid>
                            <Grid item>
                                 <InputLabel id='database-JDBC-url'>Database/JDBC URL</InputLabel>
                                <TextField
                                //    style={{marginTop:"3px"}}
                                    required={useDatabaseUrl} 
                                    error={errors.databaseUrl}
                                    multiline
                                    maxRows={5}
                                    id="databaseUrl"
                                    name="databaseUrl"
                                    className="dropDown-removePadding"
                                    placeholder="Database/JDBC URL"
                                    // label="Database/JDBC URL"
                                    variant="outlined"
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    disabled={!useDatabaseUrl || !backendKey || isDisabled}
                                    value={databaseUrl}
                                    onChange={handleChangeInput}
                                />
                            </Grid>
                            {cloudBackends.some(backend => backend.backendKey === backendKey) && (
                            <Grid item>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            color='primary'
                                            id="acceptPolicy"
                                            name="acceptPolicy"
                                            checked={acceptPolicy}
                                            size="small"
                                            disabled={isDisabled}
                                            error={errors.acceptPolicy}
                                            onChange={(event) => {
                                                handleChangeProperty(event.target.name, event.currentTarget.checked);
                                            }}
                                        />
                                    }
                                    label={
                                        <span>
                                            Accept <a href={APP_LEGAL_URL} target="_blank" className={classes.link}> policy </a>
                                        </span>
                                    }
                                />
                                {errors.acceptPolicy && !acceptPolicy && (
                                    <FormHelperText>{"*Please Accept Policy"}</FormHelperText>
                                )}
                            </Grid>
                            )}
                        </div>
                    )}
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
              <Button onClick={handleConfirm} variant="contained">
                    Save
                </Button>
                <Button onClick={handleClose} variant="outlined">
                    Cancel
                </Button>
            </DialogActions>
        </Dialog>
        </>
    );
};

DashboardModal.propTypes = {
    // data
    classes: PropTypes.object.isRequired,
    objectData: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    isLocked: PropTypes.bool,
    open: PropTypes.bool,
    targetConfiguration: PropTypes.object,
    targetNames: PropTypes.array,

    // functions
    handleClose: PropTypes.func,
    handleConfirm: PropTypes.func,
    handleChangeInput: PropTypes.func,
    handleDashboardTypeChange: PropTypes.func,
    handleChangeProperty: PropTypes.func,
    handleBackendSelect: PropTypes.func,
};

export default withStyles(styles)(DashboardModal);
