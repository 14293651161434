import React from "react";
import {TableCell} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {formatDecimalCurrency} from "utils/FormattingUtils";

interface DecimalCurrencyTableCellProps {
    value: number | null;
}

const useStyles = makeStyles({
    tableCell: {
        textAlign: "right",
        // fontSize: "0.6vw",
        fontSize: "12px",
    }
});

const DecimalCurrencyTableCell: React.FC<DecimalCurrencyTableCellProps> = ({ value }) => {
    const classes = useStyles();

    return <TableCell className={classes.tableCell}>{value != null ? formatDecimalCurrency(value) : "N/A"}</TableCell>;
};

export default DecimalCurrencyTableCell;
