import React from "react";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Switch from "components/common/Switch/Switch";

interface IntegrationCheckboxRowProps {
    label: string;
    name: string;
    value: boolean | null;
    defaultValue: boolean;
    toggle: () => void
}

const IntegrationCheckboxRow: React.FC<IntegrationCheckboxRowProps> = ({
                                                                           label,
                                                                           name,
                                                                           value,
                                                                           defaultValue,
                                                                           toggle
                                                                       }) => {

    return (
        <Grid
            item
            container
            spacing={2}
            xs={12}
            direction="row"
            justifyContent="flex-start"
            alignItems="center">
            <Grid item xs={2} style={{textAlign: "left"}}>
                <InputLabel htmlFor={name}>{label}</InputLabel>
            </Grid>
            <Grid item xs={5}>
                <Switch checked={value || defaultValue}
                        onChange={toggle}
                        name={name}
                />
            </Grid>
        </Grid>
    );
};

export default IntegrationCheckboxRow;
