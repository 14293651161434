
import withStyles from "@material-ui/core/styles/withStyles";
// import InputLabel from "@material-ui/core/InputLabel";

const styles = theme => ({
    root: {
    },
    inputLabel:{
        marginTop:"5px",
        color:"#BDBDBD",
        fontWeight: 400,
        fontSize:"12px",
        lineHeight:"16px",
        fontFamily:"Segoe UI"
    }
})

function InputLabels(props) {
    const { children, classes, id,style } = props;
    return (
      <span style={style} className={classes.inputLabel} id={id}>
        {children}
     </span>
    )
}

export default withStyles(styles)(InputLabels)