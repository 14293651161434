export const formatCurrency = (value?: number) =>
    value ? new Intl.NumberFormat("en-US",
            {
                style: "currency",
                currency: "USD"
            }).format(value)
        : "";

export const formatDecimalCurrency = (value?: number) =>
    value ? new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value)
        : "";

export const formatDecimalNumber = (value?: number) =>
    value ? new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value)
        : "";

export const formatDecimalPct = (value?: number) =>
    value ? new Intl.NumberFormat("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
        }).format(value) + "%"
        : "";

export const formatNumber = (value?: number) =>
    value ? new Intl.NumberFormat("en-US").format(value) : "";
